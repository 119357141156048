
import { GenericObject } from '../../helpers/object';

function parseTags(input: string|string[]|null): string[] {
    if (input === null) {
        return [];
    }
    if (typeof input === 'string') {
        input = input.split(', ');
    }
    return input;
}

class Customer {
    id: number|null;
    province: string|null;
    tags: string[];
    taxable: boolean;
    bold_id: number|null;
    email: string|null;

    constructor({ id = null, tags = null, province = null, taxable = true, bold_id = null, email = null }) {
        this.id = id;
        this.tags = parseTags(tags);
        this.province = province;
        this.taxable = taxable;
        this.bold_id = bold_id;
        this.email = email;
    }

    setTaxable(taxable: boolean) {
        this.taxable = taxable;
    }

    getTaxable() {
        return this.taxable;
    }

    getId() {
        return this.id;
    }

    getBoldId() {
        return this.bold_id;
    }

    getTags() {
        return Array.isArray(this.tags) && this.tags.length > 0
            ? this.tags
            : [];
    }

    getProvince() {
        return this.province;
    }

    /**
     * Does the customer have a specific tag?
     */
    hasTag(tag: string) {
        if (tag === 'default') {
            return true;
        }

        return Array.isArray(this.tags) && this.tags.includes(tag);
    }

    /**
     * Does the customer have any tags?
     */
    hasTags() {
        return Array.isArray(this.tags) && this.tags.length > 0;
    }

    toJSON(): GenericObject {
        return {
            id: this.id ? this.id : 0,
            tags: this.getTags(),
            province: this.getProvince(),
            taxable: this.taxable,
            bold_id: this.bold_id,
            email: this.email,
        };
    }
}

export default Customer;
