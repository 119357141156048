
import container from '../../../components/Container';
import WindowApi from '../../../api/WindowApi';
import { show } from '../../../helpers/dom';

abstract class BaseCustomElement {
    api: any;
    element: any;
    lastRender: any;
    constructor(element: any) {
        this.element = element;
        this.api = container.get(WindowApi);
        this.lastRender = null;

        this.basePrep();
        this.prep();
        this.update(); // first update
    }

    basePrep() {
        /* develblock:start */
        this.element.style.background = 'rgba(50,205,50,.5)';
        /* develblock:end */
        this.api.events.on('update', this.update, this);
    }

    update() {
        const render = this.render();
        if (render !== null) {
            this.element.innerHTML = render;
            this.lastRender = render;
            show(this.element);
        }
    }

    abstract prep(): void;

    abstract render(): void;
}

export default BaseCustomElement;
