
import { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';
import { GenericObject } from '../../helpers/object';
import CartBaseCondition from './CartBaseCondition';

class QtyByBundleCondition extends CartBaseCondition {
    name: string;
    values: GenericObject;
    value: any;

    constructor({
        name,
        value,
        values,
        ...others
    }: BaseConditionInput & { values: GenericObject, value: any, name: string }) {
        super(others);
        this.name = name;
        this.value = value;
        this.values = values;
    }

    continueEvaluating(variant: Variant, shop: Shop): boolean {
        const count: { [key: string]: number } = {};

        Object.keys(this.values).forEach(function (key) {
            count[key] = 0;
        });

        shop.cart.items.forEach(function (item) {
            // check if item is part of this bundle
            if (item.properties &&
                item.properties['_bold_bundle_price_rules_external_id'] &&
                item.properties['_bold_bundle_price_rules_external_id'] in count) {
                count[item.properties['_bold_bundle_price_rules_external_id']] += item.quantity;
            }
        });

        const firstId = Object.keys(count)[0]; // get the first _bold_bundle_price_rules_external_id
        const numBundles = count[firstId] / this.values[firstId]; // the number of bundles we expect to have (should be the same for each bundle item)

        const applies = Object.keys(count).reduce((carry, key): boolean => {
            const division = count[key] / this.values[key];
            const remainder = count[key] % this.values[key];
            const truth = !remainder && (division === numBundles);
            return carry && truth;
        }, true);
        return applies;
    }
}

export default QtyByBundleCondition;
