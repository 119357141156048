
import Money from '../money/Money';
import Log from '../../helpers/Log';
import DiscountData from './DiscountData';
import { normalizeIdentifier } from '../../helpers/identifier';
import { GenericObject } from '../../helpers/object';
import Product from './Product';
import CartItem from './CartItem';
import Ruleset from '../Ruleset';
import Rule from '../Rule';
import Fee from '../money/Fee';
import PriceBreakdown from '../money/PriceBreakdown';
import QuantityBreak from '../money/QuantityBreak';
import { randomString } from '../../helpers/string';

interface ReconstructInput {
    id: string|number|null;
    product_id: string|number;
    name?: string;
    sku?: string;
    price: number;
    raw_price: number;
    original_price: number;
    weight?: number;
    weight_unit?: string;
    grams?: number;
    image?: string | null;
    available?: boolean;
    price_breakdown?: PriceBreakdown[];
    fees?: Fee[];
    qty_breaks?: QuantityBreak[];
    meta?: GenericObject[];
    logs?: GenericObject[];
    compare_at_price?: number;

}

class Variant {
    appliedRulesetIds: number[];
    available!: boolean;
    compare_at_price?: number;
    discountData!: DiscountData | null;
    fees!: Fee[];
    grams?: number;
    id!: string;
    image?: string | null;
    initialData: ReconstructInput & { parent?: CartItem|Product };
    logs: GenericObject[];
    meta!: GenericObject[];
    name?: string;
    original_price!: Money;
    parent?: CartItem|Product;
    price!: Money;
    price_breakdown!: PriceBreakdown[];
    product_id!: string;
    qty_breaks!: QuantityBreak[];
    raw_price!: number;
    sku?: string;
    weight?: number;
    weight_unit?: string;

    ruleProcessorState!: {
        bucket_id?: number;
        rule?: Rule;
    }

    constructor(data: ReconstructInput & { parent?: CartItem|Product }) {
        this.reconstruct(data);

        this.appliedRulesetIds = [];
        this.parent = data.parent;
        this.initialData = data;
        this.logs = [];
    }

    reconstruct({
        id,
        product_id,
        name,
        sku,
        price,
        raw_price,
        original_price,
        weight,
        weight_unit,
        grams,
        image,
        available = true,
        price_breakdown = [],
        fees = [],
        qty_breaks = [],
        meta = [],
        logs = [],
        compare_at_price,

    }: ReconstructInput) {
        this.product_id = normalizeIdentifier(product_id) as string;
        this.setVariantId(this.product_id, id, sku);
        this.name = name;
        this.sku = sku;
        this.price = new Money(price, raw_price);
        this.original_price = new Money(original_price, raw_price);
        this.raw_price = raw_price;
        this.weight = weight;
        this.weight_unit = weight_unit;
        this.grams = grams;
        this.image = image;
        this.available = available;
        this.fees = fees;
        this.price_breakdown = price_breakdown;
        this.qty_breaks = qty_breaks;
        this.meta = meta;
        this.logs = logs;
        this.compare_at_price = compare_at_price;
        this.ruleProcessorState = {};
    }

    setVariantId(productId: string, id: string|number|null, sku: string|undefined) {
        id = normalizeIdentifier(id);
        sku = normalizeIdentifier(sku) ?? undefined;
        if (id) {
            this.id = id;
        } else if (sku) { // BigCommerce won't have variantId's on the storefront
            this.id = `${productId}|${sku}`;
        } else {
            this.id = `${productId}|${randomString()}`;
        }
    }

    addFee(fee: Fee) {
        this.fees.push(fee);
        this.original_price.addFee(fee.amount);
        this.price.addFee(fee.amount);
    }

    addPriceBreakdown(price_breakdown: PriceBreakdown[]) {
        this.price_breakdown = price_breakdown;
    }

    showFee(fee: Fee) {
        this.fees.push(fee);
        this.price.emitChange();
    }

    log(event: string, data: GenericObject = {}) {
        this.logs.push({ event, ...data });
        /* develblock:start */
        Log.debug(event, data);
        /* develblock:end */
    }

    addQuantityBreak(qb: QuantityBreak) {
        this.qty_breaks.push(qb);
        this.price.emitChange();
    }

    addAppliedRulesetIds(ruleset_ids: number[]) {
        this.appliedRulesetIds = this.appliedRulesetIds.concat(ruleset_ids);
    }

    hasHadRulesetApplied(ruleset_id: number): boolean {
        return this.appliedRulesetIds.includes(ruleset_id);
    }

    addMeta(meta: GenericObject) {
        this.meta.push(meta);
    }

    copy(): Variant {
        const data = { ...this.toJSON(), parent: this.getParent() };
        return new Variant(data);
    }

    get displayName() {
        return this.name ? `"${this.name}"/${this.id}` : this.id;
    }

    /**
     * Re-set this variant to its original values before
     * any applied rules but keep all bindings and
     * instances.
     */
    reset() {
        /** Hang on to some instances. */
        const price = this.getPrice();
        const original_price = this.getOriginalPrice();
        /** Reset. */
        this.reconstruct(this.initialData);
        this.discountData = null;
        this.appliedRulesetIds = [];
        this.logs = [];
        this.fees = [];
        this.price_breakdown = [];

        /** Restore instances. */
        this.setPrice(price);
        this.setOriginalPrice(original_price);
        price.setAmountWithoutEvent(this.initialData.price);
        original_price.setAmountWithoutEvent(this.initialData.original_price);

        this.price.emitChange();
    }

    /**
     * Validate the variant.
     */
    validate() {
        if (this.getPrice().isNegative()) {
            throw new Error('Price dropped below 0.');
        }
    }

    /**
     * ⤓⤓ STANDARD MODEL ACCESS ⤓⤓
     * Getters/Setters and toJSON/Hydrate
     * Anything more complex should be above.
     */

    /**
     * For sending data to other systems.
     */
    toJSON() {
        return {
            id: this.id,
            product_id: this.product_id,
            name: this.name,
            sku: this.sku,
            price: this.price.amount(),
            original_price: this.original_price.amount(),
            raw_price: this.raw_price,
            weight: this.weight,
            weight_unit: this.weight_unit,
            grams: this.grams,
            image: this.image,
            available: this.available,
            fees: this.fees,
            meta: this.meta,
            logs: this.logs,
            price_breakdown: this.price_breakdown,
            qty_breaks: this.qty_breaks,
            compare_at_price: this.compare_at_price,
        };
    }

    /**
     * For receiving data from other systems.
     * Should update all values and restore
     * sub-model instances.
     *
     * @param {any} data
     */
    hydrate(data: any) {
        /** Hang on to some things. */
        const price = this.getPrice();
        const original_price = this.getOriginalPrice();
        this.reconstruct(data);

        /** Restore instances. */
        this.setPrice(price);
        this.setOriginalPrice(original_price);
        // Update price (triggers price events).
        original_price.setAmount(data.original_price);
        price.setAmount(data.price);
    }

    getLogs(): GenericObject[] {
        return this.logs;
    }

    setLogs(logs: GenericObject[]) {
        this.logs = logs;
    }

    /**
     * Get all added fees
     */
    getFees(): Fee[] {
        return this.fees;
    }

    getPriceBreakdown(): PriceBreakdown[] {
        return this.price_breakdown;
    }

    /**
     * Get rule tag-along metadata.
     */
    getMeta(): GenericObject {
        return this.meta;
    }

    getId(): string {
        return this.id;
    }

    getProductId(): string {
        return this.product_id;
    }

    getSku() {
        return this.sku;
    }

    getPrice(): Money {
        return this.price;
    }

    getOriginalPrice(): Money {
        return this.original_price;
    }

    setOriginalPrice(original_price: Money) {
        this.original_price = original_price;
    }

    getRawPrice(): number {
        return this.raw_price;
    }

    getWeight() {
        return this.weight;
    }

    getWeightUnit() {
        return this.weight_unit;
    }

    getGrams() {
        return this.grams;
    }

    getImage() {
        return this.image;
    }

    getParent() {
        return this.parent;
    }

    setParent(parent: CartItem|Product) {
        this.parent = parent;
    }

    setPrice(price: Money) {
        this.price = price;
    }

    setAvailable(available: boolean) {
        this.available = available;
    }

    getDiscountData(): DiscountData|null {
        return this.discountData;
    }

    setDiscountData(discountData: { message: string, source_app: string, layer_2_rule: Rule, expiry?: string | null }) {
        this.discountData = new DiscountData(discountData);
    }
}

export default Variant;
