
import { RULE_TYPES } from '../constants';
import { config, SETTINGS } from '../config';
import * as ActionFactory from './actions/ActionFactory';
import * as ConditionFactory from './conditions/ConditionFactory';
import BaseAction from './actions/BaseAction';
import BaseCondition from './conditions/BaseCondition';
import { GenericObject } from '../helpers/object';
import Ruleset from './Ruleset';
import Shop, { SubscriptionParamsStorage } from './platform/Shop';
import Variant from './platform/Variant';
import CartItem from './platform/CartItem';
import Product from './platform/Product';

let ruleId = 1;

export interface RuleInput {
    actions: GenericObject[];
    conditions: GenericObject[];
    external_id?: string;
    id?: number;
    meta?: GenericObject;
    type: string;
    priority?: number;
    stack_order?:number;
}

/**
 * Rule
 */
class Rule {
    actions: BaseAction[];
    conditions: BaseCondition[];
    external_id?: string;
    id: number;
    meta?: GenericObject;
    ruleset: Ruleset;
    type: string;
    priority: number;
    stack_order?: number;
    constructor({
        type = RULE_TYPES.DISCOUNT,
        conditions,
        actions,
        meta,
        id,
        external_id,
        priority = 0,
        stack_order,
    }: RuleInput, ruleset: Ruleset) {
        this.id = id || ruleId++;

        this.type = type;
        this.ruleset = ruleset;
        this.external_id = external_id;
        this.priority = priority;
        // Conditions are being filtered in case of empty conditions
        this.conditions = conditions.map((condition) => ConditionFactory.make(condition)).filter((c) => !!c);

        this.actions = actions.map((action, index) => {
            action.id = index;
            return ActionFactory.make(action);
        }).filter((a) => !!a);

        this.meta = meta;
        this.stack_order = stack_order;
    }

    getType() {
        return this.type;
    }

    apply(variant: Variant, shop: Shop, processorLog: GenericObject = {}): boolean {
        const conditionResults = this.conditions
            .map((condition) => {
                return condition.evaluate(variant, shop);
            });
        const ruleApplies = conditionResults.every((r) => r) && this.checkForSubscriptionWidgetOverride(variant, shop); // every condition is true

        this.addRuleMeta(variant, this.meta);

        if (ruleApplies) {
            variant.log(`RULE_MATCHED`, { variant: variant.id, ...processorLog, ...this.toJSON() });
            this.actions.forEach((action) => action.act(variant.getPrice(), variant, shop));
        } else if (config(SETTINGS.verbose_logs)) {
            variant.log(`RULE_SKIPPED`, { variant: variant.id, ...processorLog, ...this.toJSON() });
        }

        return ruleApplies;
    }

    /**
     * Even if the conditions are true, with the advent of Staples' custom subs widget, there are additional parameters to check
     * if a ruleset should be applied.
     * Ie. if there are no conditions, the ruleset applies by default. BUT if a product is part of a subscription,
     * this ruleset should not apply since a product part of a ruleset should only receive discounts from rulesets that have
     * condition types SUBSCRIPTION_GROUP and SUBSCRIPTION_INTERVAL
     *
     * TODO Jason: refactor
     */
    checkForSubscriptionWidgetOverride(variant: Variant, shop: Shop): boolean {
        if (!shop.isSubscriptionPricesLocked()) {
            return true;
        }

        if ((!config('staples_subs_ff') && !shop.isSubscriptionSelected() && shop.getPage() !== 'cart' && shop.getPage() !== undefined && variant.parent && !this.productHasSubscription(variant.parent, shop)) || !variant.parent) {
            return true;
        }

        if (!config('staples_subs_ff') && shop.isSubscriptionSelected()) {
            return false;
        }

        if (!config('staples_subs_ff') && !this.hasSubscriptionsConditions()) {
            return true;
        }

        if (shop.getPage() === 'product' && this.conditions.length === 0 && (!shop.isSubscriptionSelected() || !this.productHasSubscription(variant.parent, shop)) && !this.isAddToCartModal()) {
            return true;
        }

        if (shop.getPage() === 'product' && config('staples_subs_ff') && this.hasSubscriptionsConditions() && this.productHasSubscription(variant.parent, shop) && shop.isSubscriptionSelected()) {
            return true;
        }

        if (this.productHasSubscription(variant.parent, shop) && this.conditions.length === 0 && shop.getPage() === 'cart') {
            return false;
        } else if (shop.isSubscriptionSelected() && this.conditions.length === 0) {
            return false;
        }

        if (!this.productHasSubscription(variant.parent, shop) && !this.hasSubscriptionsConditions() && !shop.isSubscriptionSelected()) {
            return true;
        }

        if (this.productHasSubscription(variant.parent, shop) && this.hasSubscriptionsConditions() && !shop.isSubscriptionSelected()) {
            return true;
        }

        if (shop.getSubscriptionParams() && this.conditions.length !== 0 && this.hasSubscriptionsConditions() && (shop.isSubscriptionSelected() || shop.getPage() === undefined || shop.getPage() === 'cart')) {
            return true;
        } else if (!shop.getSubscriptionParams() && (this.conditions.length === 0 || !this.hasSubscriptionsConditions())) {
            return true;
        }
        return false;
    }

    isAddToCartModal(addToCartModalContainerClassName = 'cart_modal') {
        const addToCartModalContainer = document.getElementsByClassName(addToCartModalContainerClassName);
        return addToCartModalContainer && addToCartModalContainer.length > 0 && addToCartModalContainer[0].hasChildNodes();
    }

    productHasSubscription(item: Product|CartItem, shop: Shop) {
        const product_id = item instanceof Product
            ? (item as Product).id
            : (item as CartItem).product_id;

        if (shop.getPage() === 'product' && (item.subscriptionIntervalId !== undefined || (item as CartItem).properties?._interval_id !== undefined)) {
            return true;
        } else if (shop.subscriptions_params != null) {
            let result = false;
            shop.getSubscriptionParams()?.forEach((item: SubscriptionParamsStorage) => {
                if (item.product_id === product_id) {
                    result = true;
                }
            });
            return result;
        }
        return false;
    }

    hasSubscriptionsConditions() {
        return this.conditions.filter(x => x.type === 'SUBSCRIPTION_GROUP' || x.type === 'SUBSCRIPTION_INTERVAL').length > 0;
    }

    addRuleMeta(variant: Variant, meta: GenericObject|undefined) {
        if (meta) {
            variant.addMeta(meta);
        }
    }

    toJSON() {
        return {
            id: this.id,
            type: this.type,
            ruleset_id: this.ruleset.id,
            conditions: this.conditions,
            actions: this.actions,
        };
    }
}

export default Rule;
