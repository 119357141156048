
import { GenericObject } from './object';
import EventEmitter from 'eventemitter3';
import WindowApi from '../api/WindowApi';

export interface WBoldPRE {
    config?: GenericObject;
    _api_rdy_prom?: Promise<WindowApi>;
    _api_rdy_prom_reject?: (error?: string) => void;
    _api_rdy_prom_resolve?: (api: WindowApi) => void;
    ready: () => Promise<WindowApi>;
}

interface WBoldCommon {
    cartDoctorOriginal?: { fix?: (input: any) => any; fixItem?: (input: any) => any };
    cartDoctor?: {
        fix?: (input: any) => any;
        fixItem?: (input: any) => any;
    };
    eventEmitter?: EventEmitter;
    Shopify?: {
        variants?: { [variantId: string]: { product_id: string|number } };
        shop?: {
            currency?: GenericObject;
        };
        cart?: {
            currency: string;
        }
    }
}

interface WBold {
    checkout?: {
        registerCheckoutCallback: (fn: (form: HTMLFormElement, cart: any) => void) => void;
        getCookie: (name: string) => void;
        setCookie: (name: string, params: any) => void;
        decodeCartParams: (cartParams: any) => any;
        encodeCartParams: (cartParams: any) => any;
        setCartParamsCookie: (name: string, cartParams: any) => void;
    };
    pre?: WBoldPRE;
    common?: WBoldCommon;
}

interface BoldWindow {
    BOLD?: WBold;
    BOLDCURRENCY?: {
        converter: {
            modifyPriceForDom: (input: number) => string;
        };
        moneyFormats: { [currency: string]: { money_format: string } };
        currentCurrency: string;
    };
    Shopify?: {
        formatMoney?: (input: number) => string;
        currency?: {
            active: string;
            rate: string;
        };
        locale?: string
    };
    Currency?: {
        formatMoney?: (input: number) => string;
    };
    theme?: {
        Currency?: {
            formatMoney?: (input: number) => string;
        }
    };
    BOLD_pr_confarr?: any[];
    __PR_DEBUG?: any;
    __PR_DEBUG_build_intercepted?: boolean;
}

let boldWindow: BoldWindow & Window;

if (typeof window === 'undefined') {
    // @ts-expect-error
    boldWindow = {};
} else {
    boldWindow = window as Window & BoldWindow;
}

export { boldWindow };

boldWindow.BOLD = boldWindow.BOLD ?? {};

const bold = boldWindow.BOLD;

export default bold;
