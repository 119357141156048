
import BaseAction, { BaseActionParams } from './BaseAction';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import Shop from '../platform/Shop';

class CustomerDraftOrderTaxableAction extends BaseAction {
    value: boolean;

    constructor({ type, value }: BaseActionParams & { value: boolean }) {
        super({ type });
        this.value = value;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        shop.getCustomer().setTaxable(Boolean(this.value));
    }
}

export default CustomerDraftOrderTaxableAction;
