
import { BASE_URLS } from '../constants';
import { GenericObject } from '../helpers/object';

class ClientError extends Error {
    status: any;
    code: any;
    response: any;
}

/**
 * Api/Client
 */
class Client {
    static async get(path: string) {
        return Client.request('get', path);
    }

    static async request(method: string, path: string, data: GenericObject|null = null, contentType = 'text/plain;charset=UTF-8') {
        const url = path.includes('/rulesets') || path.includes('/enabled')
            ? `${BASE_URLS.API}/${path}`
            : `${BASE_URLS.PHP_API}/${path}`;

        const config = {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': contentType,
            },
            body: data ? JSON.stringify(data) : undefined,
        };

        const response = await fetch(url, config);

        await Client.checkStatus(response);

        return response.json();
    }

    static async checkStatus(response: Response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }

        let error;
        let errorInfo;

        try {
            error = new ClientError(response.statusText);
            errorInfo = await response.json();
        } catch (e) {
            error = new ClientError('Failed to parse response json.');
            errorInfo = { error: 'API_INVALID_JSON', message: 'Failed to parse response json.' };
        }

        error.status = response.status;
        error.code = errorInfo.error;
        error.message = errorInfo.message;
        error.response = response;

        throw error;
    }
}

export default Client;
