
export interface GenericObject {
    [key: string]: any
}

export function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function fallbacks(...fallbacks: any[]): any {
    for (let i = 0; i < fallbacks.length; i++) {
        if (fallbacks[i] !== undefined) {
            return fallbacks[i];
        }
    }
}

/**
 * Serialize an object tree.
 */
export function serialize<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function nonEmptyObjectOrNull<T>(obj: T|undefined): T|null {
    if (obj && Object.keys(obj).length > 0) {
        return obj;
    }
    return null;
}
