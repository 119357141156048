
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop, { SubscriptionParamsStorage } from '../platform/Shop';
import Product from '../platform/Product';
import { config } from '../../config';

class SubscribeIntervalCondition extends BaseCondition {
    value: number;

    constructor({
        value,
        ...others
    }: BaseConditionInput & { value: number }) {
        super(others);
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        let result = false;

        if (this.hasStaplesSubsFeatureFlag()) {
            if (variant.parent instanceof Product && shop.isSubscriptionSelected()) {
            // eslint-disable-next-line eqeqeq
                result = (variant.parent.getSubscriptionIntervalId() == this.value);
            } else if (variant.parent instanceof Product && shop.isSubscriptionSelected() === false) {
                return false;
            } else if (shop.subscriptions_params != null) {
                let subsParams: any;
                shop.getSubscriptionParams()?.forEach((item: SubscriptionParamsStorage) => {
                    if (item.product_id === variant.getProductId()) {
                        subsParams = item.subsInfo;
                        // eslint-disable-next-line eqeqeq
                        if (item.subsInfo.subscription_interval_id == this.value) {
                            result = true;
                        }
                    }
                });
            }
        } else {
            let subsParams = null;
            if (shop.cart_params != null && shop.cart_params.get != null) {
                subsParams = shop.cart_params.get('subsData');
            }
            if (subsParams != null) {
                subsParams.forEach((param: any) => {
                    if (param.subscription_length && param.line_item_key) {
                        if (param.subscription_length === this.value && param.line_item_key === variant?.parent?.id) {
                            result = true;
                        }
                    }
                });
            }
        }

        return result;
    }
    hasStaplesSubsFeatureFlag(): boolean {
        return config('staples_subs_ff');
    }
}

export default SubscribeIntervalCondition;
