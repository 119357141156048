import { PUBLIC_API_IDS_PER_REQUEST } from '../constants';
import container from './Container';
import Shop from '../models/platform/Shop';
import RuleStorage from '../components/RuleStorage';
import RuleProcessor from '../components/RuleProcessor';
import Product from '../models/platform/Product';

const delay = 100;

let inputs: Product[] = [];
let timeoutRef: NodeJS.Timeout | null = null;
let promise: Promise<Product[]> | null;
let promiseSuccess: null | ((result: Product[]) => void);
let promiseFailure: null | ((error: any) => void);

function add(input: Product) {
    if (!promise) {
        promise = new Promise((resolve, reject) => {
            promiseSuccess = resolve;
            promiseFailure = reject;
        });
    }
    inputs.push(input);
    const thresholdMet = inputs.length >= PUBLIC_API_IDS_PER_REQUEST;

    if (thresholdMet) {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        flush();
    } else if (!timeoutRef) {
        timeoutRef = setTimeout(flush, delay);
    }

    return promise;
}

function flush() {
    const shop = container.get(Shop);

    // Copy some references so further add() calls don't conflict with this run of flush
    const success = promiseSuccess as (result: Product[]) => void;
    const failure = promiseFailure as (error: any) => void;
    const prods = inputs;

    timeoutRef = null;
    inputs = [];
    promise = null;
    promiseSuccess = null;
    promiseFailure = null;

    const ruleApiResponses = RuleStorage.fetchRulesForProducts(prods);

    RuleProcessor.applyRules(ruleApiResponses, shop).then(
        () => success(prods),
        (e) => failure(e),
    );
}

export default {
    add,
};
