
import BaseAction, { BaseActionParams } from './BaseAction';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import Shop from '../platform/Shop';

class FreeShippingAction extends BaseAction {
    value: number;

    constructor({ type, value }: BaseActionParams) {
        super({ type });
        this.value = value;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        /*
         * This method doesn't execute anything because this action should not affect the storefront in any way.
         * Any shipping value changes will be applied by Cashier/Checkout after PRE Discount Codes confirms
         * if the rule is a match for the current cart.
         */
    }
}

export default FreeShippingAction;
