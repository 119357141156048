
import { GenericObject } from '../../helpers/object';
import { config, ENV } from '../../config';
import OrderDataStorage from '../../components/OrderDataStorage';
import SourceStorage from '../../components/SourceStorage';
import OrderConditionClient from '../../api/OrderConditionClient';
import { equalIdentifiers, normalizeIdentifier } from '../../helpers/identifier';
import { EVENTS, channel } from '../../events';
import BaseCustomElement from '../../browser/modules/custom_elements/BaseCustomElement';
import Cart from './Cart';
import Customer from './Customer';
import Platform from './Platform';
import Product from './Product';
import Variant from './Variant';
import BOLD from '../../helpers/windowBold';
import CartItem from './CartItem';
import PlatformScanner from '../../components/PlatformScanner';

export interface ProductCollections {
    [product_id: string]: string[];
}

export interface SubscriptionParamsStorage {
    product_id: string,
    subsInfo: {
        subscription_group_id?: number,
        subscription_interval_id?: number,
    }
}

export interface ShopInput {
    shop_domain: string;
    custom_domain: string;
    customer: Customer;
    cart: Cart;
    products: Product[];
    page: string | null;
    currency: string;
    currency_symbol: string;
    money_format: string;
    order_conditions: GenericObject | null;
    source: string | null;
    cart_params: GenericObject | null;
    product_collections: ProductCollections;
    is_calling_for_cart: boolean;
    subscriptionTabSelected?: boolean;
    subscriptions_params?: SubscriptionParamsStorage[];
    skip_clearing_subs_cache: boolean;
}

class Shop {
    cart: Cart;
    currency: string;
    currency_symbol: string;
    custom_domain: string;
    custom_elements: BaseCustomElement[];
    customer: Customer;
    money_format: string;
    order_conditions: GenericObject | null;
    order_data: GenericObject | null;
    page: string | null;
    platform!: Platform;
    products: Product[];
    shop_domain: string;
    source: string | null;
    cart_params: GenericObject | null;
    product_collections: ProductCollections;
    is_calling_for_cart: boolean;
    subscriptionTabSelected: boolean | undefined;
    subscriptions_params?: SubscriptionParamsStorage[];

    constructor({
        shop_domain,
        custom_domain,
        customer,
        cart,
        products,
        page,
        currency,
        currency_symbol,
        money_format,
        order_conditions,
        source,
        cart_params,
        is_calling_for_cart,
        product_collections = {},
        skip_clearing_subs_cache = false,
    }: ShopInput) {
        this.shop_domain = shop_domain;
        this.custom_domain = custom_domain;
        this.customer = customer;
        this.cart = cart;
        this.products = products;
        this.page = page;
        this.currency = currency;
        this.currency_symbol = currency_symbol;
        this.money_format = money_format;
        this.custom_elements = [];
        this.order_data = this.getOrderData();
        this.cart_params = (cart_params == null) ? this.getCartParams() : cart_params;
        this.is_calling_for_cart = is_calling_for_cart;
        this.subscriptions_params = this.getSubscriptionParams();

        this.order_conditions = (order_conditions == null) ? this.getOrderConditions() : order_conditions;
        // same thing for preSource query
        this.source = (source == null) ? this.getSourceQuery() : source;
        this.product_collections = {};

        this.addProductCollections(product_collections);

        this.updateSubscriptionParams();
        if (this.cart?.items?.length === 0 && !skip_clearing_subs_cache) {
            OrderDataStorage.clearSubscriptionParams();
        }
    }

    updateSubscriptionParams() {
        if (config('staples_subs_ff')) {
            this.cart?.items?.filter((x: CartItem) => x.properties && x.properties._interval_id).forEach((cartItem: CartItem) => {
                this.addSubscriptionParams(
                    {
                        product_id: cartItem.product_id,
                        subsInfo: {
                            subscription_group_id: cartItem.subscriptionGroupId,
                            subscription_interval_id: cartItem.properties?._interval_id,
                        },
                    } as SubscriptionParamsStorage,
                );
            });
        }
    }

    setSubscriptionSelect(selected?: string | null) {
        this.subscriptionTabSelected = (selected && selected === 'true') as boolean;
    }

    resetSubscriptionTabSelected() {
        const subscriptionTabsElement = PlatformScanner.getCustomSubscriptionTabsElement();
        if (subscriptionTabsElement) {
            this.setSubscriptionSelect(subscriptionTabsElement.getAttribute('data-subscription-selected'));
        }
    }

    isSubscriptionSelected(): boolean {
        return <boolean> this.subscriptionTabSelected;
    }

    /**
     * If true, non-subscription pricerules will have no impact on subscription prices
     * If false, non-subscription pricerules will get evaluated on subscription prices
     */
    isSubscriptionPricesLocked(): boolean {
        return false;
    }

    setPlatform(platform: Platform) {
        this.platform = platform;
    }

    getProductById(id: string): Product|undefined {
        return this.products.find((product) => product.getId() === id);
    }

    getProductByVariantId(id: string): Product|undefined {
        /**
         * Try to take a shortcut that may stop working in future
         * if we do less looping in bold-common.liquid.
         */
        let productId = BOLD?.common?.Shopify?.variants?.[id]?.product_id ?? null;
        productId = normalizeIdentifier(productId);

        if (productId) {
            return this.getProductById(productId);
        }

        /** If that didn't work then we loop to find the product containing this variant. */
        return this.products.find((product) => {
            const foundIt = product.getVariants().find((variant) => variant.getId() === id);
            return !!foundIt;
        });
    }

    /**
     * This gets a variant by ID
     */
    getVariantById(id: string) {
        const variantFromBoldCommon = BOLD.common?.Shopify?.variants?.[id];

        if (variantFromBoldCommon) {
            return variantFromBoldCommon;
        }
        let variant = null;
        /** If that didn't work then we loop to find the product containing this variant. */
        for (const product of this.products) {
            const foundVariant = product.getVariants().find((variant) => variant.getId() === id);

            if (foundVariant != null) {
                variant = foundVariant;
            }
        }

        return variant;
    }

    getProductByProductHandle(productHandle: string) {
        return this.products.find((product) => product.handle === productHandle);
    }

    hasProducts() {
        return this.products && this.products.length > 0;
    }

    getAllVariants(): Variant[] {
        const cartVariants = this.getCart().getVariants();
        const variants = this.getProducts().reduce((carry: Variant[], product: Product): Variant[] => {
            return carry.concat(product.getVariants());
        }, []);

        return cartVariants.concat(variants);
    }

    getVariantsByProductIds(productIds: Array<string>) {
        productIds = productIds.map(normalizeIdentifier) as string[];
        const allVariants = this.getAllVariants();
        return allVariants.filter((v) => productIds.includes(v.product_id));
    }

    static getCartCurrency() {
        return ENV.BROWSER
            ? BOLD.common?.Shopify?.cart?.currency
            : null;
    }

    static getShopCurrency() {
        return ENV.BROWSER
            ? BOLD.common?.Shopify?.shop?.currency
            : null;
    }

    hasCartItems() {
        return this.cart && this.cart.items && this.cart.items.length > 0;
    }

    /**
     * Re-set this shop to the factory defaults.
     */
    reset() {
        const products = this.getProducts();
        products.forEach((p) => p.reset());
    }

    subscriptionResetId() {
        // this.getProducts().forEach((p) => { p.subscriptionGroupId = null; });
        // this.getProducts().forEach((p) => { p.subscriptionIntervalId = null; });
    }

    /********************************
     * ⤓ Simple getters / toJSON ⤓ *
     ********************************/

    getShopDomain() {
        return this.shop_domain;
    }

    getCustomDomain() {
        return this.custom_domain;
    }

    getCustomer() {
        return this.customer;
    }

    getCart(): Cart {
        return this.cart;
    }

    getProducts() {
        return Array.isArray(this.products) ? this.products : [];
    }

    getPage() {
        return this.page;
    }

    getCurrency() {
        return this.currency;
    }

    getCurrencySymbol() {
        return this.currency_symbol;
    }

    getMoneyFormat() {
        return this.money_format;
    }

    makeCopyWithoutProducts() {
        const shopCopy = new Shop(this.toJSON());
        shopCopy.products = [];
        // @ts-expect-error
        shopCopy.cart = null;
        return shopCopy;
    }

    toJSON(): ShopInput {
        return {
            shop_domain: this.shop_domain,
            custom_domain: this.custom_domain,
            customer: this.customer,
            cart: this.cart,
            products: this.products,
            currency: this.currency,
            currency_symbol: this.currency_symbol,
            money_format: this.money_format,
            order_conditions: this.order_conditions,
            cart_params: this.cart_params,
            page: this.page,
            source: this.source,
            product_collections: this.product_collections,
            is_calling_for_cart: this.is_calling_for_cart,
            subscriptionTabSelected: this.subscriptionTabSelected,
            subscriptions_params: this.subscriptions_params,
            skip_clearing_subs_cache: true,
        };
    }

    hasConditionName(conditionName: string, conditionValue: string): boolean {
        return this.order_conditions != null && this.order_conditions.get(conditionName) === conditionValue;
    }

    getOrderData() {
        return OrderDataStorage.fetchOrderData();
    }

    getCartParams() {
        return OrderDataStorage.fetchCartParams();
    }

    getOrderConditions() {
        return OrderDataStorage.fetchOrderConditions();
    }

    setOrderData(orderData: GenericObject) {
        OrderDataStorage.storeOrderData(orderData);
        OrderConditionClient.applyOrderConditions(this, orderData);
        this.order_data = orderData;
        channel.emit(EVENTS.ORDER_DATA_UPDATED, orderData);
    }

    addCartParam(key: string, cartParam: GenericObject) {
        this.cart_params?.set(key, cartParam);
        OrderDataStorage.storeCartParams(this.cart_params);
    }

    setOrderConditions() {
        this.order_conditions = this.getOrderConditions();
    }

    setCurrencySymbol(currencySymbol: string) {
        this.currency_symbol = currencySymbol;
    }

    setMoneyFormat(moneyFormat: string) {
        this.money_format = moneyFormat;
    }

    getSourceQuery() {
        let source = SourceStorage.fetchSource();
        if (source === '' && source != null) {
            const urlParams = new URLSearchParams(window.location.search);
            source = urlParams.get('preSource');
            if (source !== '' && source != null) {
                SourceStorage.storeSource(source);
            }
        }
        return source;
    }

    addProductCollections(productCollections: ProductCollections) {
        const productIds = Object.keys(productCollections);
        for (let i = 0; i < productIds.length; i++) {
            const collectionIds = productCollections[productIds[i]];
            const productId = normalizeIdentifier(productIds[i]) as string;
            this.product_collections[productId] = collectionIds;
        }
    }

    productInCollection(productId: string, collectionId: string) {
        const productCollections = this.product_collections[productId] || [];
        return Boolean(productCollections.find(c => equalIdentifiers(c, collectionId)));
    }

    productInCollections(productId: string, collectionIds: string[]) {
        for (let j = 0; j < collectionIds.length; j++) {
            const collectionId = collectionIds[j];
            if (this.productInCollection(productId, collectionId)) {
                return true;
            }
        }
        return false;
    }

    productsInCollections(collectionIds: string[]): string[] {
        const matchedProductIds = [];
        const productIdCandidates = Object.keys(this.product_collections);
        for (let i = 0; i < productIdCandidates.length; i++) {
            const productIdCandidate = productIdCandidates[i];
            if (this.productInCollections(productIdCandidate, collectionIds)) {
                matchedProductIds.push(productIdCandidate);
            }
        }
        return matchedProductIds;
    }

    addSubscriptionParams(item: SubscriptionParamsStorage) {
        if (!this.subscriptions_params) {
            this.subscriptions_params = [] as SubscriptionParamsStorage[];
        }

        if (this.subscriptions_params.filter((x) => x.product_id === item.product_id).length === 0) {
            this.subscriptions_params.push(item);
            if (ENV.BROWSER) {
                OrderDataStorage.storeSubscriptionParams(this.subscriptions_params);
            }
        }
    }

    isCartModalShowing() {
        const modalContainerClassName = 'cart_modal';
        const addToCartModalContainer = document.getElementsByClassName(modalContainerClassName);
        return addToCartModalContainer && addToCartModalContainer.length > 0 && (addToCartModalContainer[0] as HTMLElement).innerText === 'Loading...';
    }

    getSubscriptionParams() {
        if (ENV.BROWSER) {
            return OrderDataStorage.getSubscriptionParams();
        }
        return this.subscriptions_params;
    }
}

export default Shop;
