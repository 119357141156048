import Client from './Client';
import Shop from '../models/platform/Shop';
import { channel, EVENTS } from '../events';
import CurrencyStorage from '../components/CurrencyStorage';
import { config, SETTINGS } from '../config';

/*
 * Currency storage
 *
 * This class fetches and sets the shop currency
 *
 */
class GetCurrencySymbolClient {
    static get(shop: Shop, currencyName: string) {
        CurrencyStorage.setIsFetchingCurrency(shop);
        Client.request('GET', `storefront/v2/currency/${currencyName}`)
            .then(response => {
                if (Object.keys(response).length === 0) {
                    return;
                }
                const symbol = response.currency_symbol;
                const format = response.money_format;
                CurrencyStorage.storeData({
                    shop_domain: shop.shop_domain,
                    currencySymbol: symbol,
                    moneyFormat: format,
                    currencyName: currencyName,
                    lastUpdated: Date.now(),
                    isUpdating: false,
                });

                if (config(SETTINGS.multi_currency) === true) {
                    shop.setCurrencySymbol(symbol);
                    shop.setMoneyFormat(format);
                }

                channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
            });
    }
}

export default GetCurrencySymbolClient;
