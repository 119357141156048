import Rule from '../../Rule';
import Shop from '../../platform/Shop';
import container from '../../../components/Container';
import { makeVariantId } from '../../../helpers/identifier';
import { RULE_DEFINITIONS } from '../../../constants';
export interface OfferActions {
    [key: string]: number;
}

export interface Offer {
    id: number;
    rule?: Rule;
    buy: {quantity: number, items: string[]};
    get: {quantity: number, items: string[]};
    getTimesLimit?: number;
    getsAvailable?: number;
    actions: OfferActions;
    priority?: number;
}

export interface BuyXGetYCartItemInput {
    productId: string;
    variantId: string;
    originalPrice: number;
    quantity: number;
    lineItemId: string;
    sku: string;
}

interface BuyXGetYCartItem {
    productId: string;
    variantId: string;
    originalPrice: number;
    sku: string;
    quantity: number;
    key: string; // A composite of the id and the nth single qty item
    lineItemId: string;
    offerIds?: number[];
    buyOfferId?: number;
    discountedPrice?: number;
}

type BuyXGetYCartItemCombined = Omit<BuyXGetYCartItem, 'key'> & {
    offers: Offer[];
    total: number;
    computedPrice: number;
    discountedPrice?: number; // Not used yet
}

export type BuyXGetYCartItemResult = Omit<BuyXGetYCartItemCombined, 'total'>;

export function getCartResult(cartItems: BuyXGetYCartItemInput[], offers: Offer[]): BuyXGetYCartItemResult[] {
    const shop = container.get(Shop);

    offers = offers.map(offer => {
        if (offer.getTimesLimit) {
            offer.getsAvailable = offer.getTimesLimit * offer.get.quantity;
        }
        return { ...offer };
    });

    const result = bestCartRec(cartToSingleQuantity(cartItems), 0, offers, shop);

    const cartResult = cartSummaryCombined(result, offers);

    return cartResult;
}

function cartSummaryCombined(items: BuyXGetYCartItem[], offers: Offer[]): BuyXGetYCartItemResult[] {
    const cart: BuyXGetYCartItemCombined[] = [];

    items.forEach((item) => {
        let cartItem = cart.find(ci => ci.lineItemId === item.lineItemId);
        const matchedOffers = offers.filter((o) => item.offerIds?.includes(o.id));
        const price = calcItemPrice(item, matchedOffers);
        if (cartItem === undefined) {
            cartItem = {
                lineItemId: item.lineItemId,
                productId: item.productId,
                variantId: item.variantId,
                originalPrice: item.originalPrice,
                quantity: 1,
                total: 0,
                sku: item.sku,
                offerIds: item.offerIds,
                buyOfferId: item.buyOfferId,
                offers: [],
                computedPrice: 0,
            } as BuyXGetYCartItemCombined;
            cart.push(cartItem);
        } else {
            cartItem.quantity++;
        }
        if (matchedOffers && matchedOffers.length > 0) {
            matchedOffers.forEach(offer => offer && cartItem?.offers.push(offer));
        }
        cartItem.total += price;
    });

    return cart.map((item) => ({
        lineItemId: item.lineItemId,
        productId: item.productId,
        variantId: item.variantId,
        offers: item.offers,
        quantity: item.quantity,
        originalPrice: item.originalPrice,
        sku: item.sku,
        computedPrice: Math.round(item.total / item.quantity),
    }));
}

/**
 * If there are offer.get/buy.items that are included in cartItem.variantId.split('|'), this means that the product has a sku on the shop,
 * but our ruleset is based on product_id only, so we must remove the sku from the variant so it only contains what we actually want, and we
 * prevent adding these checks further down the line.
 */
function adjustVariantsOnOffers(offers: Offer[], cartItem: BuyXGetYCartItem, availableCartItems: BuyXGetYCartItem[]) {
    offers.forEach(offer => {
        const getId = offer.get.items.find((g) => cartItem.variantId?.split('|').length > 1 && cartItem.variantId?.split('|').some(id => id === g));
        const buyId = offer.buy.items.find((g) => cartItem.variantId?.split('|').length > 1 && cartItem.variantId?.split('|').some(id => id === g));
        if (getId) {
            availableCartItems.map(x => {
                if (x.variantId === cartItem.variantId) {
                    x.variantId = getId;
                }
            });
            cartItem.variantId = getId;
        }
        if (buyId) {
            availableCartItems.map(x => {
                if (x.variantId === cartItem.variantId) {
                    x.variantId = buyId;
                }
            });
            cartItem.variantId = buyId;
        }
    });
}

function bestCartRec(cart: BuyXGetYCartItem[], index: number, offers: Offer[], shop: Shop): BuyXGetYCartItem[] {
    if (index > cart.length - 1) {
        return cart;
    }

    const noActionCart = bestCartRec(cart, index + 1, offers, shop);
    const cartItem = cart[index];
    const availableCartItems = cart.filter(itemEligible);

    if (!itemEligible(cartItem)) {
        return noActionCart;
    }

    adjustVariantsOnOffers(offers, cartItem, availableCartItems);

    let matchedGetsOffers = offers
        .filter((offer) => offer.get.items.find((g) => g === cartItem.variantId) !== undefined)
        .filter((offer: Offer) => {
            if (typeof offer.getsAvailable === 'number' && offer.getsAvailable === 0) {
                return false;
            }
            return true;
        });

    matchedGetsOffers = organizePrioritiesByLayers(matchedGetsOffers);

    if (matchedGetsOffers.length === 0) {
        return noActionCart;
    }

    let bestCart = noActionCart;

    for (let i = 0; i < matchedGetsOffers.length; i++) {
        const matchedOffer = matchedGetsOffers[i];
        const matchedGetItems = availableCartItems.filter((item) => matchedOffer.get.items.includes(item.variantId));
        const matchedBuyItems = availableCartItems.filter((item) => matchedOffer.buy.items.includes(item.variantId));
        const getCombinations = k_combinations(matchedGetItems.map((i) => i.key), matchedOffer.get.quantity);
        const buyCombinations = k_combinations(matchedBuyItems.map((i) => i.key), matchedOffer.buy.quantity);

        if (matchedOffer.get.quantity <= matchedGetItems.length && matchedOffer.buy.quantity <= matchedBuyItems.length) {
            for (let j = 0; j < getCombinations.length; j++) {
                for (let k = 0; k < buyCombinations.length; k++) {
                    const getComboKeys = getCombinations[j];
                    const buyComboKeys = buyCombinations[k];
                    const intersection = getComboKeys.filter((g) => buyComboKeys.includes(g));
                    if (intersection.length === 0) {
                        const copiedCart = copyCart(cart);
                        const offersCopy = copyOffers(offers);
                        const offerCopy = offersCopy.find(offer => offer.id === matchedOffer.id);
                        if (typeof offerCopy?.getsAvailable === 'number') {
                            offerCopy.getsAvailable -= offerCopy.get.quantity;
                            offersCopy.forEach((item) => {
                                if (typeof item.priority === 'number' &&
                                    typeof offerCopy.priority === 'number' &&
                                    item.priority > offerCopy.priority) {
                                    // Since we are using some getsAvailable from `offerCopy`, we need to remove them from other rules if
                                    // they are of lower priority
                                    item.getsAvailable = 0;
                                }
                            });
                        }
                        const getOfferCombinations = getOfferCombinationsFromMatches(matchedGetsOffers);
                        getComboKeys.forEach((g) => setCartItemGetOfferProp(copiedCart, g, 'offerIds', getOfferCombinations));
                        buyComboKeys.forEach((b) => setCartItemProp(copiedCart, b, 'buyOfferId', matchedOffer.id));
                        const candidateCart = bestCartRec(copiedCart, index + 1, offersCopy, shop);
                        if (cartCheaperThan(candidateCart, bestCart, offersCopy)) {
                            bestCart = candidateCart;
                        }
                    }
                }
            }
        } else if (typeof matchedOffer.priority === 'number') {
            /**
             * If we get here, that means that the priority rule did not pass (not enough quantities in the cart)
             * So we simulate lowering priority for this rule, and re-run bestCartRec
             */
            const copiedCart = copyCart(cart);
            const offersCopy = copyOffers(offers);
            const offerCopy = offersCopy.find(offer => offer.id === matchedOffer.id);
            if (typeof offerCopy?.getsAvailable === 'number') {
                offerCopy.getsAvailable--;
                offersCopy.forEach((item) => {
                    if (offerCopy.id === item.id) {
                        item.priority = 999;
                    }
                });
            }
            bestCart = bestCartRec(copiedCart, index, offersCopy, shop);
        }
    }

    return bestCart;
}

function getOfferCombinationsFromMatches(offers: Offer[]): number[] {
    return organizePrioritiesByLayers(offers).map(x => x.id);
}

function comparePriority(offer1: Offer, offer2: Offer): number {
    if (typeof offer1.priority === 'number' && typeof offer2.priority === 'number') {
        return offer1.priority - offer2.priority;
    } else if (typeof offer1.priority === 'number') {
        return -1;
    } else if (typeof offer2.priority === 'number') {
        return 1;
    } else {
        return 0;
    }
}

function filterPriorities(offers: Offer[]): Offer[] {
    if (offers.length === 0) {
        return offers;
    }
    const sortedByPriority = offers.sort(comparePriority);
    if (typeof sortedByPriority[0].priority === 'number') {
        const highestPriority = sortedByPriority[0].priority;
        return sortedByPriority.filter(x => x.priority === highestPriority);
    }
    return offers;
}

/**
 * Different layers have different properties, so we need to divide up the rules and re-arrange them
 * Layer 0 goes by cheapest price, only 1 rule here can apply
 * Layer 1 is stackable, and supports priorities and stack_orders, so we need to order them accordingly
 * Layer 2 goes by cheapest price, but also supports priorities
 * Layer 3 is stackable, and also supports priorities and stack_order
 *
 * These layers are independent of eachother. Layer 0 will determine the best price within its layer, regardless
 * if PRE could apply a bigger discount if it were to apply a less-discounted price
 */
function organizePrioritiesByLayers(offers: Offer[]): Offer[] {
    const [layer0, layer1, layer2, layer3] = divideRulesByLayers(offers);

    const layer0Prioritized = filterPriorities(layer0);
    const layer1Prioritized = filterPriorities(layer1);
    const layer2Prioritized = filterPriorities(layer2);
    const layer3Prioritized = filterPriorities(layer3);

    return [...layer0Prioritized, ...layer1Prioritized, ...layer2Prioritized, ...layer3Prioritized];
}

function divideRulesByLayers(offers: Offer[]): Offer[][] {
    const layer0 = offers.filter(offer => offer.rule && RULE_DEFINITIONS[offer.rule.getType()].layer === 0);
    const layer1 = offers.filter(offer => offer.rule && RULE_DEFINITIONS[offer.rule.getType()].layer === 1);
    const layer2 = offers.filter(offer => offer.rule && RULE_DEFINITIONS[offer.rule.getType()].layer === 2);
    const layer3 = offers.filter(offer => offer.rule && RULE_DEFINITIONS[offer.rule.getType()].layer === 3);

    return [layer0, layer1, layer2, layer3];
}

function itemEligible(item: BuyXGetYCartItem): boolean {
    return item.offerIds === undefined && typeof item.buyOfferId !== 'number';
}

function setCartItemProp(cart: BuyXGetYCartItem[], key: string, prop: 'buyOfferId', value: number) {
    const item = cart.find((item) => item.key === key);
    if (item) {
        item[prop] = value;
    }
}

function setCartItemGetOfferProp(cart: BuyXGetYCartItem[], key: string, prop: 'offerIds', value: number[]) {
    const item = cart.find((item) => item.key === key);
    if (item) {
        item[prop] = value;
    }
}

function cartToSingleQuantity(c: BuyXGetYCartItemInput[]): BuyXGetYCartItem[] {
    const newCart: BuyXGetYCartItem[] = [];
    for (let i = 0; i < c.length; i++) {
        const quantity = c[i].quantity;
        for (let j = 1; j <= quantity; j++) {
            const item = c[i];
            const variantId = !item.sku ? item.variantId : makeVariantId(item.productId, item.variantId, item.sku);
            const copy: BuyXGetYCartItem = {
                ...item,
                quantity: 1,
                variantId: variantId,
                sku: item.sku,
                key: `${variantId}/${j}`,
            };
            newCart.push(copy);
        }
    }
    return newCart;
}

function copyItem<T>(i: T): T {
    return { ...i };
}

function copyOffers(offers: Offer[]): Offer[] {
    return offers.map(offer => {
        if (typeof offer.getsAvailable === 'number') {
            return { ...offer };
        }
        return offer;
    });
}

function copyCart(c: BuyXGetYCartItem[]): BuyXGetYCartItem[] {
    const newCart = [];
    for (let i = 0; i < c.length; i++) {
        newCart.push(copyItem(c[i]));
    }
    return newCart;
}

function cartCheaperThan(c1: BuyXGetYCartItem[], c2: BuyXGetYCartItem[], offers: Offer[]): boolean {
    return cartTotal(c1, offers) < cartTotal(c2, offers);
}

function cartTotal(c: BuyXGetYCartItem[], offers: Offer[]): number {
    let total = 0;
    for (let i = 0; i < c.length; i++) {
        total += calcItemPrice(c[i], offers);
    }
    return total;
}

function calcItemPrice(item: BuyXGetYCartItem, offers: Offer[]): number {
    if (item.offerIds && item.offerIds.length > 0 && typeof item.offerIds[0] === 'number') {
        offers = orderOffersWithinLayers(offers, item);

        // A product/variant can have multiple rules applied to it from the same ruleset
        const offerList = offers.filter((offer) => offer && item.offerIds?.includes(offer.id));

        return offerList.reduce((total, offer) => {
            /**
             * Ie. If the originalPrice was $100, and the first rule was $10 off, and the 2nd rule was 50% off,
             * the 50% off rule has previously been translated to an ABSOLUTE price off of $50, so we need to
             * recalculate this number to make sure the 50% off is now applied based the remainder instead.
             * Absolutes and Relatives stay as-is.
             */
            const fraction = requiresPriceMultiplier(offer, item)
                ? total / item.originalPrice
                : 1;

            total += total + offer.actions[item.variantId] * fraction >= 0
                ? offer.actions[item.variantId] * fraction
                : 0;
            return total;
        }, item.originalPrice);
    }
    if (typeof item.discountedPrice === 'number') {
        return item.discountedPrice;
    }

    return item.originalPrice;
}

function requiresPriceMultiplier(offer: Offer, item: BuyXGetYCartItem): boolean {
    const isCorrectRule = offer.rule?.ruleset.product_selection.products?.some(x => x.variant_id === item.variantId) === true;
    return isCorrectRule && offer.rule?.actions.some(x => x.type === 'PRICE_ADJUST_PERCENT') === true;
}

/**
 * Sort rules within layers, because each layer has different rules for prioritizing
 */
function orderOffersWithinLayers(offers: Offer[], item: BuyXGetYCartItem): Offer[] {
    const [layer0, layer1, layer2, layer3] = divideRulesByLayers(offers);

    const layer0offer = layer0 ? layer0.sort(getBiggestDiscountOfferFromLayer(item))[0] : layer0;
    const layer1offer = layer1 ? layer1.sort(orderLayer1ByPriority) : layer1;
    const layer2offer = layer2 ? layer2.sort(getBiggestDiscountOfferFromLayer(item))[0] : layer2;

    return [layer0offer, ...layer1offer, layer2offer, ...layer3];
}

function orderLayer1ByPriority(offer1: Offer, offer2: Offer): number {
    return (offer1.rule?.stack_order ?? 0) < (offer2.rule?.stack_order ?? 0) ? -1 : 0;
}

function getBiggestDiscountOfferFromLayer(item: BuyXGetYCartItem) {
    return function (offer1: Offer, offer2: Offer): number {
        if (offer1.actions[item.variantId] && offer2.actions[item.variantId]) {
            return cartTotal([item], [offer1]) < cartTotal([item], [offer2]) ? -1 : 0;
        } else if (offer1.actions[item.variantId]) {
            return -1;
        } else {
            return 0;
        }
    };
}

/**
 * https://gist.github.com/axelpale/3118596
 */
function k_combinations(set: string[], k: number): string[][] {
    let i, j, combs, head, tailcombs;

    // There is no way to take e.g. sets of 5 elements from
    // a set of 4.
    if (k > set.length || k <= 0) {
        return [];
    }

    // K-sized set has only one K-sized subset.
    if (k === set.length) {
        return [set];
    }

    // There is N 1-sized subsets in a N-sized set.
    if (k === 1) {
        combs = [];
        for (i = 0; i < set.length; i++) {
            combs.push([set[i]]);
        }
        return combs;
    }

    // Assert {1 < k < set.length}

    // Algorithm description:
    // To get k-combinations of a set, we want to join each element
    // with all (k-1)-combinations of the other elements. The set of
    // these k-sized sets would be the desired result. However, as we
    // represent sets with lists, we need to take duplicates into
    // account. To avoid producing duplicates and also unnecessary
    // computing, we use the following approach: each element i
    // divides the list into three: the preceding elements, the
    // current element i, and the subsequent elements. For the first
    // element, the list of preceding elements is empty. For element i,
    // we compute the (k-1)-computations of the subsequent elements,
    // join each with the element i, and store the joined to the set of
    // computed k-combinations. We do not need to take the preceding
    // elements into account, because they have already been the i:th
    // element so they are already computed and stored. When the length
    // of the subsequent list drops below (k-1), we cannot find any
    // (k-1)-combs, hence the upper limit for the iteration:
    combs = [];
    for (i = 0; i < set.length - k + 1; i++) {
        // head is a list that includes only our current element.
        head = set.slice(i, i + 1);
        // We take smaller combinations from the subsequent elements
        tailcombs = k_combinations(set.slice(i + 1), k - 1);
        // For each (k-1)-combination we join it with the current
        // and store it to the set of k-combinations.
        for (j = 0; j < tailcombs.length; j++) {
            combs.push(head.concat(tailcombs[j]));
        }
    }
    return combs;
}
