
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import { execute as rulesetGenerator } from './buyXGetY/RulesetGenerator';
import CartItem from '../platform/CartItem';
import Shop from '../platform/Shop';
import Ruleset from '../Ruleset';
import Variant from '../platform/Variant';
import CartBaseCondition from './CartBaseCondition';

interface ProductSelectionVariant {
    variant_ids: string[];
    product_ids: undefined;
    collection_ids: undefined;
    sku_ids: undefined;
}

interface ProductSelectionProduct {
    variant_ids: undefined;
    product_ids: string[];
    collection_ids: undefined;
    sku_ids: undefined;
}

interface ProductSelectionCollection {
    variant_ids: undefined;
    product_ids: undefined;
    collection_ids: string[];
    sku_ids: undefined;
}

interface ProductSelectionSku {
    variant_ids: undefined;
    product_ids: undefined;
    collection_ids: undefined;
    sku_ids: string[];
}

export type BXGYProductSelection = ProductSelectionProduct|ProductSelectionVariant|ProductSelectionCollection|ProductSelectionSku;

interface Input {
    buy_product_selection: BXGYProductSelection;
    buy_quantity?: number;
    get_quantity?: number;
    priority?: number;
    uses_per_order_limit?: number;
}

const dynamicRulesetsCreatedKey = 'dynamicRulesetsCreated';

class BuyXGetYCondition extends CartBaseCondition {
    buy_product_selection: BXGYProductSelection;
    buy_quantity: number;
    get_quantity: number;
    uses_per_order_limit: number;
    priority?: number;

    constructor({
        type,
        buy_product_selection,
        buy_quantity,
        get_quantity,
        priority,
        uses_per_order_limit,
    }: BaseConditionInput & Input) {
        super({ type, requiresPrepare: true });

        this.buy_product_selection = buy_product_selection;
        this.buy_quantity = buy_quantity || 1;
        this.get_quantity = get_quantity || 1;
        this.priority = priority;
        this.uses_per_order_limit = uses_per_order_limit || 1;
    }

    /**
     * For every BXGY rule, we create a dynamic PRICE_ADJUST_ABSOLUTE rule and add it to the ruleset array
     */
    prepare(item: CartItem, shop: Shop, rulesets: Ruleset[]) {
        const state = shop.cart.getRuleState(BuyXGetYCondition);
        if (!state.has(`${dynamicRulesetsCreatedKey}_${item.variant_id}`)) {
            const bxgyRulesToApply = rulesetGenerator(shop.cart, rulesets);
            rulesets.push(...bxgyRulesToApply);
            state.set(`${dynamicRulesetsCreatedKey}_${item.variant_id}`, true);
        }
    }

    /**
     * We never want any original BXGY rule to actually apply, so we always return false here.
     * How the BXGY rules actually apply is through the dynamic rules created above. When these dynamic rules
     * get created, they are created as absolute types without any conditions attached, meaning they will always apply if created.
     */
    continueEvaluating(variant: Variant, shop: Shop): boolean {
        return false;
    }
}

export default BuyXGetYCondition;
