
import Log from '../helpers/Log';
import { RULE_DEFINITIONS } from '../constants';
import BucketFilter from './BucketFilter';
import { SETTINGS, config, ENV } from '../config';
import RuleApiResponse, { BasePrices, convertShopifyDiscountCodeToPRERulesets } from '../api/RuleApiResponse';
import Shop from '../models/platform/Shop';
import Variant from '../models/platform/Variant';
import Ruleset from '../models/Ruleset';
import Rule from '../models/Rule';
import { GenericObject } from '../helpers/object';
import { RuleDiscountType } from '../models/money/CartLevelDiscount';
import { ShopifyDiscountCode } from '../api/models/SerialShopifyDiscountCode';
import { channel, EVENTS } from '../events';
import ShopifyDiscountCodeStorage from './ShopifyDiscountCodeStorage';

let bucketId = 1;

type SortedRules = {
    layer0: Rule[];
    layer1: Rule[];
    layer2: Rule[];
    layer3: Rule[];
    layer4: Rule[];
};

/**
 * RuleProcessor
 */
class RuleProcessor {
    static async applyRules(rulePromises: Promise<RuleApiResponse>[], shop: Shop, shopifyDiscountCodeRulesets?: ShopifyDiscountCode[], discountCodeSetting?: number) {
        const responses = await Promise.all(rulePromises) as RuleApiResponse[];
        if (shopifyDiscountCodeRulesets && shopifyDiscountCodeRulesets.length > 0) {
            const product_ids = responses[0].product_ids;
            const codeRulesets = convertShopifyDiscountCodeToPRERulesets(shopifyDiscountCodeRulesets, product_ids, shop, discountCodeSetting || 2);
            codeRulesets.rulesets.sort((a, x) => a.rules.find(b => b.actions.find(c => c.type.includes('CART_LEVEL_DISCOUNT'))) ? 1 : -1);
            responses.push(codeRulesets);
        }
        responses.forEach(rp => shop.addProductCollections(rp.product_collections));
        responses.forEach(rp => RuleProcessor.applyRuleApiResponse(rp, shop, shopifyDiscountCodeRulesets));
    }

    static applyRulesSync(ruleApiResponses: RuleApiResponse[], shop: Shop, discountCodeSetting?: number) {
        const shopifyDiscountCodeRulesets: any[] = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(shop.shop_domain);
        if (shopifyDiscountCodeRulesets && shopifyDiscountCodeRulesets.length > 0) {
            const product_ids = ruleApiResponses[0].product_ids;
            const codeRulesets = convertShopifyDiscountCodeToPRERulesets(shopifyDiscountCodeRulesets, product_ids, shop, discountCodeSetting || 2);
            ruleApiResponses.push(codeRulesets);
        }
        ruleApiResponses.forEach((ruleApiResponse) => {
            RuleProcessor.applyRuleApiResponse(ruleApiResponse, shop, shopifyDiscountCodeRulesets);
        });
    }

    static applyRuleApiResponse(ruleApiResponse: RuleApiResponse, shop: Shop, shopifyDiscountCodeRulesets?: ShopifyDiscountCode[]) {
        const relevantVariants = shop.getVariantsByProductIds(ruleApiResponse.product_ids);
        RuleProcessor.applyBasePrices(relevantVariants, ruleApiResponse.base_prices);
        RuleProcessor.process(ruleApiResponse.rulesets, relevantVariants, shop, shopifyDiscountCodeRulesets);
        const usesShopifyDiscountCodes = ShopifyDiscountCodeStorage.getShopifyDiscountCodeSettings(shop.shop_domain);
        if (!ENV.NODE && usesShopifyDiscountCodes && Number(usesShopifyDiscountCodes) > 0) {
            RuleProcessor.updateShopifyDiscountCodeMessage(shop);
            const numOfAppliedShopifyDiscountCodes = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(shop.shop_domain).length;
            if (shopifyDiscountCodeRulesets && shopifyDiscountCodeRulesets.length === numOfAppliedShopifyDiscountCodes) {
                const errorMsg = document.getElementById('bold-shopify-discount-code-error');
                if (errorMsg && errorMsg.parentElement) {
                    errorMsg.parentElement.style.display = 'none';
                }
            }
        }
    }

    static renderShopifyDiscountCodeErrorMessage(msg: string) {
        const errorMsg = document.getElementById('bold-shopify-discount-code-error');
        if (errorMsg && errorMsg.parentElement) {
            errorMsg.innerText = msg;
            errorMsg.parentElement.style.cssText = 'width: 100%;display: inline-block;';
        }
    }

    static updateShopifyDiscountCodeMessage(shop: Shop) {
        const msgElement = document.getElementById('bold-shopify-discount-code-message');
        if (!msgElement) {
            return;
        }
        const appliedDiscountCodeElements = document.getElementsByClassName('bold-shopify-discount-code-container');
        while (appliedDiscountCodeElements[0]) {
            appliedDiscountCodeElements[0].parentNode?.removeChild(appliedDiscountCodeElements[0]);
        }
        const shopifyDiscountCodeRulesetsApplied = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(shop.shop_domain);
        shopifyDiscountCodeRulesetsApplied.map((ruleset: ShopifyDiscountCode) => {
            const stylesElement = document.getElementById('bold-shopify-discount-codes-styling');
            if (!stylesElement) return;
            const styles = JSON.parse(stylesElement.innerHTML);

            const discountCodeContainer = document.createElement('div');
            discountCodeContainer.style.cssText = 'width: 100%;display: inline-block;';
            discountCodeContainer.className = 'bold-shopify-discount-code-container';

            const discountCodeElement = document.createElement('div');
            discountCodeElement.style.cssText = styles.appliedDiscountCodeContainerCss;
            discountCodeElement.className = 'appliedDiscountCodeContainerCss';

            const iconElement = document.createElement('span');
            iconElement.innerHTML = '&#9988';
            iconElement.className = 'appliedDiscountCodeIconStyle';
            iconElement.style.cssText = styles.appliedDiscountCodeIconStyle;

            const buttonElement = document.createElement('span');
            buttonElement.innerHTML = '&#10006';
            buttonElement.className = 'appliedDiscountCodeRemoveIconStyle';
            buttonElement.style.cssText = styles.appliedDiscountCodeRemoveIconStyle;
            buttonElement.onclick = () => RuleProcessor.removeShopifyDiscountCode(shop, ruleset.title);

            discountCodeElement.append(iconElement);
            discountCodeElement.append(document.createTextNode(ruleset.title.toUpperCase()));
            discountCodeElement.append(buttonElement);
            discountCodeContainer.append(discountCodeElement);
            msgElement.prepend(discountCodeContainer);
        });
    }

    static removeShopifyDiscountCode(shop: Shop, code: string): void {
        ShopifyDiscountCodeStorage.clearShopifyDiscountCodeData(shop.shop_domain, code);
        shop.cart.removeCartLevelDiscount(code);
        channel.dispatch(EVENTS.CART_STATE_UPDATED);
    }

    static process(rulesets: Ruleset[], variants: Variant[], shop: Shop, shopifyDiscountCodeRulesets?: ShopifyDiscountCode[]) {
        // Prepare rulesets prior to execution
        RuleProcessor.prepareRulesets(rulesets, shop);

        /**
         * Divide rules into layers:
         *  LAYER_1 : Stackable
         *  LAYER_2 : Non-stackable
         *  LAYER_3 : Stackable, Tail
         *  LAYER_4 : Cart Level Discount
         */
        const layers = RuleProcessor.divideRulesIntoLayers(rulesets);

        const rulesetIds = rulesets.map((rs) => rs.id);

        /**
         * Apply layers to each variant
         */
        variants.forEach((variant) => {
            RuleProcessor.processVariant(layers, variant, shop);
            variant.addAppliedRulesetIds(rulesetIds);
        });

        /**
         * Store applied shopify discount codes to LocalStorage
         */
        if (!ENV.NODE && shopifyDiscountCodeRulesets && shopifyDiscountCodeRulesets.length > 0) {
            variants.forEach((variant) => {
                if (variant.getLogs().filter(log => log.event === 'RULE_MATCHED').length === 0) return;

                const logs = variant.getLogs().filter(log => variant.getLogs().find(x => x.ruleset_id === log.ruleset_id));
                if (!logs) return;

                const shopifyDiscountCodeBucketLogs = logs
                    .filter(log => log.event === 'BUCKET_CHOSEN')
                    .filter(log => log.ruleset_external_id && log.ruleset_external_id.includes('shopify_discount_code'));
                shopifyDiscountCodeBucketLogs.forEach(log => {
                    const shopifyRuleset = shopifyDiscountCodeRulesets.find(x => x.id === log.ruleset_id);
                    if (shopifyRuleset) {
                        ShopifyDiscountCodeStorage.storeData(shop.shop_domain, shopifyRuleset);
                    }
                });
            });
        }

        /* develblock:start */
        rulesets.length > 0 && Log.debug(`${rulesets.length} rulesets processed.`);
        /* develblock:end */
    }

    static prepareRulesets(rulesets: Ruleset[] = [], shop: Shop) {
        // INFO: Considering the amount of loops in this method, rulesets need to be filtered eventually.
        // I.E.: If there are 3 rulesets each one with 3 rules, each having 3 conditions that requires preparation and 3 cart items then we would be looping 3^4, totaling 81 times.
        // On the bright side, if the condition doesn't require preparation then it will be skipped.
        for (let i = 0; i < rulesets.length; i++) {
            const ruleset = rulesets[i];
            for (let j = 0; j < ruleset.rules.length; j++) {
                const rule = ruleset.rules[j];
                for (let k = 0; k < rule.conditions.length; k++) {
                    const condition = rule.conditions[k];
                    // Filter conditions that require preparation
                    if (condition.requiresPrepare) {
                        for (let l = 0; l < shop.cart.items.length; l++) {
                            const item = shop.cart.items[l];
                            if (ruleset.matchesVariant(item.variant)) {
                                condition.prepare(item, shop, rulesets, rule);
                            }
                        }
                    }
                }
            }
        }
    }

    static layerFilter(variant: Variant) {
        return function (rules: Rule[]) {
            return rules.filter((rule) => rule.ruleset?.matchesVariant(variant));
        };
    }

    static processVariant({
        layer0,
        layer1,
        layer2,
        layer3,
        layer4,
    }: SortedRules, variant: Variant, shop: Shop) {
        const [filteredLayer0, filteredLayer1, filteredLayer2, filteredLayer3, filteredLayer4] = [layer0, layer1, layer2, layer3, layer4].map(RuleProcessor.layerFilter(variant));

        RuleProcessor.applyLayersToVariant({
            layer0: filteredLayer0,
            layer1: filteredLayer1,
            layer2: filteredLayer2,
            layer3: filteredLayer3,
            layer4: filteredLayer4,
        }, variant, shop);
    }

    static applyLayersToVariant({
        layer0,
        layer1,
        layer2,
        layer3,
        layer4,
    }: SortedRules, variant: Variant, shop: Shop) {
        /**
         * Layer 0 - Base price
         * Has priority and stack order support
         */
        if (layer0 && layer0.length > 0) {
            layer0 = RuleProcessor.filterRulePriority(layer0);
            RuleProcessor.applyCompetitiveLayer(layer0, variant, shop);
        }

        /**
         * Layer 1 - All applied
         * Has priority and stack order support
         */
        if (layer1 && layer1.length > 0) {
            layer1 = RuleProcessor.filterRulePriority(layer1);
            RuleProcessor.applyLayer(layer1, variant, shop, { layer: 1 });
        }

        /**
         * Layer 2 - One applied, lowest price selected
         */
        if (layer2 && layer2.length > 0) {
            RuleProcessor.applyCompetitiveLayer(layer2, variant, shop);
        }

        /**
         * Layer 3 - All applied
         * Has priority and stack order support
         */
        if (layer3 && layer3.length > 0) {
            layer3 = RuleProcessor.filterRulePriority(layer3);
            RuleProcessor.applyLayer(layer3, variant, shop, { layer: 3 });
        }
        /**
         * Layer 4 - Cart Level Discount applied
         * Lower discount priority
         */
        if (layer4 && layer4.length > 0) {
            RuleProcessor.applyLayer(layer4, variant, shop, { layer: 4 });
        }
        variant.parent?.processingFinished(shop);
    }

    static filterRulePriority(layer: Rule[]) {
        const map = new Map();
        let list : Rule[];
        let highestPriority = layer.length > 0 ? layer[0].priority : 0;
        for (let i = 0; i < layer.length; i++) {
            if (layer[i].priority < highestPriority) {
                highestPriority = layer[i].priority;
            }
            list = map.get(layer[i].priority) !== undefined ? list = map.get(layer[i].priority) : [];
            list.push(layer[i]);
            map.set(layer[i].priority, list);
        }

        return map.get(highestPriority);
    }

    /**
     * Rules in this layer will compete against each other
     * @param layer
     * @param variant
     * @param shop
     */
    static applyCompetitiveLayer(layer: Rule[], variant: Variant, shop: Shop) {
        if (layer.length > 0) {
            /**
             * Create a temporary bucket copy of each variant for
             * each rule that applies.
             * A BUCKET is: a variant copy with the applied rule saved.
             */
            const buckets = layer.reduce((carry: Variant[], rule) => {
                const bucket = variant.copy();
                bucketId++;

                const ruleApplies = rule.apply(bucket, shop, {
                    // This info is just for logs
                    bucket: bucketId,
                    layer: 2,
                });

                if (ruleApplies) {
                    bucket.ruleProcessorState = {
                        bucket_id: bucketId, // for debugging
                        rule,
                    };
                    carry.push(bucket);
                }

                return carry;
            }, []);

            /** Choose the bucket to apply */
            const chosenBucket = BucketFilter.selectBucket(buckets);

            /** Apply the chosen bucket */
            if (chosenBucket) {
                if (config(SETTINGS.verbose_logs)) {
                    // Transfer all bucket logs to chosen bucket
                    RuleProcessor.aggregateLogs(chosenBucket, buckets);
                }
                chosenBucket.log(`BUCKET_CHOSEN`, {
                    // This info is just for logs
                    variant_id: variant.id,
                    variant_price: variant.price.amt,
                    bucket: chosenBucket.ruleProcessorState.bucket_id,
                    rule_id: chosenBucket.ruleProcessorState.rule?.id,
                    rule_external_id: chosenBucket.ruleProcessorState.rule?.external_id,
                    rule_type: chosenBucket.ruleProcessorState.rule?.type,
                    ruleset_id: chosenBucket.ruleProcessorState.rule?.ruleset.id,
                    ruleset_external_id: chosenBucket.ruleProcessorState.rule?.ruleset.external_id,
                    ruleset_public_message: chosenBucket.ruleProcessorState.rule?.ruleset.public_name,
                    layer: 2,
                });

                RuleProcessor.syncVariantWithLayer2Winner(chosenBucket, variant);
            }
        }
    }

    static applyLayer(layer: Rule[], variant: Variant, shop: Shop, processorLog: GenericObject) {
        // @ts-ignore
        layer.sort((previous, next) => previous.stack_order - next.stack_order);

        layer.forEach((rule) => {
            rule.apply(variant, shop, processorLog);

            if (shop.getCart().cartLevelDiscounts.size > 0) {
                variant.log(`BUCKET_CHOSEN`, {
                    variant_id: variant.id,
                    variant_price: variant.price.amt,
                    rule_id: rule?.id,
                    rule_external_id: rule?.external_id,
                    rule_type: rule?.type,
                    ruleset_id: rule?.ruleset.id,
                    ruleset_external_id: rule?.ruleset.external_id,
                    ruleset_public_message: rule?.ruleset.public_name,
                    cart_level_discounts: Array.from(shop.getCart().cartLevelDiscounts, ([key, value]) => {
                        if (value.ruleDiscountType === RuleDiscountType.PERCENT) {
                            return { name: key, amount: value.amount, rule_type: 'PERCENTAGE' };
                        } else {
                            return { name: key, amount: value.amount, rule_type: 'FIXED_PRICE' };
                        }
                    }),
                });
            } else {
                variant.log(`BUCKET_CHOSEN`, {
                    variant_id: variant.id,
                    variant_price: variant.price.amt,
                    rule_id: rule?.id,
                    rule_external_id: rule?.external_id,
                    rule_type: rule?.type,
                    ruleset_id: rule?.ruleset.id,
                    ruleset_external_id: rule?.ruleset.external_id,
                    ruleset_public_message: rule?.ruleset.public_name,
                });
            }
        });
    }

    static syncVariantWithLayer2Winner(chosenBucket: Variant, variant: Variant) {
        variant.setDiscountData({
            message: chosenBucket.ruleProcessorState.rule!.ruleset.public_name,
            expiry: chosenBucket.ruleProcessorState.rule!.ruleset.expiry_date,
            source_app: chosenBucket.ruleProcessorState.rule!.ruleset.app_slug,
            layer_2_rule: chosenBucket.ruleProcessorState.rule!,
        });
        const data = chosenBucket.toJSON();
        variant.hydrate(data);
    }

    static divideRulesIntoLayers(rulesets: Ruleset[]): SortedRules {
        return rulesets.reduce((carry: SortedRules, ruleset) => {
            ruleset.getRules().forEach((rule) => {
                const ruleDef = RULE_DEFINITIONS[rule.getType()];
                if (!ruleDef) {
                    throw new Error(`Invalid rule type ${rule.getType()}.`);
                }
                rule.ruleset = ruleset;
                const { stackable, tail, basePrice, cart_level, layer } = ruleDef;
                if (layer === 3 || (stackable && tail)) {
                    carry.layer3.push(rule);
                } else if (stackable) {
                    carry.layer1.push(rule);
                } else if (cart_level) {
                    carry.layer4.push(rule);
                } else {
                    if (rule.stack_order !== undefined) {
                        throw new Error('Invalid rule field (stack_order) for type Discount');
                    }
                    if (basePrice) {
                        carry.layer0.push(rule);
                    } else {
                        carry.layer2.push(rule);
                    }
                }
            });

            return carry;
        }, { layer0: [], layer1: [], layer2: [], layer3: [], layer4: [] });
    }

    /**
     * Aggregates logs together onto the chosen bucket.
     *
     * Only for verbose logging mode.
     */
    static aggregateLogs(chosenBucket: Variant, buckets: Variant[]) {
        const layer2Logs = buckets.reduce((carry: GenericObject[], bucket: Variant) => {
            const logs = bucket.getLogs();

            const l2l = logs.filter((log) => log.layer === 2);

            return carry.concat(l2l);
        }, []);

        const otherLogs = chosenBucket.getLogs().filter((log) => log.layer !== 2);

        const aggrLogs = [
            ...otherLogs,
            ...layer2Logs,
        ];

        chosenBucket.setLogs(aggrLogs);
    }

    static applyBasePrices(variants: Variant[], base_prices: BasePrices) {
        if (!base_prices) {
            return;
        }
        variants.forEach((v) => {
            const variantBasePrice = base_prices[v.id];
            if (variantBasePrice) {
                v.price.setAmountWithoutEvent(variantBasePrice);
                v.original_price.setAmountWithoutEvent(variantBasePrice);
            }
        });
    }
}

export default RuleProcessor;
