
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';
import { intersection } from '../../../helpers/array';

class CustomerInAnyGroupFromListCondition extends BaseCondition {
    list: string[];

    constructor({ type, list }: BaseConditionInput & { list: string[] }) {
        super({ type });
        this.list = list;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        const customer = shop.getCustomer();

        if (!customer.hasTags()) {
            return false;
        }

        const customerGroupsInTheList = intersection(customer.getTags(), this.list);
        return customerGroupsInTheList.length > 0;
    }
}

export default CustomerInAnyGroupFromListCondition;
