
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class CustomerProvinceCondition extends BaseCondition {
    value: string;

    constructor({
        type,
        value,
    }: BaseConditionInput & { value: string }) {
        super({ type });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        const customer = shop.getCustomer();

        return customer.getProvince() === this.value;
    }
}

export default CustomerProvinceCondition;
