
/* eslint no-console: "off" */
/* develblock:start */
import LogPlease from 'logplease';
import { padZero } from './string';
import { ENV, SETTINGS, config } from '../config';
import { BASE_URLS } from '../constants';
import container from '../components/Container';

const verbose_logs = SETTINGS && config(SETTINGS.verbose_logs);
const dev_build = ENV && ENV.DEV_BUILD;
const unit_testing = ENV && ENV.UNIT_TESTING;
const browser = ENV && ENV.BROWSER;

if (unit_testing) {
    LogPlease.setLogLevel(LogPlease.LogLevels.ERROR); // Logs errors only
} else if (process.env.LOG === 'debug' || verbose_logs || dev_build) {
    LogPlease.setLogLevel(LogPlease.LogLevels.DEBUG); // Logs everything
} else {
    LogPlease.setLogLevel(LogPlease.LogLevels.ERROR); // Logs errors only
}

/**
 * General App Logging
 * -----------------------------
 * ENV: Browser + Node
 */

const timers: { [key: string]: Date; } = {};
const completedTimers: { [key: string]: number; } = {};

// https://github.com/haadcode/logplease#log-levels
const logger = LogPlease.create('pr', { showTimestamp: !browser });

/**
 * Log
 */
class Log {
    /**
     * Initialize logging and start a timer
     */
    static init() {
        if (ENV.BROWSER) {
            Log.info(`Initializing Bold Price Rules.`, { ...BASE_URLS, dev: ENV.DEV_BUILD });
            container.debug.config = config();
        }
    }

    /**
     * Logs a noticeable message to the console if logging is on.
     * Also doesn't stringify objects.
     *
     * The only method in this file that doesn't go through
     * logplease and therefore shouldn't be used for prod.
     */
    static notice(message: any, ...extra: any[]) {
        console.log(`%cNOTICE -- ${message}`, 'background:#bedced;color:#222;', ...extra);
    }

    /**
     * Logs a noticeable message to the console if logging is on
     *
     * @param {any} message
     * @param {object} extra
     */
    static debug(message: any, ...extra: any[]) {
        logger.debug(message, ...extra);
    }

    /**
     * Logs a message to the console if logging is on
     *
     * @param {any} message
     * @param {object} extra
     */
    static info(message: any, ...extra: any[]) {
        logger.info(message, ...extra);
    }

    /**
     * Logs a warning to the console if logging is on
     *
     * @param {any} message
     * @param {object} extra
     */
    static warn(message: any, ...extra: any[]) {
        logger.warn(message, ...extra);
    }

    /**
     * Handle an error based on log settings
     */
    static error(error: any, ...extra: any[]) {
        if (error.response) {
            logger.error(`${error.response.status} ${error.message}`);
        } else {
            logger.error(error.message);
        }
    }

    /**
     * Date format for logs
     *
     * @returns {string}
     * @param {Date} date
     */
    static date(date = new Date()) {
        return [
            date.getFullYear(),
            padZero(date.getMonth()),
            padZero(date.getDay()),
        ].join('-');
    }

    /**
     * Time format for logs
     *
     * @returns {string}
     * @param {Date} date
     */
    static time(date = new Date()) {
        return [
            padZero(date.getHours()),
            padZero(date.getMinutes()),
            padZero(date.getSeconds()),
            padZero(date.getMilliseconds(), 3),
        ].join(':');
    }

    static startTimer(timerKey: string) {
        timers[timerKey] = new Date();
    }

    static stopTimer(timerKey: string): number|null {
        if (!timers[timerKey]) {
            Log.warn(`The ${timerKey} timer does not exist!`);
            return null;
        }
        const time = (new Date()).getTime() - timers[timerKey].getTime();
        completedTimers[timerKey] = time;
        return time;
    }

    static getTimer(timerKey: string): number {
        if (timers[timerKey]) { // calculate from running timer if there is one
            return (new Date()).getTime() - timers[timerKey].getTime();
        }

        return completedTimers[timerKey];
    }
}

export default Log;
/* develblock:end */
