
import Money from '../money/Money';
import PriceElementSet from '../dom/PriceElementSet';
import { config, ENV, SETTINGS } from '../../config';
import CartElements from '../../components/CartElements';
import CartItem, { FactoryCartItemInput } from './CartItem';
import Variant from './Variant';
import Fee from '../money/Fee';
import { Attributes } from './ShopifyCart';
import { GenericObject } from '../../helpers/object';
import CartLevelDiscount from '../money/CartLevelDiscount';
import ShopifyDiscountCodeStorage from '../../components/ShopifyDiscountCodeStorage';

declare global {
    interface Window {
        Shopify: any;
    }
}

export interface CartInput {
    token?: string;
    items: CartItem[];
    note?: string;
    attributes?: Attributes;
    total_price?: number;
    total_discount?: number;
    discount_code?: string | null
}

export type CartInputRaw = Omit<CartInput, 'items'> & {
    items: FactoryCartItemInput[];
    sub_total?: number;
    products?: GenericObject[];
};

class Cart {
    attributes?: Attributes;
    discount_code: string | null;
    feesByLineId: { [line_id: string]: Fee };
    items: CartItem[];
    note?: string;
    resetActions: Array<() => void>;
    ruleState: WeakMap<any, any>;
    subTotalPriceElementSet: PriceElementSet;
    sub_total: Money;
    token?: string;
    total_discount?: number;
    cartLevelDiscounts: Map<string, CartLevelDiscount>;

    constructor({
        items,
        total_price,
        note,
        attributes,
        token,
        total_discount,
        discount_code = '',
    }: CartInput) {
        this.items = items;
        this.note = note;
        this.attributes = attributes;
        this.token = token;
        this.total_discount = total_discount;
        this.discount_code = discount_code;

        this.ruleState = new WeakMap();

        /** Derived/other cart data that shouldn't be passed in when constructing: */
        this.sub_total = new Money(this.calculateSubTotal(), total_price);
        this.subTotalPriceElementSet = new PriceElementSet('cart_subtotal', this, [], this.sub_total, config(SETTINGS.template_sub_total));

        this.feesByLineId = {};
        this.cartLevelDiscounts = new Map();

        if (ENV.BROWSER) {
            this.bindPriceEvents();
        }

        this.resetActions = [];
    }

    getRuleState(ref: any): Map<any, any> {
        if (this.ruleState.has(ref)) {
            return this.ruleState.get(ref);
        }

        const state = new Map();
        this.ruleState.set(ref, state);
        this.resetActions.push(() => state.clear());
        return state;
    }

    destroy() {
        // In PlatformScanner cart elements are acted on again after the
        // LOADED_CART event, so we have to "purge" them here so they
        // don't get duplicate MutationObservers
        CartElements.purge();
        // We have to do this during the destroy while we have these references.
        this.resetRuleState();
    }

    reset() {
        this.resetItems();
        this.resetRuleState();
    }

    resetRuleState() {
        this.resetActions.forEach((action) => action());
    }

    resetItems() {
        this.items.forEach((i) => i.reset());
    }

    bindPriceEvents() {
        /** Update the subtotal when a line price changes. */
        this.items.forEach((item) => {
            const linePrice = item.getLinePrice();
            linePrice.ee.on('change', () => this.updateSubTotal());
        });
    }

    toJSON() {
        return {
            items: this.items,
            sub_total: this.sub_total.amount(),
            note: this.note,
            attributes: this.attributes,
            fees: Object.values(this.feesByLineId),
            cart_discount: Object.values(this.cartLevelDiscounts),
            discount_code: this.discount_code,
        };
    }

    getDiscountCode() {
        return this.discount_code;
    }

    getItems() {
        return this.items;
    }

    getItemByVariantId(id: string) {
        return this.items.find((item) => item.getVariantId() === id);
    }

    getItemByProductHandle(productHandle: string) {
        return this.items.find((item) => item.handle === productHandle);
    }

    getItemCount() {
        return this.items.length;
    }

    addFee(key: string, fee: Fee) {
        this.feesByLineId[key] = fee;
        this.sub_total.add(fee.amount);
        this.sub_total.setOriginal(this.sub_total.original() + fee.amount);
    }

    /*
     * Get cart subtotal
     */
    getSubTotal() {
        return this.sub_total;
    }

    addCartLevelDiscount(cart_discount: CartLevelDiscount, key: string) {
        this.cartLevelDiscounts.set(key, cart_discount);
    }

    removeCartLevelDiscount(key: string) {
        this.cartLevelDiscounts.delete(key);
    }

    /*
     * Calculate the cart subtotal from its lines.
     */
    calculateSubTotal(): number {
        return this.calculateSubTotalWithoutFees() + this.calculateFeeTotal();
    }

    /*
     * Calculate the cart subtotal from its lines.
     */
    calculateSubTotalWithoutFees(considerCartLevelDiscounts = false): number {
        const cartLevelDiscountAmount = ENV.BROWSER &&
            window.Shopify &&
            this.cartLevelDiscounts &&
            this.cartLevelDiscounts.size > 0 &&
            ShopifyDiscountCodeStorage.getShopifyDiscountCodeSettings(window.Shopify.shop) === '1'
            ? this.calculateShopifyDiscountCodeCartLevelDiscounts()
            : 0;

        return this.items.reduce((sum: number, cartItem) => {
            return sum + cartItem.line_price.amount();
        }, 0) - cartLevelDiscountAmount;
    }

    /*
     * Calculate the cart level shopify discount codes total
     */
    calculateShopifyDiscountCodeCartLevelDiscounts(): number {
        let cartLevelDiscountAmount = 0;
        this.cartLevelDiscounts.forEach((cartLevelDiscount) => {
            cartLevelDiscount.ruleDiscountType === 1
                ? cartLevelDiscountAmount += Math.abs(cartLevelDiscount.discountValue)
                : cartLevelDiscountAmount += cartLevelDiscount.discountValue;
        });
        return cartLevelDiscountAmount;
    }

    /*
     * Calculate the total of all cart fees.
     */
    calculateFeeTotal(): number {
        if (!this.feesByLineId) {
            return 0;
        }

        return Object.values(this.feesByLineId).reduce((sum, fee) => sum + fee.amount, 0);
    }

    /*
     * Calculate the original cart subtotal from its lines.
     */
    calculateOriginalSubTotal(): number {
        return this.items.reduce((carry: number, cartItem) => {
            carry += cartItem.original_line_price.amount();
            return carry;
        }, 0);
    }

    /*
     * Calculate the total discount.
     */
    calculateTotalDiscount(): number {
        const { sub_total, original_sub_total } = this.items.reduce((carry: any, cartItem) => {
            carry.sub_total += cartItem.line_price.amount();
            carry.original_sub_total += cartItem.original_line_price.amount();
            return carry;
        }, { sub_total: 0, original_sub_total: 0 });

        return original_sub_total - sub_total;
    }

    updateSubTotal() {
        const subTotal = this.calculateSubTotal();
        this.sub_total.setAmount(subTotal);
    }

    getVariants(): Variant[] {
        return this.items.map((cartItem) => cartItem.getVariant());
    }

    purge() {
        this.items.forEach((item) => item.purge());
        this.items = [];
        this.subTotalPriceElementSet.purge();
    }

    addSubtotalPriceElement(element: HTMLElement) {
        this.subTotalPriceElementSet.push(element);
    }

    updateElements() {
        this.items.forEach((ele) => ele.update());
        this.subTotalPriceElementSet.updateElements();
    }
}

export default Cart;
