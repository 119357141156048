
import Log from '../../../helpers/Log';
import { addCSS } from './helpers';
import DraftOrderCheckoutLoader from './DraftOrderCheckoutLoader';

class LoginHandler {
    static run(page: string|null, checkoutLoader: DraftOrderCheckoutLoader) {
        if (page === 'customers/login') {
            LoginHandler.handleLoginRedirect(checkoutLoader);
        } else if (page === 'cart' && LoginHandler.isSkippingCart()) {
            LoginHandler.handleCartSkip(checkoutLoader);
        }
    }

    static isSkippingCart() {
        return window.location.href.search(/\/cart\?nav_to_checkout/) !== -1;
    }

    /**
     * Adjust the post-login action to navigate back to `/cart?navigate_to_checkout`
     * If you are on login page and your url contains "checkout_url"
     * You can redirected from checkout page's login
     * PRE will send to cart page with "?nav_to_checkout" to the url for skipping cart purpose
     */
    static handleLoginRedirect(checkoutLoader: DraftOrderCheckoutLoader) {
        /* develblock:start */
        Log.debug('LoginHandler - Detected on login page. Binding to form.');
        /* develblock:end */
        const form = document.querySelector('form[action*="/account/login"]');
        if (form) {
            /**
             * Running this change on submit so we can check at the last moment
             * before submit if the checkoutloader is enabled.
             */
            form.addEventListener('submit', (evt) => {
                const checkoutUrlInput = document.querySelector('[name=checkout_url]') as HTMLInputElement;
                if (checkoutLoader.isEnabled() && checkoutUrlInput) {
                    checkoutUrlInput.value = '/cart?nav_to_checkout';
                }
            });
        }
    }

    /**
     * Skip the cart and go to checkout.
     * It hides the content of cart page and wait for checkout button
     * to be avaibale to be clicked.
     */
    static handleCartSkip(checkoutLoader: DraftOrderCheckoutLoader) {
        /* develblock:start */
        Log.debug('LoginHandler - Detected on /cart?nav_to_checkout. Skipping cart...');
        /* develblock:end */

        /**
         * Any checks that need to be done by PRE to ensure we
         * should be taking over the checkout can be force
         * overridden here because this is just a bounce
         * and we checked from the last page. In CSP this was
         * `window.BOLD.csp.is_installed=true`.
         */
        addCSS('html,body { display:none; }');
        checkoutLoader.waitCheckoutAvailableForClick();
    }
}

export default LoginHandler;
