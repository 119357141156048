
import { findFormFromChild, getFormField, queryHTMLElements } from './dom';
import { GenericObject } from './object';
import { normalizeIdentifier } from './identifier';
import BOLD, { boldWindow } from './windowBold';

export function isShopifyMCActive(): boolean {
    const currency = getShopifyMCInfo();
    return currency.shop &&
        currency.active &&
        currency.rate &&
        currency.shop !== currency.active;
}

export function getShopifyMCRate(): string {
    return boldWindow.Shopify?.currency?.rate ?? '1.0';
}

export function getShopifyMCInfo(): GenericObject {
    if (typeof boldWindow.Shopify === 'object') {
        return {
            ...(boldWindow.Shopify.currency ?? {}),
            shop: BOLD.common?.Shopify?.shop?.currency,
        };
    } else {
        return {};
    }
}

export function applyShopifyMC(price: number): number {
    if (!isShopifyMCActive()) {
        return price;
    }

    return Math.floor(price * parseFloat(getShopifyMCRate()));
}

export function reverseShopifyMC(price: number): number {
    if (!isShopifyMCActive()) {
        return price;
    }

    return Math.floor(price / parseFloat(getShopifyMCRate()));
}

function docElsByName(name: string) {
    return Array.from(document.getElementsByName(name));
}

export function getAddToCartForm(): HTMLFormElement|boolean {
    const idEls = [...docElsByName('id'), ...docElsByName('id[]')];
    if (!idEls.length) {
        return false;
    }
    const form = findFormFromChild(idEls[0]);

    if (form instanceof HTMLFormElement) {
        return form;
    }

    return false;
}
