
import { setData, addClass, hasClass } from '../../helpers/dom';

const cartHookClasses = {
    price: 'bold_cart_item_price',
    linePrice: 'bold_cart_item_total',
    subTotal: 'bold_cart_total',
};

type MoneyElementById = { [line_item_key: string]: HTMLElement[] };
type MoneyElementByIndex = HTMLElement[][];

const isHtmlElement = (el: any) => el instanceof HTMLElement;

class LegacyCartHooks {
    /**
     * Scans for legacy cart hook elements and marks
     * them according to the new format.
     *
     * Hooks are html elements with specific css class names. Those elements are
     * usually followed by a text node where the price will be shown.
     *
     * This provides backwards compatibility with
     * the current robo install.
     */
    static scanForLegacyCartHookElements() {
        if (!this.scanByKey()) {
            this.scanByIndex();
        }

        const subTotalElements = this.getSiblingMoneyElements(cartHookClasses.subTotal);

        subTotalElements.filter(this.isValid).forEach(ele => {
            addClass(ele as HTMLElement, 'money');
            setData(ele as HTMLElement, 'cart-total', '');
        });
    }

    static manipulateKeyedMoneyElements(cssClass: string) {
        const keyedElements = this.getHookElementsByKey(cssClass);
        let changes = 0;
        Object.keys(keyedElements).forEach((key) => {
            keyedElements[key].filter(this.isValid).forEach((ele) => {
                addClass(ele, 'money');
                setData(ele, 'item-key', key);
                if (cssClass === cartHookClasses.linePrice) {
                    setData(ele, 'line-total', '');
                }
                changes++;
            });
        });
        return changes;
    }

    static scanByKey() {
        const changed = this.manipulateKeyedMoneyElements(cartHookClasses.price) + this.manipulateKeyedMoneyElements(cartHookClasses.linePrice);
        return Boolean(changed);
    }

    static scanByIndex() {
        const priceElementsByIndex = this.getHookElementsByIndex(cartHookClasses.price);

        const linePriceElementsByIndex = this.getHookElementsByIndex(cartHookClasses.linePrice);

        priceElementsByIndex.forEach((priceElements: HTMLElement[], index: number) => {
            priceElements.filter(this.isValid).forEach((ele) => {
                addClass(ele, 'money');
                setData(ele, 'line-index', index);
            });
        });

        linePriceElementsByIndex.forEach((linePriceElements: HTMLElement[], index: number) => {
            linePriceElements.filter(this.isValid).forEach((ele: HTMLElement) => {
                addClass(ele, 'money');
                setData(ele, 'line-index', index);
                setData(ele, 'line-total', '');
            });
        });
    }

    static isValid(ele: any) {
        if (ele instanceof HTMLElement) {
            return !hasClass(ele, 'money-details');
        }
        return false;
    }

    static getHookElementsByKey(className: string, classCount = 1, moneyElements: MoneyElementById = {}): MoneyElementById {
        const fullClassName = classCount > 1 ? `${className}_${classCount}` : `${className}`;
        const keyElements = Array.from(document.getElementsByClassName(fullClassName));
        if (!keyElements.length && classCount > 2) {
            return moneyElements;
        }
        keyElements.forEach((keyElement) => {
            if (!(keyElement instanceof HTMLElement)) {
                return;
            }
            const { itemKey } = keyElement.dataset;
            if (itemKey === null || itemKey === undefined || itemKey === '') {
                return;
            }
            moneyElements[itemKey] = moneyElements[itemKey] || [];
            const sib = this.getSiblingMoneyElement(keyElement);
            if (sib) {
                moneyElements[itemKey].push(sib);
            }
        });
        return this.getHookElementsByKey(className, classCount + 1, moneyElements);
    }

    static getHookElementsByIndex(className: string, classCount = 1, moneyElementsByIndex: MoneyElementByIndex = []): MoneyElementByIndex {
        const fullClassName = classCount > 1 ? `${className}_${classCount}` : `${className}`;
        const moneyElements = this.getSiblingMoneyElements(fullClassName).filter(isHtmlElement) as HTMLElement[];
        if (!moneyElements.length) {
            return moneyElementsByIndex;
        }
        for (let i = 0; i < moneyElements.length; i++) {
            if (typeof moneyElementsByIndex[i] === 'undefined') {
                moneyElementsByIndex[i] = [];
            }
            moneyElementsByIndex[i].push(moneyElements[i]);
        }
        return this.getHookElementsByIndex(className, classCount + 1, moneyElementsByIndex);
    }

    static getSiblingMoneyElement(element: HTMLElement|Element): HTMLElement | null {
        let moneyElement = element.nextSibling;
        // Element can be a text node, have to replace it with a real node
        if (moneyElement && moneyElement.nodeName === '#text') {
            const textContent = moneyElement.textContent || '';
            if (textContent.trim() !== '') {
                const replacementNode = document.createElement('span');
                replacementNode.innerHTML = textContent.trim();
                const parent = moneyElement.parentNode;
                if (parent instanceof Element) {
                    parent.insertBefore(replacementNode, moneyElement);
                    parent.removeChild(moneyElement);
                }
                moneyElement = replacementNode;
            } else {
                moneyElement = moneyElement.nextSibling;
            }
        }
        return moneyElement as HTMLElement | null;
    }

    static getSiblingMoneyElements(className: string): Array<HTMLElement | null> {
        return Array.from(document.getElementsByClassName(className))
            .map(this.getSiblingMoneyElement);
    }
}

export default LegacyCartHooks;
