import { ENV } from '../config';
import { GenericObject } from '../helpers/object';
import { SubscriptionParamsStorage } from '../models/platform/Shop';

/**
 * Rule Storage
 *
 * This class fetches and sets OrderData using local storage
 */
class OrderDataStorage {
    static storeOrderData(orderData: GenericObject) {
        window.localStorage.setItem('PRE.orderData', JSON.stringify(orderData));
    }

    static fetchOrderData(): GenericObject|null {
        if (ENV.NODE) {
            return null;
        }

        const data = window.localStorage.getItem('PRE.orderData');
        if (data === null) {
            return {};
        } else {
            return JSON.parse(data);
        }
    }

    static clearOrderData() {
        window.localStorage.setItem('PRE.orderData', JSON.stringify([]));
    }

    static storeOrderConditions(orderConditionsArray: GenericObject) {
        window.localStorage.setItem('PRE.orderConditions', JSON.stringify(orderConditionsArray));
    }

    static fetchOrderConditions(): Map<any, any>|null {
        if (ENV.NODE) {
            return null;
        }

        const conditions = window.localStorage.getItem('PRE.orderConditions');
        if (conditions === null) {
            return new Map();
        } else {
            const orderConditionsArray = JSON.parse(conditions);
            return new Map(Object.entries(orderConditionsArray));
        }
    }

    static clearOrderConditions() {
        window.localStorage.setItem('PRE.orderConditions', JSON.stringify([]));
    }

    static clearCartParams() {
        window.localStorage.setItem('PRE.cartParams', JSON.stringify([]));
    }

    static clearSubscriptionParams() {
        if (ENV.BROWSER) {
            window.localStorage.removeItem('PRE.subscriptionParams');
        }
    }

    static storeCartParams(cartParamsMap: any) {
        window.localStorage.setItem('PRE.cartParams', JSON.stringify(cartParamsMap, OrderDataStorage.cart_params_replacer));
    }

    static storeSubscriptionParams(subsParamsMap: SubscriptionParamsStorage[]) {
        window.localStorage.setItem('PRE.subscriptionParams', JSON.stringify(subsParamsMap));
    }

    // TODO: call the generic static params_reviver
    static cart_params_replacer(key : any, value : any) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()),
            };
        } else {
            return value;
        }
    }

    // TODO: call the generic static params_reviver
    static cart_params_reviver(key : any, value : any) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }

    static fetchCartParams(): Map<any, any>|null {
        if (ENV.NODE) {
            return null;
        }
        const params = window.localStorage.getItem('PRE.cartParams');
        if (params === null) {
            return new Map();
        } else {
            return JSON.parse(params, OrderDataStorage.cart_params_reviver);
        }
    }

    static getSubscriptionParams() {
        if (ENV.NODE) {
            return undefined;
        }
        const params = window.localStorage.getItem('PRE.subscriptionParams');
        if (params === null) {
            return undefined;
        } else {
            return JSON.parse(params);
        }
    }
}

export default OrderDataStorage;
