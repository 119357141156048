
export function hasClass(element: HTMLElement, className: string): boolean {
    if (element.classList) {
        return element.classList.contains(className);
    } else {
        return !!element.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
    }
}

export function addClass(element: HTMLElement, className: string) {
    if (element.classList) {
        element.classList.add(className);
    } else if (!hasClass(element, className)) {
        element.className += ` ${className}`;
    }
}

export function removeClass(element: HTMLElement, className: string) {
    if (element.classList) {
        element.classList.remove(className);
    } else if (hasClass(element, className)) {
        const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
        element.className = element.className.replace(reg, ' ');
    }
}

export function getDataRaw(element: HTMLElement, key: string): string | null {
    const ret = element.getAttribute(`data-${key}`);
    return ret ? ret.trim() : ret;
}

export function getDataNumber(element: HTMLElement, key: string): number | null {
    const data = getDataRaw(element, key);

    if (data !== null) {
        const dataInt = parseInt(data);
        if (!isNaN(dataInt)) {
            return dataInt;
        }
    }

    return null;
}

export function setData(element: HTMLElement, key: string, value: any) {
    element.setAttribute(`data-${key}`, value);
}

export function show(ele: HTMLElement) {
    ele.style.visibility = 'visible';
}

export function hide(ele: HTMLElement) {
    ele.style.visibility = 'hidden';
}

export function insertBefore(newNode: HTMLElement, referenceNode: HTMLElement) {
    referenceNode.parentNode && referenceNode.parentNode.insertBefore(newNode, referenceNode);
}

export function insertAfter(newNode: HTMLElement, referenceNode: HTMLElement) {
    referenceNode.parentNode && referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export function createElement(tag: string, attributes: any): HTMLElement {
    const element = document.createElement(tag);

    if (attributes === null) {
        return element;
    }

    for (const attr in attributes) {
        const data = attributes[attr];
        switch (attr) {
            case 'innerHTML':
                element.innerHTML = data;
                break;
            case 'text':
                element.appendChild(document.createTextNode(data));
                break;
            case 'click':
            case 'onClick':
                element.addEventListener('click', data);
                break;
            case 'appendChild':
                element.appendChild(data);
                break;
            case 'appendChildren':
                for (let i = 0; i < data.length; i++) {
                    element.appendChild(data[i]);
                }
                break;
            case 'insertBefore':
                insertBefore(element, data);
                break;
            case 'insertAfter':
                insertAfter(element, data);
                break;
            case 'data':
                Object.keys(data).forEach(k => setData(element, k, data[k]));
                break;
            default:
                // @ts-expect-error
                element[attr] = data;
        }
    }

    return element;
}

export function matches(element: HTMLElement, selector: string): boolean {
    if (!Element.prototype.matches) {
        // polyfill from https://developer.mozilla.org/en/docs/Web/API/Element/matches
        Element.prototype.matches =
            // @ts-expect-error
            Element.prototype.matchesSelector ||
            // @ts-expect-error
            Element.prototype.mozMatchesSelector ||
            // @ts-expect-error
            Element.prototype.msMatchesSelector ||
            // @ts-expect-error
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                // @ts-expect-error
                const matches = (this.document || this.ownerDocument).querySelectorAll(s);

                let i = matches.length;
                // @ts-expect-error
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }
    return element.matches(selector);
}

export function getFormField(form: HTMLFormElement, field: string): HTMLInputElement|HTMLSelectElement|HTMLOptionElement|HTMLTextAreaElement|HTMLButtonElement|null {
    const fieldEl = form.querySelector(`[name="${field}"]`);
    if (fieldEl instanceof HTMLInputElement ||
        fieldEl instanceof HTMLSelectElement ||
        fieldEl instanceof HTMLOptionElement ||
        fieldEl instanceof HTMLTextAreaElement ||
        fieldEl instanceof HTMLButtonElement
    ) {
        return fieldEl;
    }
    return null;
}

export function queryHTMLElements(searchNode: Element|Document, query: string): HTMLElement[] {
    const nodeList = searchNode.querySelectorAll(query);
    const htmlElements: HTMLElement[] = [];
    let candidate = null;
    for (let i = 0; i < nodeList.length; i++) {
        candidate = nodeList[i];
        if (candidate instanceof HTMLElement) {
            htmlElements.push(candidate);
        }
    }
    return htmlElements;
}

export function findFormFromChild(ele: HTMLElement | Node | null): boolean|HTMLFormElement|Node {
    if (ele === null || ele.parentNode === null || ele.parentNode.nodeName === null) {
        return false;
    }

    if (ele.parentNode.nodeName === 'FORM') {
        return ele.parentNode;
    }
    return findFormFromChild(ele.parentNode);
}

export function onDomReady(): Promise<null> {
    return new Promise((resolve) => {
        if (document.readyState === 'complete') {
            resolve(null);
        } else {
            document.addEventListener('DOMContentLoaded', () => resolve(null));
        }
    });
}
