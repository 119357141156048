
import container from '../components/Container';
import Shop from '../models/platform/Shop';
import { getDataRaw } from '../helpers/dom';
import { EVENTS, channel } from '../events';
import Log from '../helpers/Log';
import ElementCache from '../components/ElementCache';
import { SETTINGS, config, ENV } from '../config';
import { make as makeCustomElement } from '../browser/modules/custom_elements/factory';
import CartElements from '../components/CartElements';
import { GenericObject } from '../helpers/object';
import Product from '../models/platform/Product';
import CartItem from '../models/platform/CartItem';

const productElementCache = new ElementCache();
const invalidElementCache = new ElementCache();

function elementReducer(event: string, data: GenericObject = {}) {
    switch (event) {
        case EVENTS.SHOP_STATE_UPDATED:
            CartElements.updateElements();
            break;
        case EVENTS.NEW_PRICE_ELEMENTS:
        case EVENTS.NEW_TEMPLATE_ELEMENTS:
            data.elements.forEach(identifyPriceElements);
            break;
        case EVENTS.NEW_ELEMENT_PRODUCT:
            // @ts-expect-error
            addNewProductElement(data);
            break;
        case EVENTS.NEW_ELEMENT_LINE_ITEM_TOTAL:
            // @ts-expect-error
            CartElements.addLineItemTotalPriceElement(data);
            break;
        case EVENTS.NEW_ELEMENT_LINE_ITEM_PRICE:
            // @ts-expect-error
            CartElements.addLineItemPriceElement(data);
            break;
        case EVENTS.NEW_ELEMENT_SUBTOTAL:
            // @ts-expect-error
            CartElements.addSubTotalPriceElement(data);
            break;
        case EVENTS.CART_UPDATED:
            // This event is handled in the cartReducer before this fires.
            /* develblock:start */
            Log.debug('Clearing cart elements...');
            /* develblock:end */
            invalidElementCache.clear();
            break;
    }
}

function getProductFromShop(element: HTMLElement): Product | undefined {
    const shop = container.get(Shop);
    const productId = getDataRaw(element, 'product-id');
    const variantId = getDataRaw(element, 'variant-id');
    const subscriptionGroupId = getDataRaw(element, 'bold-subscription-group-id');
    const subscriptionIntervalId = getDataRaw(element, 'bold-subscription-interval-id');
    const frequencyElements = document.getElementsByName('selling_plan');
    const frequencyElement = frequencyElements && frequencyElements.length > 0 ? frequencyElements[0] as HTMLSelectElement : null;

    let product: Product | undefined;
    if (productId) {
        product = shop.getProductById(productId); // data-product-id with product id
    } else if (variantId) {
        product = shop.getProductByVariantId(variantId); // data-variant-id with variant id
    }

    if (config('staples_subs_ff') && subscriptionGroupId) {
        const previousIntervalId = product?.getSubscriptionIntervalId();
        const frequencyUpdated = frequencyElement && frequencyElement[frequencyElement.selectedIndex].getAttribute('data-interval-id') !== subscriptionIntervalId;
        const shouldUpdateProduct = !shop.isSubscriptionSelected() && previousIntervalId;
        if (!frequencyElement || !shop.isSubscriptionSelected()) {
            product?.setSubscriptionGroupId();
            product?.setSubscriptionIntervalId();
        } else {
            const groupId = subscriptionGroupId === 'NaN' ? undefined : parseInt(subscriptionGroupId);
            const intervalId = subscriptionIntervalId ? parseInt(subscriptionIntervalId) : undefined;
            product?.setSubscriptionGroupId(groupId);
            product?.setSubscriptionIntervalId(intervalId);
        }
        // eslint-disable-next-line no-mixed-operators
        if (shouldUpdateProduct || shop.isSubscriptionSelected() && previousIntervalId !== product?.getSubscriptionIntervalId()) {
            channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
        }
    }

    if (!product && productId) {
        /**
         * Bizarro world where product ids and variant ids are
         * all mixed up but hey lets let it all work anyway.
         * Essentially this just allows the lookup to still work
         * if variant id is provided in `data-product-id`.
         */
        product = shop.getProductByVariantId(productId); // data-product-id with variant id
    }

    return product;
}

function identifyPriceElements(element: HTMLElement) {
    if (CartElements.identify(element)) {
        return;
    }

    if (invalidElementCache.contains(element)) {
        return;
    }

    const productId = getDataRaw(element, 'product-id');
    const variantId = getDataRaw(element, 'variant-id');
    const productHandle = getDataRaw(element, 'product-handle');
    const customType = getDataRaw(element, 'custom');

    if (customType) {
        addCustomElement({ type: customType, element: element });
    } else if (variantId) {
        if (config(SETTINGS.legacy_variant_elements)) {
            identifyProductElement(element);
        } else {
            addCustomElement({ type: 'variant', element: element });
        }
    } else if (productId) {
        identifyProductElement(element);
    } else if (productHandle) {
        fetchProductJson(element);
    } else {
        invalidElementCache.save(element);
        /* develblock:start */
        Log.warn('Invalid money element ignored.', element.outerHTML);
        /* develblock:end */
    }
}

function identifyProductElement(element: HTMLElement) {
    const product = getProductFromShop(element);

    if (product) {
        channel.dispatch(EVENTS.NEW_ELEMENT_PRODUCT, { element, product_id: product.getId() });
    } else {
        invalidElementCache.save(element);
        /* develblock:start */
        Log.warn('Money element for unknown product ignored.', element);
        /* develblock:end */
    }
}

function addCustomElement({ type, element }: { type: string, element: HTMLElement }) {
    if (productElementCache.contains(element)) {
        return;
    }

    const custEl = makeCustomElement(type, element);

    if (custEl) {
        const shop = container.get(Shop);
        shop.custom_elements.push(custEl);
    }

    productElementCache.save(element);
}

function addNewProductElement({ element, product_id }: { element: HTMLElement, product_id: string}) {
    if (productElementCache.contains(element)) {
        return;
    }

    const shop = container.get(Shop);
    const product = shop.getProductById(product_id);

    if (product) {
        product.priceElementSet.push(element);
    }

    productElementCache.save(element);
}

function fetchProductJson(element: HTMLElement) {
    const productHandle = getDataRaw(element, 'product-handle') as string;
    const { product } = getProductOrItemByProductHandle(productHandle);

    if (product instanceof Product) {
        element.setAttribute('data-product-id', product.id.toString());
        addNewProductElement({ element, product_id: product.id });
    } else {
        const shop = container.get(Shop);

        fetch(`https://${shop.shop_domain}/products/${productHandle}.js`)
            .then(response => response.json())
            .then(product => {
                element.setAttribute('data-product-id', product.id);
                channel.dispatch(EVENTS.NEW_PRODUCTS_RAW, { products: [product] });
            });
    }
}

function getProductOrItemByProductHandle(productHandle: string): { product?: Product, item?: CartItem } {
    const shop = container.get(Shop);
    const product = shop.getProductByProductHandle(productHandle);
    const item = shop.cart.getItemByProductHandle(productHandle);

    return { product, item };
}

export default elementReducer;
