
import Platform from '../models/platform/Platform';
import IPlatformBinder from '../models/platform/IPlatformBinder';
import IPlatformFactory from '../models/platform/IPlatformFactory';
import { GenericObject } from '../helpers/object';
import container from './Container';
import Shop from '../models/platform/Shop';

export function createPlatform(
    platformBinder: IPlatformBinder,
    platformFactory: IPlatformFactory,
    platformData: GenericObject,
): Platform {
    const shop = platformFactory.createShop(platformData);
    const platform = new Platform({ Factory: platformFactory, shop });

    shop.setPlatform(platform);
    container.set(Platform, platform);
    container.set(Shop, shop);
    container.set('platform_data', platformData);

    platformBinder.bind();

    return platform;
}
