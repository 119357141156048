
import { ENV, config, setConfig as setPriceRulesConfig } from '../config';
import BOLD, { WBoldPRE } from '../helpers/windowBold';
import RuleStorage from '../components/RuleStorage';
import CurrencyStorage from '../components/CurrencyStorage';
import GetCurrencySymbolClient from '../api/GetCurrencySymbolClient';
import RuleProcessor from '../components/RuleProcessor';
import { EVENTS, channel } from '../events';
import EventBridge from './EventBridge';
import EventEmitter from 'eventemitter3';
import container from '../components/Container';
import Shop from '../models/platform/Shop';
import Customer from '../models/platform/Customer';
import Platform from '../models/platform/Platform';
import { GenericObject, serialize } from '../helpers/object';
import MoneyDisplay from '../models/money/MoneyDisplay';
import * as Loaders from '../browser/loaders';
import { SerialCart, serializeCart } from './models/SerialCart';
import SerialCartItem, { serializeCartItem } from './models/SerialCartItem';
import { serializeProduct, SerialProduct } from './models/SerialProduct';
import { normalizeIdentifier } from '../helpers/identifier';
import AddProductJsonBatcher from '../components/AddProductJsonBatcher';
import Product from '../models/platform/Product';
import Variant from '../models/platform/Variant';
import { FactoryCartItemInput } from '../models/platform/CartItem';
import { PREShopifyCart, ShopifyCart, ShopifyCartItem } from '../models/platform/ShopifyCart';

const minimalCartTemplate: PREShopifyCart = {
    token: '',
    attributes: {},
    original_total_price: 0,
    total_price: 0,
    total_discount: 0,
    total_weight: 0,
    item_count: 1,
    requires_shipping: true,
    currency: '',
    items: [],
    items_subtotal_price: 0,
    cart_level_discount_applications: [],
    sub_total: 0,
    _pre_is_fixed: false,
};

interface RawProduct {
   id: number;
   variants: Variant[];
}

interface RawProductDetail {
    variantId: number|string;
    productId: number|string;
    qty: number;
}

const PUBLIC_EVENTS = {
    // listening
    cart_updated: 'cart_updated',
    customer_updated: 'customer_updated',
    cart_updated_qty: 'cart_updated_qty',
    variant_changed: 'variant_changed',
    // emitting
    update: 'update',
};

type MiddlewareFunction = (cart: PREShopifyCart) => PREShopifyCart;

/**
 * Api/Window
 * The public browser API in window.BOLD.pre
 */
class WindowApi implements WBoldPRE {
    config: GenericObject;
    events: EventEmitter;
    postCartMiddleware: Array<MiddlewareFunction>;
    v: string;
    version: string;
    constructor() {
        this.version = ENV.VERSION || '';
        this.v = ENV.VERSION_MEM || '';
        this.config = config();
        this.events = new EventEmitter();

        const eventBridge = new EventBridge(this.events);
        eventBridge.listen(PUBLIC_EVENTS.cart_updated, EVENTS.CART_UPDATED);
        eventBridge.listen(PUBLIC_EVENTS.customer_updated, EVENTS.CUSTOMER_UPDATED);
        eventBridge.listen(PUBLIC_EVENTS.cart_updated_qty, EVENTS.CART_UPDATED_QTY);
        eventBridge.listen(PUBLIC_EVENTS.variant_changed, EVENTS.VARIANT_CHANGED);
        eventBridge.publish(EVENTS.RP_QUEUE_COMPLETE, PUBLIC_EVENTS.update);
        eventBridge.publish(EVENTS.MONEY_ELEMENT_RESTORED, PUBLIC_EVENTS.update);

        /** Override the CartDoctor fix fn for existing install backwards compatibility. */
        BOLD.common = BOLD.common || {};
        BOLD.common.cartDoctor = BOLD.common.cartDoctor || {};
        const tap = <T>(input: T): T => input;
        BOLD.common.cartDoctorOriginal = {
            fix: BOLD.common.cartDoctor.fix?.bind(BOLD.common.cartDoctor) ?? tap,
            fixItem: BOLD.common.cartDoctor.fixItem?.bind(BOLD.common.cartDoctor) ?? tap,
        };
        BOLD.common.cartDoctor.fix = (rawCart: any) => this.processCartSync(rawCart);
        BOLD.common.cartDoctor.fixItem = (rawItem: any) => this.processCartItemSync(rawItem);
        BOLD.common.eventEmitter = BOLD.common.eventEmitter || new EventEmitter();
        const compatEventBridge = new EventBridge(BOLD.common.eventEmitter);
        compatEventBridge.listen('BOLD_COMMON_variant_changed', EVENTS.VARIANT_CHANGED);
        compatEventBridge.listen('BOLD_COMMON_shopify_discount_code_added', EVENTS.SHOPIFY_DISCOUNT_CODE_ADDED);
        compatEventBridge.listen('BOLD_COMMON_shopify_discount_code_setting', EVENTS.SHOPIFY_DISCOUNT_CODE_SETTING);

        this.postCartMiddleware = [];
    }

    /**
     * Merge in some new config.
     */
    setConfig(newConfig: GenericObject) {
        setPriceRulesConfig(newConfig);
        this.config = config();
    }

    /**
     * Set the Storefront Currency for PRE
     */
    setCurrency(currencyName: string) {
        const shop = container.get(Platform).shop;
        GetCurrencySymbolClient.get(shop, currencyName);
    }

    /*
     * Delete the Storefront Currency for PRE data from LocalStorage
     */
    removeCurrencyData() {
        const shop = container.get(Platform).shop;
        CurrencyStorage.clearCurrencyData(shop);
    }

    /**
     * Pass this function a raw cart object in the style
     * of those from cart.js.
     *
     * Rules will be fetched for the products in the cart
     * and applied to the returned cart object.
     *
     * This call has no effect on the global Shop
     * and can be used to process separate cart
     * payloads.
     */
    async processCart(rawCart: ShopifyCart): Promise<PREShopifyCart|ShopifyCart> {
        try {
            // @ts-expect-error if _pre_is_fixed is set, PRE already processed the cart
            if (rawCart._pre_is_fixed) {
                return rawCart;
            }
            const platform = container.get(Platform);
            let shop = platform.shop;
            shop = shop.makeCopyWithoutProducts();
            shop.cart = platform.Factory.createCartInstance(rawCart);

            const rulePromises = RuleStorage.fetchRulesForShop(shop);
            await RuleProcessor.applyRules(rulePromises, shop);

            const serialCart = serializeCart(shop.cart);
            const mergedCarts = mergeCarts(rawCart, serialCart);
            return this.runMiddleware(mergedCarts);
        } catch (err) {
            console.error(err);
            return rawCart;
        }
    }

    /**
     * Pass this function a raw cart object in the style
     * of those from cart.js.
     *
     * This is the synchronous version of this function.
     * It comes with the limitation of not being able
     * to fetch new rules so only rules for products
     * that already existed on the page will be applied.
     *
     * This call has no effect on the global Shop
     * and can be used to process separate cart
     * payloads.
     */
    processCartSync(rawCart: ShopifyCart): PREShopifyCart|ShopifyCart {
        try {
            // @ts-expect-error if _pre_is_fixed is set, PRE already processed the cart
            if (rawCart._pre_is_fixed) {
                return rawCart;
            }
            const platform = container.get(Platform);
            let shop = platform.shop;
            shop = shop.makeCopyWithoutProducts();
            shop.cart = platform.Factory.createCartInstance(rawCart);
            const ruleApiResponses = RuleStorage.getLoadedRulesForShop(shop);
            RuleProcessor.applyRulesSync(ruleApiResponses, shop);

            const serialCart = serializeCart(shop.cart);
            const mergedCarts = mergeCarts(rawCart, serialCart);
            return this.runMiddleware(mergedCarts);
        } catch (err) {
            console.error(err);
            return rawCart;
        }
    }

    processCartItemSync(rawCartItem: ShopifyCartItem): ShopifyCartItem {
        try {
            const platform = container.get(Platform);
            const shop = platform.shop.makeCopyWithoutProducts();
            shop.cart = platform.Factory.createCartInstance({ items: [rawCartItem] });
            shop.updateSubscriptionParams();
            shop.resetSubscriptionTabSelected();

            const ruleApiResponses = RuleStorage.getLoadedRulesForShop(shop);
            RuleProcessor.applyRulesSync(ruleApiResponses, shop);

            const cart = serialize(minimalCartTemplate);
            cart.items = [mergeLineItem(rawCartItem, serializeCartItem(shop.cart.getItems()[0]))];
            return this.runMiddleware(cart).items[0];
        } catch (err) {
            console.error(err);
            return rawCartItem;
        }
    }

    /**
     * Allows modification of the customer object.
     */
    updateCustomer(fn: (customer: GenericObject) => GenericObject) {
        const shop = container.get(Shop);
        try {
            const newCustomerObj = fn(shop.customer.toJSON());
            shop.customer = new Customer(newCustomerObj);
            channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
        } catch (err) {
            console.error(err);
        }
    }

    formatAmount(amount: number): string {
        return MoneyDisplay.display(amount);
    }

    /**
     * All price elements in the system flash green for a moment.
     */
    hi() {
        const shop = container.get(Shop);
        shop.cart.subTotalPriceElementSet.flash();
        shop.cart.items.forEach((cartItem) => cartItem.priceElementSet.flash() && cartItem.linePriceElementSet.flash());
        shop.products.forEach((prod) => prod.priceElementSet.flash());
    }

    getTotalDiscount() {
        const shop = container.get(Shop);
        return shop.cart.calculateTotalDiscount();
    }

    /**
     * Pass in a money dom element and get a report about what
     * product is using it and how it was adjusted.
     */
    elementReport(domElement: HTMLElement): string {
        const shop = container.get(Shop);
        Loaders.reports().then(({ elementReport }) => {
            console.info(elementReport(domElement, shop));
        });
        return '';
    }

    getProductByVariantId(rawVariantId: string|number): SerialProduct|null {
        const variantId = normalizeIdentifier(rawVariantId);
        const shop = container.get(Shop);
        let product;
        if (typeof variantId === 'string') {
            product = shop.products.find((prod) => {
                return !!prod.variants.find((v) => v.id === variantId);
            });
        }
        return product ? serializeProduct(product) : null;
    }

    /**
     * Process raw product data into the store.
     */
    async addProductsJson(rawProds: RawProduct[]): Promise<SerialProduct[]> {
        const platform = container.get(Platform);
        const prods = rawProds.map((rp) => {
            return platform.Factory.addRawProductToShop(rp, platform.shop);
        });

        const ruleApiResponses = RuleStorage.fetchRulesForProducts(prods);
        await RuleProcessor.applyRules(ruleApiResponses, platform.shop);
        return prods.map(serializeProduct);
    }

    /**
     * Single product version of `addProductsJson`.
     */
    async addProductJson(rawProd: RawProduct): Promise<SerialProduct> {
        const platform = container.get(Platform);

        // We add the rawProd right away, some themes depend on this synchronous behavior
        const prod = platform.Factory.addRawProductToShop(rawProd, platform.shop);

        await AddProductJsonBatcher.add(prod);

        return serializeProduct(prod);
    }

    getProductById(rawProductId: string | number): SerialProduct | null {
        const productId = normalizeIdentifier(rawProductId);
        const shop = container.get(Shop);
        let product;
        if (typeof productId === 'string') {
            product = shop.products.find((p) => p.id === productId);
        }
        return product ? serializeProduct(product) : null;
    }

    getPriceByProductId(rawProductId: string | number): number | null {
        const productId = normalizeIdentifier(rawProductId);
        const shop = container.get(Shop);
        let product : Product | undefined;
        if (typeof productId === 'string') {
            product = shop.products.find((p) => p.id === productId);
        }
        return product ? product.getPrice().amount() : null;
    }

    /**
     * @deprecated This fn is deprecated in favour of `getCartItem`.
     */
    getCartItemByLineIndex(lineIndex: number): SerialCartItem | null {
        console.warn('getCartItemByLineIndex is deprecated.');
        return this.getCartItem(lineIndex);
    }

    getCartItem(lineIndex: number): SerialCartItem | null {
        const shop = container.get(Shop);
        const cartItem = shop.cart.items[lineIndex];
        return cartItem ? serializeCartItem(cartItem) : null;
    }

    getCart(): SerialCart | null {
        const shop = container.get(Shop);
        return shop?.cart ? serializeCart(shop.cart) : null;
    }

    ready(): Promise<WindowApi> {
        // the api is already ready, just return a resolved promise
        return Promise.resolve(this);
    }

    /* develblock:start */
    static copyData() {
        const values: any = [];
        // @ts-expect-error
        copy(JSON.stringify({ boldCommon: BOLD.common.Shopify, html: document.documentElement.outerHTML }, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (values.indexOf(value) !== -1) {
                    try {
                        return serialize(value);
                    } catch (error) {
                        return;
                    }
                }
                values.push(value);
            }
            return value;
        }, '  '));
    }
    /* develblock:end */

    getPriceByProductIdAndVariantId(rawProductId: string | number, rawVariantId: string | number, qty: number): number | null {
        const productId = normalizeIdentifier(rawProductId);
        const variantId = normalizeIdentifier(rawVariantId);
        if (typeof productId !== 'string' || typeof variantId !== 'string') {
            return null;
        }
        const shop = container.get(Shop);
        const product = shop.products.find((prod) => prod.id === productId);
        if (product) {
            const variant = product.getVariantById(variantId);
            if (!variant) {
                return null;
            }
            const cartItem: FactoryCartItemInput = {
                quantity: qty,
                variant_id: variantId,
                price: variant.original_price.raw_amt,
                product_id: product.id,
            };
            const shopCopy = this.processRulesForShopAndCart(shop, [cartItem]);
            if (shopCopy.cart.items.length > 0) {
                const { price } = shopCopy.cart.items[0];
                return price !== null ? price.amount() : null;
            }
            return null;
        } else {
            return null;
        }
    }

    getPricesForVariantsByArray(productDetails: RawProductDetail[]): GenericObject[] {
        const results: any = [];
        const cartItems: FactoryCartItemInput[] = [];
        const shop = container.get(Shop);
        productDetails.forEach((productDetail) => {
            const productId = normalizeIdentifier(productDetail.productId);
            const variantId = normalizeIdentifier(productDetail.variantId);
            if (typeof productId !== 'string' || typeof variantId !== 'string') {
                return;
            }
            const product = shop.products.find((prod) => prod.id === productId);
            if (product) {
                const variant = product.getVariantById(variantId);
                if (variant) {
                    cartItems.push({
                        quantity: productDetail.qty,
                        variant_id: variantId,
                        price: variant.original_price.raw_amt,
                        product_id: product.id,
                    });
                }
            }
        });
        const shopCopy = this.processRulesForShopAndCart(shop, cartItems);
        if (shopCopy.cart.items.length > 0) {
            shopCopy.cart.items.forEach((item) => {
                results.push({ variant: item.variant_id, price: item.price !== null ? item.price.amount() : null });
            });
        }
        return results;
    }

    processRulesForShopAndCart(shop: Shop, cartItems: FactoryCartItemInput[]) {
        const shopCopy = shop.makeCopyWithoutProducts();
        shopCopy.cart = shop.platform.Factory.createCartInstance({
            items: cartItems,
        });
        const ruleApiResponses = RuleStorage.getLoadedRulesForShop(shopCopy);
        RuleProcessor.applyRulesSync(ruleApiResponses, shopCopy);
        return shopCopy;
    }

    setOrderData(orderData: GenericObject) {
        const shop = container.get(Shop);
        shop.setOrderData(orderData);
        const ruleApiResponses = RuleStorage.getLoadedRulesForShop(shop);
        RuleProcessor.applyRulesSync(ruleApiResponses, shop);
    }

    addCartParam(key: string, cartParam: GenericObject) {
        const shop = container.get(Shop);
        shop.addCartParam(key, cartParam);
        const ruleApiResponses = RuleStorage.getLoadedRulesForShop(shop);
        RuleProcessor.applyRulesSync(ruleApiResponses, shop);
    }

    registerPostCartMiddleware(middlewareFunction: MiddlewareFunction) {
        this.postCartMiddleware.push(middlewareFunction);
    }

    runMiddleware(cart: PREShopifyCart): PREShopifyCart {
        if (this.postCartMiddleware && this.postCartMiddleware.length > 0) {
            cart = serialize(cart);

            for (let i = 0; i < this.postCartMiddleware.length; i++) {
                cart = serialize(this.postCartMiddleware[i](cart));
            }
        }
        return cart;
    }

    hasFlag(flag: string) {
        return config(flag);
    }
}

function mergeLineItem(rawItem: ShopifyCartItem, updatedItem: SerialCartItem): ShopifyCartItem {
    return {
        ...rawItem,
        ...updatedItem,
        id: rawItem.id,
        discounted_price: updatedItem.price,
        final_price: updatedItem.price,
        final_line_price: updatedItem.line_price,
    };
}

function mergeCarts(rawCart: ShopifyCart, updatedCart: SerialCart): PREShopifyCart {
    return {
        ...rawCart,
        items: rawCart.items.map((rawCartItem, i) => {
            const updatedItem = updatedCart.items[i];
            if (updatedItem) {
                return mergeLineItem(rawCartItem, updatedItem);
            } else {
                return rawCartItem;
            }
        }),
        sub_total: updatedCart.sub_total,
        items_subtotal_price: updatedCart.sub_total,
        original_total_price: updatedCart.sub_total,
        total_price: updatedCart.sub_total,
        _pre_is_fixed: true,
    };
}

export default WindowApi;
