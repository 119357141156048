
/**
 * Pre Source Storage
 *
 * This class fetches and sets the PreSource query param using local storage for source pricing
 */
class SourceStorage {
    static storeSource(source: string) {
        window.localStorage.setItem('PRE.source', source);
    }

    static fetchSource(): string | null {
        if (typeof window === 'undefined') {
            return null;
        }

        const source = window.localStorage.getItem('PRE.source') as string | null;
        if (source === null) {
            return '';
        } else {
            return source;
        }
    }

    static clearSource() {
        window.localStorage.setItem('PRE.source', '');
    }
}

export default SourceStorage;
