
import { EVENTS, channel } from '../events';
import Platform from '../models/platform/Platform';
import container from '../components/Container';
import { GenericObject } from '../helpers/object';

function customerReducer(event: string, data: GenericObject) {
    switch (event) {
        case EVENTS.CUSTOMER_UPDATED:
            updateCustomer(data);
            break;
    }
}

async function updateCustomer(rawCustomer: GenericObject) {
    const platform = container.get(Platform);
    const shop = platform.getShop();
    shop.customer = platform.Factory.createCustomerInstance(rawCustomer);
    channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
}

export default customerReducer;
