
import Rule from '../../models/Rule';
import Variant from '../../models/platform/Variant';
import { serialize } from '../../helpers/object';

export interface SerialDiscount {
    amount: number;
    expiry?: string | null;
    layer_2_rule: Rule;
    message: string;
    source_app: string;
}

export function serializeDiscount(variant: Variant): SerialDiscount|null {
    const discountData = variant.getDiscountData();

    if (!discountData || !discountData.source_app) {
        return null;
    }

    return {
        amount: variant.original_price.amount() - variant.price.amount(),
        message: discountData.message,
        expiry: discountData.expiry,
        source_app: discountData.source_app,
        layer_2_rule: serialize(discountData.layer_2_rule),
    };
}
