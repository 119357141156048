
import { normalizeIdentifier } from '../helpers/identifier';
import Ruleset from '../models/Ruleset';
import Shop, { ProductCollections } from '../models/platform/Shop';
import { serializeShopifyDiscountCode, ShopifyDiscountCode } from './models/SerialShopifyDiscountCode';

let id = 1;

export interface BasePrices {
    [variant: string]: number;
}

/**
 * Api/RuleApiResponse
 */
class RuleApiResponse {
    base_prices: BasePrices;
    id: number;
    product_ids: string[];
    rulesets: Ruleset[];
    uses_from_cache: number;
    product_collections: ProductCollections;
    is_shopify_discount_code_rule: boolean;
    constructor({
        rulesets,
        base_prices,
        product_ids,
        product_collections = {},
        is_shopify_discount_code_rule = false,
    }: { rulesets: Ruleset[], base_prices: BasePrices, product_ids: Array<string|number>, product_collections: ProductCollections, is_shopify_discount_code_rule?: boolean }) {
        this.id = id++;
        this.rulesets = rulesets;
        this.base_prices = base_prices;
        this.product_ids = product_ids.map(normalizeIdentifier).filter(Boolean) as string[];
        this.uses_from_cache = 0;
        this.product_collections = product_collections;
        this.is_shopify_discount_code_rule = is_shopify_discount_code_rule;
    }

    usedFromCache() {
        this.uses_from_cache++;
    }
}

export function convertShopifyDiscountCodeToPRERulesets(codes: ShopifyDiscountCode[], product_ids: string[], shop: Shop, discountCodeSetting: number): RuleApiResponse {
    const rulesets = serializeShopifyDiscountCode(codes, discountCodeSetting, shop.shop_domain);
    const response = {
        rulesets: rulesets,
        base_prices: [] as any as BasePrices,
        product_ids: product_ids,
        product_collections: [] as any as ProductCollections,
        is_shopify_discount_code_rule: true,
    };
    return new RuleApiResponse(response);
}

export default RuleApiResponse;
