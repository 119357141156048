
import { PUBLIC_API_IDS_PER_REQUEST } from '../constants';
import Client from './Client';
import { chunk, uniqueByProp } from '../helpers/array';
import { SETTINGS, config, setConfig } from '../config';
import container from '../components/Container';
import Ruleset from '../models/Ruleset';
import RuleApiResponse, { BasePrices } from './RuleApiResponse';
import Shop from '../models/platform/Shop';
import { GenericObject } from '../helpers/object';

let configLoaded = false;

interface RulesetResponseShape {
    ids: string[];
    request: Promise<RuleApiResponse>;
}

interface RulesetRequestObject {
   path: string;
   ids: string[];
}

/**
 * Api/RuleApiClient
 *
 * This class handles the request to our back-end
 * Rule API.
 *
 * Request logic specific to the PRE Rule API goes
 * here.
 *
 */
class RuleApiClient {
    /**
     * Returns an array of Promises that each resolve to an
     * instance of RuleApiResponse.
     */
    static getRulesets(productIds: string[], filters: string[] = []): RulesetResponseShape[] {
        if (productIds.length === 0) {
            return [];
        }
        return RuleApiClient.generateApiRequestObjects(productIds, filters)
            .map(({ path, ids }: RulesetRequestObject) => ({
                ids,
                request: Client.get(path).then(this.transformRulesetResponse.bind(this, ids)),
            }));
    }

    static transformRulesetResponse(ids: string[], response: GenericObject): RuleApiResponse {
        if (!configLoaded) {
            setConfig({ ...response.config, ...response.featureFlags });
            configLoaded = true;
        }

        const rulesetModels = uniqueByProp(response.rulesets, 'id').map((rs) => new Ruleset(rs));
        const base_prices = RuleApiClient.indexBasePrices(response.base_prices);
        return new RuleApiResponse({
            rulesets: rulesetModels,
            base_prices,
            product_ids: ids,
            product_collections: response.product_collections,
        });
    }

    static generateApiRequestObjects(ids: string[], filters: string[] = []): RulesetRequestObject[] {
        const shop = container.get(Shop);
        const rulesetPath = `${shop.getShopDomain()}/rulesets`;

        ids = ids.sort();

        // Data for the request
        const idChunks = chunk(ids, PUBLIC_API_IDS_PER_REQUEST);
        const getParams = RuleApiClient.getParams(filters);

        // Each chunk becomes a request object with path and ids
        const apiPaths = idChunks.map((idChunk) => ({
            path: `${rulesetPath}?products=${idChunk.map((id) => `${id}`).join(',')}${getParams}`,
            ids: idChunk,
        }));

        return apiPaths;
    }

    static getParams(filters: string[]): string {
        let additionalParams = '';

        /* develblock:start */
        if (config(SETTINGS.disable_cache)) {
            additionalParams += `&t=${Date.now()}`;
        }
        /* develblock:end */

        if (filters.length > 0) {
            filters = filters.map((filter) => `filter[]=${filter}`);
            additionalParams += `&${filters.join('&')}`;
        }

        return additionalParams;
    }

    static indexBasePrices(base_prices: {id: string, price: number}[]): BasePrices {
        if (!base_prices) {
            return {};
        }

        const pricesById = base_prices.reduce((c: BasePrices, base_price): BasePrices => {
            const { id, price } = base_price;
            c[id] = price;
            return c;
        }, {});

        return pricesById;
    }
}

export default RuleApiClient;
