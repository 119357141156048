
import Rule, { RuleInput } from './Rule';
import { RULE_TYPES } from '../constants';
import { ENV } from '../config';
import Variant from './platform/Variant';
import { equalIdentifiers } from '../helpers/identifier';
import CartItem from './platform/CartItem';

export interface ProductSelectionVariantSku {
    variant_id: string|undefined;
    product_id: string;
    variant_sku: string;
}

export interface ProductSelectionVariantId {
    variant_id: string;
    product_id: string;
    variant_sku: undefined;
}

export interface ProductSelectionLineItemId {
    line_item_id: string|number;
    product_id: string;
    variant_id: undefined;
    variant_sku: undefined;
}

export type ProductSelectionVariant = ProductSelectionVariantId|ProductSelectionVariantSku;

export interface ProductSelectionProduct {
    variant_id: undefined;
    product_id: string;
    variant_sku: undefined;
}

export interface ProductSelection {
    type: 'SHOPIFY_PRODUCT_SEARCH'|'SHOPIFY_PRODUCTS_ALL'|'PRODUCTS_EXCEPT'|'LINE_ITEM_ID';
    products: Array<ProductSelectionProduct|ProductSelectionVariant|ProductSelectionLineItemId>;
}

export interface RulesetInput {
    app_slug: string;
    expiry_date?: string | null;
    external_id: string | null;
    id: number;
    priority: number;
    product_selection: ProductSelection;
    public_name: string;
    rules: RuleInput[];
    sync_percent: number;
}

class Ruleset {
    app_slug: string;
    expiry_date?: string | null;
    external_id: string | null;
    id: number;
    priority: number;
    product_selection: ProductSelection;
    public_name: string;
    rules: Rule[];
    sync_percent: number;

    constructor({
        id,
        app_slug,
        public_name,
        sync_percent = 100,
        expiry_date,
        priority = 0,
        product_selection,
        rules,
        external_id = null,
    }: RulesetInput) {
        this.id = id;
        this.app_slug = app_slug;
        this.public_name = public_name;
        this.expiry_date = expiry_date;
        this.sync_percent = sync_percent;
        this.priority = priority;
        this.product_selection = product_selection;
        this.external_id = external_id;

        this.rules = [];

        for (let i = 0; i < rules.length; i++) {
            const rawRule = rules[i];
            // back-end doesn't need display rules
            const skip = ENV.NODE && rawRule.type === RULE_TYPES.DISPLAY;
            if (!skip) {
                const rule = new Rule(rawRule, this);
                this.rules.push(rule);
            }
        }
    }

    getAppSlug() {
        return this.app_slug;
    }

    getRules() {
        return this.rules;
    }

    /**
     * Get rulesets by product
     */
    matchesVariant(variant: Variant): boolean {
        if (variant.hasHadRulesetApplied(this.id)) {
            return false;
        }

        switch (this.product_selection.type) {
            case 'SHOPIFY_PRODUCT_SEARCH':
                return this.matchesVariantByIds(variant);
            case 'SHOPIFY_PRODUCTS_ALL':
                return true;
            case 'PRODUCTS_EXCEPT':
                return this.isVariantNotExcluded(variant);
            case 'LINE_ITEM_ID':
                const parent = variant.getParent();
                const p = this.product_selection.products[0] as ProductSelectionLineItemId;
                return parent instanceof CartItem && parent.getId() === p.line_item_id;
        }
    }

    matchesVariantByIds(variant: Variant): boolean {
        const matchedRuleProduct = this.product_selection.products.find((ruleProduct) => {
            const variantMatchRequired = ruleProduct.variant_id || ruleProduct.variant_sku;
            // If the match criteria has a variant id or a sku we need to match on the specific variant and not the product
            if (variantMatchRequired) {
                // If true, that means that we only have a product_id on this variant, and no variant_id and no sku_id, so just compare product_ids
                if (ruleProduct.variant_id === ruleProduct.product_id && ruleProduct.product_id === variant.getProductId()) {
                    return equalIdentifiers(ruleProduct.product_id, variant.getProductId());
                }
                return (equalIdentifiers(ruleProduct.variant_sku, variant.getSku()) || equalIdentifiers(ruleProduct.variant_id, variant.getId()));
            }

            return equalIdentifiers(ruleProduct.product_id, variant.getProductId());
        });

        return !!matchedRuleProduct;
    }

    isVariantNotExcluded(variant: Variant): boolean {
        const matchesExclusion = this.product_selection.products.some((ruleProduct) => {
            if (ruleProduct.variant_id) {
                /*
                 * If the rule has variant id then the exclusion applies precisely
                 * to that variant and not to the product as a whole.
                 */
                return ruleProduct.variant_id === variant.getId();
            }
            return ruleProduct.product_id === variant.getProductId();
        });

        return !matchesExclusion;
    }
}

export default Ruleset;
