
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class CustomerGroupCondition extends BaseCondition {
    value: string | null;

    constructor({ type, value }: BaseConditionInput & { value: string | null }) {
        super({ type });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        const customer = shop.getCustomer();

        // null for CUSTOMER_GROUP value matches customers with no tags
        if (this.value === null && !customer.hasTags()) {
            return true;
        }

        return customer.hasTag(`${this.value}`);
    }
}

export default CustomerGroupCondition;
