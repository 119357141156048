
import elementReducer from './elementReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import customerReducer from './customerReducer';

export default [
    elementReducer,
    productReducer,
    customerReducer,
    cartReducer,
];
