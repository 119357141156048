
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';

class CustomerCountryCondition extends BaseCondition {
    list: string[];

    constructor({ type, list }: BaseConditionInput & { list: string[] }) {
        super({ type });
        this.list = list;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        /*
         * This condition is not evaluated by rule processor, it is returned to PRE Discount Codes inside the
         * processed payload and send by our plugin to Cashier/Checkout so it can be evaluated in their side.
         */
        return true;
    }

    toJSON() {
        return {
            type: this.type,
            list: this.list,
        };
    }
}

export default CustomerCountryCondition;
