
import BaseAction from './BaseAction';
import Money from '../money/Money';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';
import Cart from '../platform/Cart';
import CartItem from '../platform/CartItem';

abstract class BaseCartLevelDiscountAction extends BaseAction {
    value: number;
    name: string;

    constructor({ type, value, name }: { type: string, value: number, name: string }) {
        super({ type });
        this.value = value;
        this.name = name;
    }

    abstract calculateCartDiscount(shopCart: Cart, discount: number): number;

    act(price: Money, variant: Variant, shop: Shop) {
        const shopCart = shop.getCart();

        if (!(shopCart.cartLevelDiscounts.has(this.name))) {
            const oldTotal = shopCart.calculateSubTotalWithoutFees();
            const newCartSubTotal = this.calculateCartDiscount(shopCart, this.value);
            shopCart.sub_total.setAmount(newCartSubTotal + shopCart.calculateFeeTotal());
            variant.log(this.type, { price_adj: `Price adjusted by cart level discount of: ${this.value}, ${oldTotal} --> ${newCartSubTotal}` });
        }
    }

    toJSON(): { type: string; value: any; name: string; } {
        return {
            type: this.type,
            value: this.value,
            name: this.name,
        };
    }
}

export default BaseCartLevelDiscountAction;
