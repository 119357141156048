
import Money from '../money/Money';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

export interface BaseActionParams {
    type: string;
    value: number;
}

abstract class BaseAction {
    type: string;
    value: any;
    limit?: number;
    apply_to_discounted_subtotal?: boolean;

    constructor({ type }: { type: string }) {
        this.type = type;
        this.value = null;
    }

    toJSON() {
        return this.apply_to_discounted_subtotal
            ? {
                type: this.type,
                value: this.value,
                apply_to_discounted_subtotal: this.apply_to_discounted_subtotal,
            } : {
                type: this.type,
                value: this.value,
            };
    }

    abstract act(price: Money, variant: Variant, shop: Shop): void;
}

export default BaseAction;
