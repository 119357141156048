
import BaseAction from './BaseAction';
import Fee from '../money/Fee';
import CartItem from '../platform/CartItem';
import { SETTINGS, config } from '../../config';
import Shop from '../platform/Shop';
import Variant from '../platform/Variant';
import Money from '../money/Money';

class AddCartFeeAction extends BaseAction {
    name: string;
    value: number;

    constructor({ type, name, value }: { name: string, type: string, value: number }) {
        super({ type });
        this.name = name;
        this.value = value;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = price.amount();

        if (variant.parent instanceof CartItem) {
            const cartItem = variant.parent;
            shop.cart.addFee(`${cartItem.getId()}|${this.name}`, new Fee({
                name: this.name,
                amount: this.value * cartItem.getQuantity(),
                line_id: cartItem.getId(),
                is_cart_fee: true,
            }));
            if (config(SETTINGS.cart_fees_show_on_lines)) {
                variant.showFee(new Fee({
                    name: this.name,
                    amount: this.value,
                    is_cart_fee: true,
                }));
            }
        } else {
            // Cart fees can still be shown on the product page
            variant.showFee(new Fee({
                name: this.name,
                amount: this.value,
                is_cart_fee: true,
            }));
        }

        variant.log(this.type, { price_adj: `${oldPrice} --> ${price.amount()}` });
    }
}

export default AddCartFeeAction;
