
import Mustache from 'mustache';
import MoneyDisplay from './MoneyDisplay';
import { config, SETTINGS } from '../../config';
import { sortByProp } from '../../helpers/array';
import container from '../../components/Container';
import Shop from '../platform/Shop';
import Cart from '../platform/Cart';
import CartItem from '../platform/CartItem';
import Product from '../platform/Product';
import { GenericObject } from '../../helpers/object';
import Money from './Money';
import Variant from '../platform/Variant';
import QuantityBreak from './QuantityBreak';
import { RuleDiscountType } from './CartLevelDiscount';

export const DEFAULT_TEMPLATE = '{{money}}';

interface QuantityBreakData {
    qty: number;
    qty_max: string|number;
    price: string;
    saved: string;
    percent: string;
    sep: string;
    is_first: boolean;
    is_last: boolean;
}

interface FeeData {
    fee?: string;
    amount: string;
    is_cart_fee: boolean;
    line_item_key?: string;
}

interface TemplateInput {
    original: string;
    saved: string;
    has_saved: boolean;
    message: string;
    has_message: boolean;
    expiry: string;
    has_expiry: boolean;
    fees: FeeData[];
    has_fees: boolean;
    qty_breaks: QuantityBreakData[];
    qty_break_grid: string;
    has_qty_breaks: boolean;
    source_app?: string;
}

/**
 * MoneyTemplate
 */
class MoneyTemplate {
    after!: string;
    before!: string;
    templateString: any;

    constructor(templateString: any) {
        this.templateString = templateString;
        this.parse();
    }

    /**
     * Separate the template into before/after parts and
     * parse them.
     */
    parse() {
        const delimiter = DEFAULT_TEMPLATE;
        const templateParts = this.templateString.split(delimiter);

        this.before = templateParts.shift();
        this.after = templateParts.join(delimiter);

        Mustache.parse(this.before);
        Mustache.parse(this.after);
    }

    /**
     * Render the price data and templates into strings.
     */
    render(money: Money, priceElementSetParent: Cart|CartItem|Product): { display: string, before?: string, after?: string } {
        const display = money.display();

        if (this.templateString === DEFAULT_TEMPLATE) { // the template is just {{money}}
            return { display };
        }

        const shopData = MoneyTemplate.loadShopData();
        const modelData = MoneyTemplate.loadModelData(priceElementSetParent);

        const data = {
            has_message: false,
            message: '',
            ...shopData,
            ...modelData,
            price: display,
            raw_price: money.amount(),
        };

        // Replace template tokens in discount messages
        if (data.has_message && config(SETTINGS.replace_tokens_in_public_name)) {
            data.message = Mustache.render(data.message, data);
        }

        const before = Mustache.render(this.before, data).trim();
        const after = Mustache.render(this.after, data).trim();
        return { display, before, after };
    }

    static loadShopData() {
        const shop = container.get(Shop);
        return {
            is_product_page: shop.getPage() === 'product',
        };
    }

    static loadModelData(model: Cart|CartItem|Product): GenericObject {
        if (model instanceof Cart) {
            return MoneyTemplate.loadCartData(model);
        } else if (model instanceof CartItem) {
            return MoneyTemplate.loadCartItemData(model);
        }

        return MoneyTemplate.loadVariantData(model.getVariant());
    }

    static loadCartData(cart: Cart): GenericObject {
        const fees = MoneyTemplate.displayCartFees(cart);
        const cart_discount = MoneyTemplate.displayCartLevelDiscount(cart);

        return {
            fees,
            cart_discount,
            has_fees: fees.length > 0,
            has_message: false,
            message: null,
        };
    }

    static loadCartItemData(cartItem: CartItem): GenericObject {
        const variantData = MoneyTemplate.loadVariantData(cartItem.getVariant());
        const qty = cartItem.getQuantity();
        return {
            ...variantData,
            qty,
        };
    }

    static loadVariantData(variant: Variant): TemplateInput {
        const original = MoneyTemplate.displayOriginal(variant);
        const saved = MoneyTemplate.displaySaved(variant);
        const has_saved = MoneyTemplate.hasSaved(variant);
        const { message, expiry, source_app } = variant.getDiscountData() || {};
        const fees = MoneyTemplate.displayFees(variant);
        const qty_breaks = MoneyTemplate.displayQtyBreaks(variant);
        const qty_break_grid = MoneyTemplate.basicQtyBreakGrid(qty_breaks);

        return {
            original,
            saved,
            has_saved,

            message: message || '',
            has_message: !!message,

            expiry: expiry ? MoneyTemplate.displayExpiry(expiry) : '',
            has_expiry: !!expiry,

            fees,
            has_fees: fees.length > 0,

            qty_breaks,
            qty_break_grid,
            has_qty_breaks: qty_breaks.length > 0,

            source_app,
        };
    }

    static hasSaved(variant: Variant): boolean {
        return variant.price.amount() < variant.original_price.amount();
    }

    static displayOriginal(variant: Variant): string {
        return MoneyDisplay.display(variant.original_price.amount());
    }

    /**
     * Get all added fees for display
     */
    static displayFees(variant: Variant): FeeData[] {
        return variant.fees.map((fee) => ({
            fee: fee.name,
            amount: MoneyDisplay.display(fee.amount),
            is_cart_fee: fee.is_cart_fee,
        }));
    }

    /**
     * Get all added quantity breaks for display
     */
    static displayQtyBreaks(variant: Variant): QuantityBreakData[] {
        if (variant.qty_breaks.length === 0) {
            return [];
        }

        type tmpQB = Omit<QuantityBreak, 'proposed_price'> & { qty_max?: number };
        const qtyBreaks: tmpQB[] = MoneyTemplate.sortAndFilterBreaks(variant.qty_breaks);
        const feeTotal = variant.price.feeTotal();

        if (config(SETTINGS.qty_breaks_show_single)) {
            qtyBreaks.unshift({
                qty: 1,
                qty_max: qtyBreaks[0].qty - 1,
                price: variant.price.amountNoFees(),
                saved: 0,
                percent: 0,
            });
        }

        const qbUnlimitedText = config(SETTINGS.qty_breaks_unlimited_text) || '+';

        return qtyBreaks.map((qb, i): QuantityBreakData => {
            const is_first = i === 0;
            const is_last = i === qtyBreaks.length - 1;
            const qty_max = qtyBreaks[i + 1] ? qtyBreaks[i + 1].qty - 1 : `${qb.qty}${qbUnlimitedText}`;
            return {
                ...qb,
                qty_max,
                price: MoneyDisplay.display(qb.price + feeTotal),
                saved: MoneyDisplay.display(qb.saved),
                sep: '-',
                is_first,
                is_last,
                percent: `${qb.percent}%`,
            };
        });
    }

    /**
     * Sorts the quantity breaks array by quantity because
     * they weren't necessarily added in order.
     *
     * Also filters out any quantities with duplicate
     * quantities by choosing the break with the better
     * price.
     */
    static sortAndFilterBreaks(quantityBreaks: QuantityBreak[]): QuantityBreak[] {
        type QBQ = { [qty: number]: QuantityBreak };
        const uniqueBreaksObjectByQty = quantityBreaks.reduce((carry: QBQ, qb: QuantityBreak): QBQ => {
            const qtyAlreadyExists = !!carry[qb.qty];
            const newQtyHasBetterDeal = qtyAlreadyExists && qb.proposed_price < carry[qb.qty].proposed_price;
            if (!qtyAlreadyExists || newQtyHasBetterDeal) {
                carry[qb.qty] = qb;
            }
            return carry;
        }, {});

        const uniqueBreaksArray = Object.values(uniqueBreaksObjectByQty);

        return sortByProp(uniqueBreaksArray, 'qty');
    }

    static basicQtyBreakGrid(qty_breaks: any) {
        if (qty_breaks.length === 0) {
            return '';
        }
        return Mustache.render(`
            <table class="shappify_qb_grid">
                <thead><tr><th>Qty</th><th>Price</th></tr></thead>
                <tbody>
                    {{#qty_breaks}}
                        <tr>
                            <td>Buy {{qty}}</td>
                            <td>{{price}}</td>
                        </tr>
                    {{/qty_breaks}}
                </tbody>
            </table>
        `, { qty_breaks });
    }

    /*
     * Get all added fees for display
     */
    static displayCartFees(cart: Cart) {
        return Object.keys(cart.feesByLineId).map(k => {
            const fee = cart.feesByLineId[k];
            return {
                fee: fee.name,
                amount: MoneyDisplay.display(fee.amount),
                is_cart_fee: fee.is_cart_fee,
                line_item_key: k,
            };
        });
    }

    static displayCartLevelDiscount(cart: Cart) {
        const cartLevelDiscounts: any[] = [];

        cart.cartLevelDiscounts.forEach((cartLevelDiscount) => {
            const cartLevel = {
                discount_level_name: cartLevelDiscount.name,
                amount: MoneyDisplay.display(cartLevelDiscount.discountValue),
            };
            cartLevelDiscounts.push(cartLevel);
        });
        return cartLevelDiscounts;
    }

    static displayExpiry(expiryDateInUtc: string): string {
        const d = MoneyTemplate.mysqlTimeStampToDate(expiryDateInUtc);

        const localeStringsArray = [];
        const configLocale = config(SETTINGS.locale_string);
        if (configLocale) {
            localeStringsArray.push(configLocale);
        }

        const dateString = d.toLocaleDateString(localeStringsArray);
        const timeString = d.toLocaleTimeString(localeStringsArray, { hour: '2-digit', minute: '2-digit' });

        return `${dateString} ${timeString}`;
    }

    static mysqlTimeStampToDate(expiryDateInUtc: string): Date {
        const regex = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9]) (?:([0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;
        const parts = expiryDateInUtc.replace(regex, '$1 $2 $3 $4 $5 $6').split(' ').map((nb: any) => parseInt(nb));
        return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]));
    }

    /**
     * Gets the $ amount saved.
     */
    static displaySaved(variant: Variant): string {
        const amountSaved = variant.original_price.amount() - variant.price.amount();
        return MoneyDisplay.display(amountSaved);
    }
}

export default MoneyTemplate;
