import { BASE_URLS } from '../constants';
import { getIntlPolyfills } from './polyfill-intl';
import { checkBrowserRequiresPolyfill } from './checkBrowserRequiresPolyfill';

if (!Element.prototype.matches) {
    // @ts-expect-error
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s: any) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let el: any = this;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

function polyfills(success: () => void, failure: () => void) {
    let polyfillLoaded = false;
    const successWrapper = () => {
        polyfillLoaded = true;
        getIntlPolyfills().then(() => {
            success();
        }, () => {
            // This might be happening when using build interception...Just eat the error
            success();
        });
    };
    const failureWrapper = () => {
        if (!polyfillLoaded) {
            failure();
        }
    };

    if (checkBrowserRequiresPolyfill()) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `${BASE_URLS.CHUNK}/polyfills.js`;
        s.addEventListener('load', successWrapper, false);
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
        setTimeout(failureWrapper, 5000);
    } else {
        successWrapper();
    }
}

export default polyfills;
