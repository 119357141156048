
export default class PriceBreakdown {
    price: number;
    qty: number;

    constructor({
        qty,
        price,
    }: { qty: number, price: number }) {
        this.qty = qty;
        this.price = price;
    }

    toJSON() {
        return {
            qty: this.qty,
            price: this.price,
        };
    }
}
