export function checkBrowserRequiresPolyfill() {
    const features = [
        'Array.from',
        'Array.isArray',
        'Array.prototype.entries',
        'Array.prototype.every',
        'Array.prototype.filter',
        'Array.prototype.find',
        'Array.prototype.flatMap',
        'Array.prototype.forEach',
        'Array.prototype.includes',
        'Array.prototype.indexOf',
        'Array.prototype.keys',
        'Array.prototype.map',
        'Array.prototype.reduce',
        'Array.prototype.some',
        'Array.prototype.sort',
        'Array.prototype.values',
        'ArrayBuffer',
        'console',
        'DataView',
        'Date.now',
        'document',
        'Element',
        'fetch',
        'Intl',
        'JSON',
        'Map',
        'MutationObserver',
        'Number.MAX_SAFE_INTEGER',
        'Object.assign',
        'Object.create',
        'Object.defineProperties',
        'Object.defineProperty',
        'Object.entries',
        'Object.getOwnPropertyDescriptor',
        'Object.getOwnPropertyDescriptors',
        'Object.getOwnPropertySymbols',
        'Object.getPrototypeOf',
        'Object.keys',
        'Object.setPrototypeOf',
        'Object.values',
        'Promise.prototype.finally',
        'Reflect.construct',
        'Set',
        'String.prototype.includes',
        'String.prototype.trim',
        'Symbol.iterator',
        'Symbol.toStringTag',
        'URLSearchParams',
        'WeakMap',
        'WeakSet',
    ];
    let needsPolyfill = false;
    try {
        for (let i = 0; i < features.length; i++) {
            const featureName = features[i];
            const featureNameParts = featureName.split('.');
            let availableFunctionality = window;

            // A feature name will be a method path, similar to: "Array.prototype.find".
            // We must check if "Array" exists in our window, then if "prototype" exists
            // on Array, and then if "find" exists inside the prototype.
            for (let j = 0; j < featureNameParts.length; j++) {
                const featureNamePart = featureNameParts[j];

                // @ts-expect-error
                const featurePartExists = Boolean(availableFunctionality[featureNamePart]);

                if (featurePartExists) {
                    // Navigate to next level in the method path.
                    // @ts-expect-error
                    availableFunctionality = availableFunctionality[featureNamePart];
                } else {
                    needsPolyfill = true;
                    break;
                }
            }

            if (needsPolyfill) break;
        }
    } catch (e) {
        needsPolyfill = true;
    }

    return needsPolyfill;
}
