
import Log from '../../../helpers/Log';
import * as helpers from './helpers';

/**
 * ??
 */
export default function unbind() {
    window.addEventListener('load', () => {
        /* develblock:start */
        Log.debug(`Checkout loader: unbinding express checkout buttons.`);
        /* develblock:end */
        [0, 500, 1000].forEach(n => {
            window.setTimeout(() => unbindAll(), n);
        });
    }, false);
}

/**
 * ??
 */
function unbindAll() {
    const expressCheckoutButtons = helpers.getExpressCheckoutButtons();
    expressCheckoutButtons.forEach(btn => helpers.unbind(btn));
}
