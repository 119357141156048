
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';

class ShippingRateRangeCondition extends BaseCondition {
    min_value: number;
    max_value: number;

    constructor({ type, min_value, max_value }: BaseConditionInput & { min_value: number, max_value: number }) {
        super({ type });
        this.min_value = min_value;
        this.max_value = max_value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        /*
         * This condition is not evaluated by rule processor, it is returned to PRE Discount Codes inside the
         * processed payload and send by our plugin to Cashier/Checkout so it can be evaluated in their side.
         */
        return true;
    }

    toJSON() {
        return {
            type: this.type,
            min_value: this.min_value,
            max_value: this.max_value,
        };
    }
}

export default ShippingRateRangeCondition;
