import { GenericObject } from '../../helpers/object';

export const RuleDiscountType = {
    PERCENT: 0,
    MONEY: 1,
};

interface Input {
    name?: string
    amount: number;
    meta?: GenericObject | null;
    ruleDiscountType?: number;
    discountValue: number;
}

/**
 * CartLevelDiscount
 */
class CartLevelDiscount {
    discountValue: number;
    name?: string;
    meta: GenericObject|null;
    ruleDiscountType: number;
    amount: number;

    constructor({
        discountValue,
        amount,
        name,
        meta = null,
        ruleDiscountType = 1, // default value will be money

    }: Input) {
        this.amount = amount;
        this.name = name;
        this.meta = meta;
        this.ruleDiscountType = ruleDiscountType;
        this.discountValue = discountValue;
    }
}
export default CartLevelDiscount;
