
import EventChannel from './components/EventChannel';

export const EVENTS = {
    VARIANT_CHANGED: 'VARIANT_CHANGED',
    SHOPIFY_DISCOUNT_CODE_ADDED: 'SHOPIFY_DISCOUNT_CODE_ADDED',
    SHOPIFY_DISCOUNT_CODE_SETTING: 'SHOPIFY_DISCOUNT_CODE_SETTING',
    CART_UPDATED: 'CART_UPDATED',
    CART_UPDATED_QTY: 'CART_UPDATED_QTY',
    CUSTOMER_UPDATED: 'CUSTOMER_UPDATED',
    SHOP_STATE_UPDATED: 'SHOP_STATE_UPDATED',
    CART_STATE_UPDATED: 'CART_STATE_UPDATED',
    NEW_RULESET_RESPONSE: 'NEW_RULESET_RESPONSE',
    NEW_PRICE_ELEMENTS: 'NEW_PRICE_ELEMENTS',
    NEW_TEMPLATE_ELEMENTS: 'NEW_TEMPLATE_ELEMENTS',
    NEW_ELEMENT_PRODUCT: 'NEW_ELEMENT_PRODUCT',
    NEW_ELEMENT_LINE_ITEM_PRICE: 'NEW_ELEMENT_LINE_ITEM_PRICE',
    NEW_ELEMENT_LINE_ITEM_TOTAL: 'NEW_ELEMENT_LINE_ITEM_TOTAL',
    NEW_ELEMENT_SUBTOTAL: 'NEW_ELEMENT_SUBTOTAL',
    NEW_PRODUCTS_RAW: 'NEW_PRODUCTS_RAW',
    LOADED_PRODUCTS: 'LOADED_PRODUCTS',
    LOADED_CART: 'LOADED_CART',
    MONEY_RENDERED: 'MONEY_RENDERED',
    RP_QUEUE_COMPLETE: 'RP_QUEUE_COMPLETE',
    MONEY_ELEMENT_RESTORED: 'MONEY_ELEMENT_RESTORED',
    ORDER_DATA_UPDATED: 'ORDER_DATA_UPDATED',
};

export const channel = new EventChannel();
