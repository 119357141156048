
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import { ENV } from '../../config';
import { getCookie } from '../../helpers/browser';

export const REF_URL_COOKIE_KEY = '__bprrefuri';
export const REF_URL_COOKIE_VAL = 'y';

export function hasRefUrlCookie() {
    return getCookie(REF_URL_COOKIE_KEY) === 'y';
}

class RefUrlCondition extends BaseCondition {
    value: string;

    constructor({ type, value }: BaseConditionInput & { value: string }) {
        super({ type });
        this.value = value;
    }

    evaluate(): boolean {
        if (ENV.BROWSER && typeof this.value === 'string') {
            const wasJustReferred = document.referrer && document.referrer.indexOf(this.value) === 0;
            if (wasJustReferred || hasRefUrlCookie()) {
                return true;
            }
        }

        return false;
    }
}

export default RefUrlCondition;
