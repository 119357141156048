
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import CartItem from '../platform/CartItem';
import { CONDITION_OPERATORS as OPS } from '../../constants';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class CartLevelDiscountCondition extends BaseCondition {
    value: number;

    constructor({
        type,
        operator = OPS.EQUAL,
        value,
        requiresPrepare = false,
    }: BaseConditionInput & { value: number }) {
        super({ type, operator, requiresPrepare });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        if (!(variant.parent instanceof CartItem)) {
            return this.compare(1);
        }
        return this.compare(shop.cart.getSubTotal().amount());
    }
}

export default CartLevelDiscountCondition;
