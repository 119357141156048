
// Polling
export const DOM_POLLING_INTERVAL = 50; // ms

// This value is limited by the group_concat_max_len setting in mysql,
// it's configured in apps/web/config/database.php. The query
// sensitive to the above is in the class::function RuleBucketRepository::getRulesByProductIds.
// Variants can be 36 characters long (UUID in CommerceTools), plus a delimiter, and a max of 100 variants per product,
// times 15 (PUBLIC_API_IDS_PER_REQUEST).
// (36 + 1) * 100 * 15 = 55,500
export const PUBLIC_API_IDS_PER_REQUEST = 15;

export const BASE_URLS = {
    API: process.env.BASE_URL_API_GO || 'https://api.boldcommerce.com/go_pre/pricerules',
    PHP_API: process.env.BASE_URL_API || 'https://apps.boldapps.net/pricerules',
    CHUNK: process.env.BASE_URL_CHUNK || 'https://static.boldcommerce.com/bold-platform',
    BASE_CSP_URL: process.env.ENVIRONMENT === 'staging' ? 'https://cp.staging.boldapps.net' : 'https://cp.boldapps.net',
};

// Rule constants
export const CONDITION_OPERATORS = {
    EQUAL: '=',
    NOTEQUAL: '!=',
    LT: '<',
    GT: '>',
    LTE: '<=',
    GTE: '>=',
};

export const RULE_TYPES = {
    DISCOUNT: 'DISCOUNT',
    DISCOUNTABLE_ADDITION: 'DISCOUNTABLE_ADDITION',
    ADDITION: 'ADDITION',
    STACKABLE_DISCOUNT: 'STACKABLE_DISCOUNT',
    ROUNDING: 'ROUNDING',
    DISPLAY: 'DISPLAY',
    SETTING: 'SETTING',
    DISCOUNT_CODE: 'DISCOUNT_CODE',
    SHOPIFY_DISCOUNT_CODE_STACKABLE: 'SHOPIFY_DISCOUNT_CODE_STACKABLE',
    SHOPIFY_DISCOUNT_CODE_NOT_STACKABLE: 'SHOPIFY_DISCOUNT_CODE_NOT_STACKABLE',
    BASE_PRICE: 'BASE_PRICE',
    CART_LEVEL_DISCOUNT: 'CART_LEVEL_DISCOUNT',
};

export const RULE_DEFINITIONS = {
    [RULE_TYPES.BASE_PRICE]: {
        stackable: false,
        basePrice: true,
        description: 'Sets up the base price',
        layer: 0,
    },
    [RULE_TYPES.DISCOUNT]: {
        stackable: false,
        description: 'A sale or otherwise reduced price.',
        layer: 2,
    },
    [RULE_TYPES.DISCOUNTABLE_ADDITION]: {
        stackable: true,
        description: 'An upsell or otherwise increased price that can itself be discounted.',
        layer: 1,
    },
    [RULE_TYPES.ADDITION]: {
        stackable: true,
        tail: true,
        description: 'An upsell or otherwise increased price.',
        layer: 3,
    },
    [RULE_TYPES.STACKABLE_DISCOUNT]: {
        stackable: true,
        tail: true,
        description: 'A discount that should stack with any other discounts.',
        layer: 3,
    },
    [RULE_TYPES.ROUNDING]: {
        stackable: true,
        tail: true,
        description: 'Charm pricing or otherwise tweaked price.',
        layer: 3,
    },
    [RULE_TYPES.DISPLAY]: {
        stackable: true,
        tail: true,
        description: 'A rule providing display data rather than discount data.',
        /* note this is intentionally undocumented for now */
    },
    [RULE_TYPES.SETTING]: {
        stackable: true,
        tail: true,
        description: 'A rule that adjusts processing behaviour in price rules.',
        /* note this is intentionally undocumented for now */
    },
    [RULE_TYPES.DISCOUNT_CODE]: {
        /*
         * TODO: Add validations inside the rule-api endpoints, rules with the
         * DISCOUNT_CODE type should always have one DiscountCodeCondition inside them.
         */
        stackable: false,
        description: 'A discount based on a promotional code.',
        layer: 2,
    },
    [RULE_TYPES.SHOPIFY_DISCOUNT_CODE_NOT_STACKABLE]: {
        stackable: false,
        tail: true,
        description: 'A discount based on a shopify promotional code. Cannot be stacked.',
        layer: 3,
    },
    [RULE_TYPES.SHOPIFY_DISCOUNT_CODE_STACKABLE]: {
        stackable: true,
        tail: true,
        description: 'A discount based on a shopify promotional code. Can be stacked.',
        layer: 3,
    },
    [RULE_TYPES.CART_LEVEL_DISCOUNT]: {
        stackable: false,
        cart_level: true,
        description: 'A discount to be applied after all the others',
        layer: 4,
    },
};

export const PUBLIC_ERROR_MESSAGE = `
Bold Price Rules encountered an error while attempting to run.
`;
