import { randomString } from './string';

export function equalIdentifiers(ID: number|string|null|undefined, otherID: number|string|null|undefined): boolean {
    ID = normalizeIdentifier(ID);
    otherID = normalizeIdentifier(otherID);
    if (validIdentifier(ID) && validIdentifier(otherID)) {
        const splitIDs = ID?.split('|');
        return ID === otherID || (splitIDs?.some(id => id === otherID) === true);
    }
    return false;
}

function validIdentifier(input: any): boolean {
    if (typeof input === 'string') {
        input = input.trim();
        return input.length > 0 && input !== '0';
    }
    return false;
}

export function serializeIdentifier(id: string): number | null {
    const intId = parseInt(id);
    if (id === null || isNaN(intId)) {
        return null;
    }
    return intId;
}

export function makeVariantId(productId: string, id: string|number|null, sku: string|undefined) {
    id = normalizeIdentifier(id);
    sku = normalizeIdentifier(sku) ?? undefined;
    if (id) {
        return id;
    } else if (sku) { // BigCommerce won't have variantId's on the storefront
        return `${productId}|${sku}`;
    } else {
        return `${productId}|${randomString()}`;
    }
}
export function normalizeIdentifier(input: any): string | null {
    if (typeof input === 'number' && input > 0) {
        return normalizeIdentifier(input.toString());
    } else if (typeof input === 'string' && validIdentifier(input)) {
        return input;
    } else {
        return null;
    }
}
