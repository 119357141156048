
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import CartItem from '../platform/CartItem';
import { CONDITION_OPERATORS as OPS } from '../../constants';
import Variant from '../platform/Variant';
import Ruleset from '../Ruleset';
import Shop from '../platform/Shop';
import Rule from '../Rule';

interface ExcludeItem {
    id: string,
    limit: number | null;
}

class SpendXGetYCondition extends BaseCondition {
    value: number;
    excludeItems: ExcludeItem[] // an array of ExcludeItems,
    // with ids from the Cart Item that should be excluded, as well as the
    // limit that should be excluded. Null limit means all of that item should be excluded.
    excludePrice: number; // the price to exclude from the cart total
    // cart total will be calculated as: (total cart price - exclude price)

    constructor({
        type,
        operator = OPS.EQUAL,
        value,
        requiresPrepare = true,
    }: BaseConditionInput & { value: number }) {
        super({ type, operator, requiresPrepare });
        this.value = value;
        this.excludePrice = 0;
        this.excludeItems = [];
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        if (!(variant.parent instanceof CartItem)) {
            return this.compare(0);
        }

        for (const excludeItem of this.excludeItems) {
            if (variant.parent.id === excludeItem.id) { // found a matching exclude item
                const limit = excludeItem.limit;
                if ((limit === null) || (variant.parent.quantity <= limit)) {
                    // discount by the full line price
                    this.excludePrice += variant.parent.line_price.amount();
                } else {
                    // only discount quantity equal to the limit specified in the action
                    this.excludePrice += variant.price.amount() * limit;
                }
                break; // only match one item, since we are only evaluating for this variant
            }
        }
        let excludedCartPrice = shop.cart.getSubTotal().amount() - this.excludePrice;
        excludedCartPrice = excludedCartPrice > 0 ? excludedCartPrice : 0;
        return this.compare(excludedCartPrice);
    }

    prepare(item: CartItem, shop: Shop, rulesets: Ruleset[], rule: Rule) {
        // We already know the cart item matches the ruleset, due to prepareRulesets() in RuleProcessor.ts.
        // Find price of item to be excluded from the cart total
        let limit: number | null = null;
        for (let i = 0; i < rule.actions.length; i++) {
            if ((rule.actions[i].type === 'PRICE_ADJUST_RELATIVE_WITH_LIMIT') ||
                (rule.actions[i].type === 'PRICE_ADJUST_ABSOLUTE_WITH_LIMIT')) {
                // found a limit action. Use the limit provided by that action
                // assure the limit is not undefined
                limit = (rule.actions[i].limit === undefined) ? null : rule.actions[i].limit!;
                break; // use only the first limit action found
            }
        }

        this.excludeItems.push({
            id: item.id,
            limit: limit,
        });
        super.prepare(item, shop, rulesets);
    }
}

export default SpendXGetYCondition;
