
import CustomerGroupCondition from './CustomerGroupCondition';
import QtyByLineCondition from './QtyByLineCondition';
import QtyByProductCondition from './QtyByProductCondition';
import QtyByVariantCondition from './QtyByVariantCondition';
import QtyByMixAndMatchCondition from './QtyByMixAndMatchCondition';
import CustomerProvinceCondition from './CustomerProvinceCondition';
import LineItemPropertyCondition from './LineItemPropertyCondition';
import RefUrlCondition from './RefUrlCondition';
import QtyByBundleCondition from './QtyByBundleCondition';
import OrderConditionNameValuePairCondition from './OrderConditionNameValuePairCondition';
import SourceCondition from './SourceCondition';
import BuyXGetYCondition from './BuyXGetYCondition';
import SubscribeGroupCondition from './SubscribeGroupCondition';
import SubscribeIntervalCondition from './SubscribeIntervalCondition';

import DiscountCodeCondition from './discountCodes/DiscountCodeCondition';
import CartSubtotalMinCondition from './discountCodes/CartSubtotalMinCondition';
import DiscountedCartSubtotalMinCondition from './discountCodes/DiscountedCartSubtotalMinCondition';
import ShippingRateRangeCondition from './discountCodes/ShippingRateRangeCondition';
import CustomerBoldIdCondition from './discountCodes/CustomerBoldIdCondition';
import CustomerInAnyGroupFromListCondition from './discountCodes/CustomerInAnyGroupFromListCondition';
import CustomerCountryCondition from './discountCodes/CustomerCountryCondition';
import BuyXGetYDiscountCodeCondition from './discountCodes/BuyXGetYDiscountCodeCondition';
import ApplicableCurrencyCondition from './ApplicableCurrencyCondition';
import CartLevelDiscountCondition from './CartLevelDiscountCondition';
import SpendXGetYCondition from './SpendXGetYCondition';
import CustomerIdForShopifyDiscountCodesCondition from './CustomerIdForShopifyDiscountCodesCondition';
import QtyOnCartCondition from './discountCodes/QtyOnCartCondition';
import QtyOnCartForShopifyDiscountCodeCondition from './discountCodes/QtyOnCartForShopifyDiscountCodeCondition';

/**
 * ConditionFactory
 */
export function make(condition: any) {
    switch (condition.type) {
        case 'QTY_BY_LINE':
            return new QtyByLineCondition(condition);
        case 'QTY_BY_VARIANT':
            return new QtyByVariantCondition(condition);
        case 'QTY_BY_PRODUCT':
            return new QtyByProductCondition(condition);
        case 'QTY_BY_MIX_AND_MATCH':
            return new QtyByMixAndMatchCondition(condition);
        case 'CUSTOMER_GROUP':
            return new CustomerGroupCondition(condition);
        case 'CUSTOMER_PROVINCE':
            return new CustomerProvinceCondition(condition);
        case 'LINE_ITEM_PROPERTY':
            return new LineItemPropertyCondition(condition);
        case 'REF_URL':
            return new RefUrlCondition(condition);
        case 'BUNDLE':
            return new QtyByBundleCondition(condition);
        case 'ORDER_CONDITION':
            return new OrderConditionNameValuePairCondition(condition); // Condition for checking order conditions by name. For example to check for an OrderCondition such as "Zone" has the value "Paries"
        case 'SOURCE':
            return new SourceCondition(condition);
        case 'BUY_X_GET_Y':
            return new BuyXGetYCondition(condition);
        case 'DISCOUNT_CODE':
            /* To check if a specific discount code was applied to the cart. */
            return new DiscountCodeCondition(condition);
        case 'CART_SUBTOTAL_MIN':
            return new CartSubtotalMinCondition(condition);
        case 'CART_DISCOUNTED_SUBTOTAL_MIN':
            return new DiscountedCartSubtotalMinCondition(condition);
        case 'QTY_ON_CART':
            return new QtyOnCartCondition(condition);
        case 'QTY_ON_CART_SHOPIFY_DISCOUNT_CODE':
            return new QtyOnCartForShopifyDiscountCodeCondition(condition);
        case 'SHIPPING_RATE_RANGE':
            return new ShippingRateRangeCondition(condition);
        case 'CUSTOMER_BOLD_ID':
            return new CustomerBoldIdCondition(condition);
        case 'CUSTOMER_IN_ANY_GROUP_FROM_LIST':
            return new CustomerInAnyGroupFromListCondition(condition);
        case 'CUSTOMER_COUNTRY':
            return new CustomerCountryCondition(condition);
        case 'BUY_X_GET_Y_DISCOUNT_CODE':
            return new BuyXGetYDiscountCodeCondition(condition);
        case 'CURRENCY':
            return new ApplicableCurrencyCondition(condition);
        case 'SUBSCRIPTION_GROUP':
            return new SubscribeGroupCondition(condition);
        case 'SUBSCRIPTION_INTERVAL':
            return new SubscribeIntervalCondition(condition);
        case 'CART_LEVEL_DISCOUNT_CONDITION':
            return new CartLevelDiscountCondition(condition);
        case 'SPEND_X_GET_Y':
            return new SpendXGetYCondition(condition);
        case 'CUSTOMER_ID_SHOPIFY_DISCOUNT_CODE':
            return new CustomerIdForShopifyDiscountCodesCondition(condition);
    }

    throw new Error(`Unrecognized condition: ${condition.type}`);
}
