
import BaseCustomElement from './BaseCustomElement';
import { getDataRaw } from '../../../helpers/dom';
import { equalIdentifiers } from '../../../helpers/identifier';

class VariantElement extends BaseCustomElement {
    variantId: any;
    prep() {
        this.variantId = getDataRaw(this.element, 'variant-id');
    }

    render() {
        const variant = this._findVariant();
        if (!variant) {
            return null;
        }

        return this.api.formatAmount(variant.price);
    }

    _findVariant() {
        const prod = this.api.getProductByVariantId(this.variantId);
        if (!prod) {
            return null;
        }

        return prod.variants.find((v: any) => equalIdentifiers(v.id, this.variantId));
    }
}

export default VariantElement;
