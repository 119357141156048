
import RuleStorage from './RuleStorage';
import RuleProcessor from './RuleProcessor';
import container from './Container';
import { EVENTS, channel } from '../events';
import Shop from '../models/platform/Shop';
import Log from '../helpers/Log';
import Cart from '../models/platform/Cart';
import Product from '../models/platform/Product';
import { ShopifyDiscountCode } from '../api/models/SerialShopifyDiscountCode';
import ShopifyDiscountCodeStorage from './ShopifyDiscountCodeStorage';

const RESET = true;

interface QueueEntry {
    fn: () => void;
    name: string;
}

export class RuleProcessQueue {
    _queue: QueueEntry[];
    busy: boolean;
    log: any[];
    shop: Shop;
    constructor() {
        this._queue = [];
        this.busy = false;
        this.shop = container.get(Shop);
        /* develblock:start */
        container.debug.rp_queue = this;
        this.log = [];
        /* develblock:end */
    }

    init() {
        this.shop = container.get(Shop);
        this.listen();
    }

    listen() {
        channel.on(EVENTS.LOADED_PRODUCTS, ({
            products,
        }: any) => this.processProducts(products), this);
        channel.on(EVENTS.SHOP_STATE_UPDATED, () => {
            const codesApplied: any[] =
                this.shop.getPage() === 'cart'
                    ? ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(this.shop.shop_domain)
                    : [];
            this.processShop(RESET, codesApplied);
        }, this);
        // LOADED_CART means the cart is made up of all new instances
        channel.on(EVENTS.LOADED_CART, () => {
            const codesApplied: any[] = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(this.shop.shop_domain);
            this.processCart(this.shop.cart, RESET, codesApplied);
        }, this);
        // CART_STATE_UPDATED means the cart has the same instances
        channel.on(EVENTS.CART_STATE_UPDATED, () => {
            const codesApplied: any[] = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(this.shop.shop_domain);
            this.processCart(this.shop.cart, RESET, codesApplied);
        }, this);
        // SHOPIFY_DISCOUNT_CODE_ADDED means CSPv3 TAE submitted a Shopify Discount Code
        channel.on(EVENTS.SHOPIFY_DISCOUNT_CODE_ADDED, (discountRulesets: ShopifyDiscountCode[]) => {
            const codesApplied: any[] = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(this.shop.shop_domain);
            if (codesApplied.length === 1 && ShopifyDiscountCodeStorage.getShopifyDiscountCodeSettings(this.shop.shop_domain) !== '1') {
                RuleProcessor.renderShopifyDiscountCodeErrorMessage('Only one discount code can be applied per order.');
                return;
            }
            if (codesApplied.length > 0 && codesApplied.find(x => x.title === discountRulesets[0].title)) {
                RuleProcessor.renderShopifyDiscountCodeErrorMessage('This discount code is already applied.');
                return;
            }
            codesApplied.push(...discountRulesets);
            this.processCart(this.shop.cart, RESET, codesApplied);
            setTimeout(() => {
                const newCodesApplied: any[] = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(this.shop.shop_domain);
                if (!newCodesApplied.find(x => x.title === discountRulesets[0].title)) {
                    RuleProcessor.renderShopifyDiscountCodeErrorMessage('Discount code is not valid.');
                }
            }, 1000);
        }, this);
        // SHOPIFY_DISCOUNT_CODE_SETTING CSPv3 sent us the shopify discount codes setting (0, 1, 2)
        channel.on(EVENTS.SHOPIFY_DISCOUNT_CODE_SETTING, (setting: string) => {
            ShopifyDiscountCodeStorage.storeSettingsData(this.shop.shop_domain, setting);
        }, this);
    }

    /**
     * Run the next fn in the queue.
     */
    async next() {
        if (this._queue.length === 0) {
            this.busy = false;
            /* develblock:start */
            Log.debug(`RPQ / complete.`);
            /* develblock:end */
            channel.emit(EVENTS.RP_QUEUE_COMPLETE);
        } else {
            this.busy = true;
            const { fn, name } = this._queue.shift() as QueueEntry;
            await fn();
            /* develblock:start */
            Log.debug(`RPQ {l:${this._queue.length}} / item done : ${name}`);
            /* develblock:end */
            window.setTimeout(() => this.next(), 0);
        }
    }

    /**
     * Add a fn to the queue.
     */
    queue(fn: () => void, name = '') {
        this._queue.push({ fn, name });
        /* develblock:start */
        this.log.push(name);
        Log.debug(`RPQ {l:${this._queue.length}} / queued : ${name}`);
        /* develblock:end */
        if (!this.busy) {
            this.next();
        }
    }

    processShop(reset = false, shopify_discount_code: ShopifyDiscountCode[] = []) {
        this.queue(async () => {
            reset && this.shop.reset();
            const rulePromises = RuleStorage.fetchRulesForShop(this.shop);
            const discountCodeSetting = ShopifyDiscountCodeStorage.getShopifyDiscountCodeSettings(this.shop.shop_domain);
            await RuleProcessor.applyRules(rulePromises, this.shop, shopify_discount_code, Number(discountCodeSetting));
        }, `${reset ? 're' : ''}process shop`);
    }

    processProducts(products: Product[], reset = false) {
        this.queue(async () => {
            reset && products.forEach((p) => p.reset());
            const rulePromises = RuleStorage.fetchRulesForProducts(products);
            await RuleProcessor.applyRules(rulePromises, this.shop);
        }, `${reset ? 're' : ''}process products`);
    }

    /**
     * @param cart
     * @param {boolean} reset This is true when we're updating an existing cart, and false when the cart is fresh.
     * @param shopify_discount_code
     */
    processCart(cart: Cart, reset = false, shopify_discount_code: ShopifyDiscountCode[] = []) {
        this.queue(async () => {
            reset && cart.reset();
            const rulePromises = RuleStorage.fetchRulesForCartItems(cart.items);
            this.shop.updateSubscriptionParams();
            const discountCodeSetting = ShopifyDiscountCodeStorage.getShopifyDiscountCodeSettings(this.shop.shop_domain);
            await RuleProcessor.applyRules(rulePromises, this.shop, shopify_discount_code as ShopifyDiscountCode[], Number(discountCodeSetting));
        }, `${reset ? 're' : ''}process cart`);
    }
}

export default new RuleProcessQueue();
