
import ShopifyFactory from './components/shopify/ShopifyFactory';
import ShopifyBinder from './components/shopify/ShopifyBinder';
import polyfills from './browser/polyfills';
import { setup, start } from './browser/init';

setup();

polyfills(() => {
    start('shopify', ShopifyBinder, ShopifyFactory);
}, () => {
    throw new Error('Failed to load polyfills');
});
