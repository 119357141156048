
import { isShopifyMCActive, applyShopifyMC } from '../../helpers/shopify';
import container from '../../components/Container';
import Shop from '../platform/Shop';
import { SETTINGS, config } from '../../config';
import { channel, EVENTS } from '../../events';
import addNumberSeparators from './moneySeparators';
import { boldWindow } from '../../helpers/windowBold';
import GetCurrencySymbolClient from '../../api/GetCurrencySymbolClient';
import CurrencyStorage from '../../components/CurrencyStorage';
import WindowApi from '../../api/WindowApi';

let boldMcReady = false;

/**
 * MoneyDisplay
 */
class MoneyDisplay {
    static display(amount: any) {
        const format = this.getFormat();
        const formatter = this.getFormatter();
        return formatter(amount, format);
    }

    static enableBoldMC() {
        boldMcReady = true;
    }

    static getFormat() {
        const shop = container.get(Shop);
        const useBoldPreCurrency = config(SETTINGS.multi_currency) === true || config(SETTINGS.multi_currency) === 1;
        const currencyData = CurrencyStorage.fetchCurrencyData(shop);

        if (useBoldPreCurrency) {
            const shouldUpdateCurrency = currencyData === null;

            if (!shouldUpdateCurrency) {
                shop.setMoneyFormat(currencyData?.moneyFormat);
            } else {
                const api = container.get(WindowApi);
                api.setCurrency(config(SETTINGS.multi_currency_default_currency));
                channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
            }
        } else {
            const boldMCFormat = this.getBoldMCFormat();
            if (boldMCFormat) {
                return boldMCFormat;
            }
        }
        return shop.getMoneyFormat();
    }

    static getFormatter() {
        if (isShopifyMCActive()) {
            return this.shopifyMCFormatMoney.bind(this);
        }

        /**
         * First choice is the config currency_format_fn override.
         */
        const configFormatFn = config(SETTINGS.currency_format_fn);
        if (typeof configFormatFn === 'function') {
            return configFormatFn;
        }

        /**
         * Second choice is if the auto-browser locale setting is on.
         */
        if (config(SETTINGS.use_browser_locale)) {
            return this.automaticBrowserLocaleFormatter;
        }

        /**
         * Third choice is settings for using the browser i18n API.
         */
        const currencyLang = config(SETTINGS.currency_lang);
        const currencyCode = config(SETTINGS.currency_code);
        if (currencyLang && currencyCode) {
            return this.browserLocaleFormatter;
        }

        /**
         * Fourth etc. choices are fallbacks to common Shopify currency functions.
         */
        const formatters = [
            boldWindow.Shopify?.formatMoney,
            boldWindow.Currency?.formatMoney,
            boldWindow.theme?.Currency?.formatMoney,
            this.lastFormatMoney,
        ];

        if (this.usingBoldCurrency()) {
            formatters.unshift(boldWindow.BOLDCURRENCY?.converter.modifyPriceForDom);
        }

        const formatter = formatters.find(f => typeof f === 'function') ?? this.lastFormatMoney;

        // Wrap formatter to catch errors and fall back to the last formatter
        return (price: any, format: any) => {
            try {
                if (this.usingBoldCurrency()) {
                    return formatter(price);
                }
                return formatter(price, format);
            } catch (err) {
                const __THREW_CURRENCY_FORMATTER_ERROR = '__THREW_CURRENCY_FORMATTER_ERROR';
                if (!container.getData(__THREW_CURRENCY_FORMATTER_ERROR)) {
                    console.warn('Handled currency formatter error', err);
                    container.set(__THREW_CURRENCY_FORMATTER_ERROR, true);
                }
                return this.lastFormatMoney(price, format);
            }
        };
    }

    static usingBoldCurrency() {
        return boldMcReady && boldWindow.BOLDCURRENCY?.converter.modifyPriceForDom;
    }

    static getBoldMCFormat() {
        const boldMoneyFormats = boldWindow.BOLDCURRENCY?.moneyFormats;

        if (!boldMoneyFormats || Object.keys(boldMoneyFormats).length === 0) {
            return;
        }

        const currency = this.getCurrentCurrency();

        return boldWindow.BOLDCURRENCY?.moneyFormats[currency]?.money_format;
    }

    static getCurrentCurrency(): string {
        const boldCurrency = boldWindow.BOLDCURRENCY?.currentCurrency;
        if (boldCurrency) {
            return boldCurrency;
        }

        const shop = container.get(Shop);
        return shop.getCurrency();
    }

    static shopifyMCFormatMoney(money: any, format: any) {
        const convertedMoney = applyShopifyMC(money);
        return this.lastFormatMoney(convertedMoney, format);
    }

    static automaticBrowserLocaleFormatter(money: any) {
        const localeString = window.navigator.language;
        const currency = this.getCurrentCurrency();
        return new Intl.NumberFormat(localeString, { style: 'currency', currency }).format(money / 100);
    }

    static browserLocaleFormatter(money: any) {
        const localeString = config(SETTINGS.locale_string) || config(SETTINGS.currency_lang) || window.navigator.language;
        const currency = config(SETTINGS.currency_code) || this.getCurrentCurrency();
        return new Intl.NumberFormat(localeString, { style: 'currency', currency }).format(money / 100);
    }

    static lastFormatMoney(money: number|string, format = '$ {{ amount }}'): string {
        let numberString = '';
        const formatRegex = /{{\s*(\w+)\s*}}/;
        const match = format.match(formatRegex)?.[1] ?? 'amount';

        switch (match) {
            case 'amount':
                numberString = addNumberSeparators(money, 2, ',', '.');
                break;
            case 'amount_no_decimals':
                numberString = addNumberSeparators(money, 0, ',', '.');
                break;
            case 'amount_with_comma_separator':
                numberString = addNumberSeparators(money, 2, '.', ',');
                break;
            case 'amount_no_decimals_with_comma_separator':
                numberString = addNumberSeparators(money, 0, '.', ',');
                break;
            case 'amount_with_space_separator':
                numberString = addNumberSeparators(money, 2, ' ', ',');
                break;
            case 'amount_no_decimals_with_space_separator':
                numberString = addNumberSeparators(money, 0, ' ', ',');
                break;
            case 'amount_with_apostrophe_separator':
                numberString = addNumberSeparators(money, 2, "'", '.');
                break;
        }
        return format.replace(formatRegex, numberString);
    }
}

export default MoneyDisplay;
