
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';
import ShopifyDiscountCodeStorage from '../../components/ShopifyDiscountCodeStorage';

/**
 * This will only ever be used for Shopify Discount Codes
 */
class CustomerIdForShopifyDiscountCodesCondition extends BaseCondition {
    value: string | null;

    constructor({ type, value }: BaseConditionInput & { value: string | null }) {
        super({ type });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        const customer = shop.getCustomer();

        const shouldEvaluate = ShopifyDiscountCodeStorage.getShopifyDiscountCodeSettings(shop.shop_domain);
        if (!shouldEvaluate || Number(shouldEvaluate) === 0) {
            return false;
        }

        return customer.id === Number(this.value);
    }
}

export default CustomerIdForShopifyDiscountCodesCondition;
