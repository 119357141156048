
import container from './components/Container';

export const ENV = getEnvInfo();

export const SETTINGS = {
    /*  1 */ handle_all_prices: 'handle_all_prices',
    /*  2 */ checkout_json: 'checkout_json',
    /*  3 */ verbose_logs: 'verbose_logs', // does not do anything in prod browser build.
    /*  4 */ disable_cache: 'disable_cache',
    /*  5 */ template_product: 'template_product',
    /*  6 */ template_cart_item: 'template_cart_item',
    /*  7 */ template_sub_total: 'template_sub_total',
    /*  8 */ replace_tokens_in_public_name: 'replace_tokens_in_public_name',
    /*  9 */ compare_at_price_as_original: 'compare_at_price_as_original',
    /* 10 */ use_browser_locale: 'use_browser_locale',
    /* 11 */ qty_breaks_show_single: 'qty_breaks_show_single',
    /* 12 */ shopify_auto_update_qty: 'shopify_auto_update_qty',
    /* 13 */ currency_format_fn: 'currency_format_fn',
    /* 14 */ currency_lang: 'currency_lang',
    /* 15 */ currency_code: 'currency_code',
    /* 16 */ cart_fees_show_on_lines: 'cart_fees_show_on_lines',
    /* 17 */ locale_string: 'locale_string',
    /* 18 */ checkout_data_style: 'checkout_data_style',
    /* 19 */ checkout_submit_style: 'checkout_submit_style',
    /* 20 */ async_checkout_test_mode: 'async_checkout_test_mode',
    /* 21 */ checkout_loader_enabled: 'checkout_loader_enabled',
    /* 22 */ async_checkout_bar_enabled: 'async_checkout_bar_enabled',
    /* 23 */ async_checkout_bar_color: 'async_checkout_bar_color',
    /* 24 */ qty_breaks_unlimited_text: 'qty_breaks_unlimited_text',
    /* 25 */ legacy_variant_elements: 'legacy_variant_elements',
    /* 26 */ multi_currency: 'multi_currency',
    /* 27 */ multi_currency_default_currency: 'multi_currency_default_currency',
    /* 28 */ display_public_message: 'display_public_message',
    /* 29 */ disable_price_rounding: 'disable_price_rounding',
};

export const defaultConfig = {
    /**
     * 1. Handle all prices even if they don't need to change.
     * Temporarily true by default until our price change detection
     * can include other data changes such as message/qbgrid.
     */
    [SETTINGS.handle_all_prices]: true,
    /**
     * 2. Append ?json to the PRE checkout url so it shows checkout results.
     */
    [SETTINGS.checkout_json]: false,
    /**
     * 3. Show all log output instead of only errors.
     * Note that this only works in node or in the .dev
     * browser build.
     */
    [SETTINGS.verbose_logs]: false,
    /**
     * 4. Disable the cache for ruleset requests by appending a ?t=123 param.
     * For dev/testing purposes only (only works in .dev.js build).
     */
    [SETTINGS.disable_cache]: false,
    /**
     * 5. Template for the product price display (product/collection/search pages).
     */
    [SETTINGS.template_product]: `
        {{money}}
        {{#fees}}<div>{{fee}}: {{{amount}}}</div>{{/fees}}
        {{#has_message}}<small>{{{message}}}</small>{{/has_message}}
    `,
    /**
     * 6. Template for the cart item price display.
     */
    [SETTINGS.template_cart_item]: `
        {{money}}
        {{#fees}}<div>{{fee}}: {{{amount}}}</div>{{/fees}}
    `,
    /**
     * 7. Template for the sub-total price display on the cart page.
     */
    [SETTINGS.template_sub_total]: `
        {{#fees}}<div>{{fee}}: {{{amount}}}</div>{{/fees}}
        {{#cart_discount}}<div>{{discount_level_name}}: {{{amount}}}</div>{{/cart_discount}}
        {{money}}
    `,
    /**
     * 8. Replace mustache template tokens in the ruleset public name?
     */
    [SETTINGS.replace_tokens_in_public_name]: true,
    /**
     * 9. Seed the system with compare-at-price for prices instead.
     * NOTE: This was added for Staples and may not be applicable for other shops.
     */
    [SETTINGS.compare_at_price_as_original]: false,
    /**
     * 10. Use the browser locale API to handle for currency formatting.
     * Ignored if `currency_format_fn` is defined.
     */
    [SETTINGS.use_browser_locale]: false,
    /**
     * 11. Show the single qty row in qty breaks grids.
     */
    [SETTINGS.qty_breaks_show_single]: true,
    /**
     * 12. Quantity changes detected by price rules will update Shopify.
     */
    [SETTINGS.shopify_auto_update_qty]: true,
    /**
     * 13. Currency format function. If this is defined
     * then all dom paints for prices will run the raw
     * price through this first.
     * This function receives the amount as an arg.
     * This setting will override other currency settings.
     */
    [SETTINGS.currency_format_fn]: null,
    /**
     * 14. Currency language e.g. en-US or en-CA or fr-CA --DEPRECATED by `locale_string`
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
     * Ignored if `currency_format_fn` is defined or `use_browser_locale` is `true`.
     */
    [SETTINGS.currency_lang]: '', // DEPRECATED by `locale_string`
    /**
     * 15. Currency code e.g. CAD or USD
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
     * Ignored if `currency_format_fn` is defined or `use_browser_locale` is `true`.
     */
    [SETTINGS.currency_code]: '',
    /**
     * 16. Dictates whether cart level fees should be shown under the line item they belong to.
     */
    [SETTINGS.cart_fees_show_on_lines]: true,
    /**
     * 17. Locale string e.g. en-US or en-CA or fr-CA
     * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
     * Ignored if `currency_format_fn` is defined or `use_browser_locale` is `true`.
     */
    [SETTINGS.locale_string]: '',
    /**
     * 18. Checkout data style.
     * One of: `cartjs` / `token`
     * - *cartjs -- default* fetches the cart just in time for checkout
     * - *token* sends the token only and the back-end fetches the cart (*problem: requires shop password if set*)
     */
    [SETTINGS.checkout_data_style]: 'cartjs',
    /**
     * 19. Checkout submit style
     * One of: `async` / `direct`
     * - *async -- default* creates the checkout async and then redirects to the Shopify generated checkout.
     * - *direct* redirects to the Price Rules checkout (*problem: for Google Analytics / needs proxy page*)
     */
    [SETTINGS.checkout_submit_style]: 'async',
    /**
     * 20. Async checkout test mode
     * Used for testing/timing the async mode checkout.
     * Has no effect if checkout submit style is not `async`.
     */
    [SETTINGS.async_checkout_test_mode]: false,
    /**
     * 21. Checkout loader enabled.
     * Setting this to false will force disable the PRE
     * draft order checkout. This is auto-disabled when
     * Cashier is installed.
     */
    [SETTINGS.checkout_loader_enabled]: true,
    /**
     * 22. Show a progress bar at the top of the page
     * when checkout out in async mode. This helps avoid
     * the impression of the initial hang as the checkout
     * is being generated in the background.
     */
    [SETTINGS.async_checkout_bar_enabled]: true,
    /**
     * 23. The async checkout progress bar color.
     * #29d is a default looking blue.
     */
    [SETTINGS.async_checkout_bar_color]: '#29d',
    /**
     * 24. The unlimited quantity text on QB grid style.
     */
    [SETTINGS.qty_breaks_unlimited_text]: '+',
    /**
     * 25. Legacy variant elements:
     *      This controls the behaviour of elements with data-variant-id.
     *      *legacy element*: changes along with variant changes on the page like elements with data-product-id
     *      *new element*: stays static at the variant id provided.
     */
    [SETTINGS.legacy_variant_elements]: false,

    /**
     * 26. Multi-currency setting:
     *      This controls wheather storefront ruleset request will add currency filter or not
     */
    [SETTINGS.multi_currency]: false,

    /**
     * 27. multi_currency_default_currency eetting:
     *      This controls default currency on storefront, should be null so that storefront will use
     *      shop's currency
     */
    [SETTINGS.multi_currency_default_currency]: null,

    /**
     * 28. display_public_message setting:
     *      This controls wheather the ruleset's public name/message is displayed on cart items on the cart page
     */
    [SETTINGS.display_public_message]: {
        cart_page: false,
        product_page: true,
    },

    /**
     * 29. disable_price_rounding setting:
     *      This controls wheather the hopper will do round with calculation
     */
    [SETTINGS.disable_price_rounding]: false,
};

/**
 * Retrieves general app configuration settings.
 *
 * @param {string} key
 * @returns {any}
 */
export function config(key?: string) {
    const configObj = container.getData('CONFIG');
    if (typeof key !== 'string') {
        // return all config
        return {
            ...defaultConfig,
            ...configObj,
        };
    }
    return configObj && configObj[key] !== undefined
        ? configObj[key]
        : defaultConfig[key];
}

/**
 * @param {object} newConfig
 */
export function setConfig(newConfig: any) {
    if (ENV.BROWSER) {
        if (!newConfig || typeof newConfig !== 'object') {
            console.warn(`Invalid config provided.`, newConfig);
            return;
        }

        const currentConfig = container.getData('CONFIG');
        container.set('CONFIG', Object.assign(currentConfig, newConfig));

        /* develblock:start */
        container.debug.config = config();
        /* develblock:end */
    }
}

/**
 * @returns {{
 *   BROWSER: boolean,
 *   NODE: boolean,
 *   VERSION_MEM: string|null,
 *   DEV_BUILD: boolean,
 *   UNIT_TESTING: boolean,
 *   VERSION: string|null,
 *   NODE_ENV: string
 * }}
 */
function getEnvInfo() {
    const isUnitTest = typeof expect === 'function';
    const isBrowser = typeof window === 'object';

    const envInfo = {
        BROWSER: isBrowser,
        NODE: !isBrowser,
        NODE_ENV: process.env.NODE_ENV,
        UNIT_TESTING: isUnitTest,
        DEV_BUILD: false,
        VERSION: process.env.VERSION,
        VERSION_MEM: process.env.VERSION_MEM,
    };

    /* develblock:start */
    if (isBrowser || process.env.ENVIRONMENT === 'staging') {
        envInfo.DEV_BUILD = true;
    }
    /* develblock:end */

    return envInfo;
}
