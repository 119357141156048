
import BaseAction, { BaseActionParams } from './BaseAction';
import Money from '../money/Money';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class PriceAdjustRelativeAction extends BaseAction {
    value: number;

    constructor({ type, value }: BaseActionParams) {
        super({ type });
        this.value = value;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = price.amount();

        price.add(this.value);

        variant.log(this.type, { price_adj: `${oldPrice} --> ${price.amount()}` });
    }
}

export default PriceAdjustRelativeAction;
