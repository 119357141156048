
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';

class QtyOnCartCondition extends BaseCondition {
    value: number;

    constructor({ type, value }: BaseConditionInput & { value: number }) {
        super({ type });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        /*
         * This condition is not evaluated by rule processor, it is returned to PRE Discount Codes inside the
         * processed payload and send by our plugin to Cashier/Checkout so it can be evaluated in their side.
         */
        return true;
    }
}

export default QtyOnCartCondition;
