
interface Input {
    percent: number;
    price: number;
    proposed_price: number;
    qty: number;
    saved: number;
}

class QuantityBreak {
    percent: number;
    price: number;
    proposed_price: number;
    qty: number;
    saved: number;

    constructor({
        qty,
        price,
        percent,
        saved,
        proposed_price,
    }: Input) {
        this.qty = qty;
        this.price = price;
        this.percent = percent;
        this.saved = saved;
        this.proposed_price = proposed_price;
    }
}

export default QuantityBreak;
