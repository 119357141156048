
import Log from '../helpers/Log';
import { dedupe } from '../helpers/array';
import RuleApiClient from '../api/RuleApiClient';
import container from './Container';
import Shop from '../models/platform/Shop';
import { normalizeIdentifier } from '../helpers/identifier';
import RuleApiResponse from '../api/RuleApiResponse';
import Product from '../models/platform/Product';
import CartItem from '../models/platform/CartItem';
import { config, SETTINGS } from '../config';
import CurrencyStorage from '../components/CurrencyStorage';

const ruleRequestCache: { [product_id: string]: Promise<RuleApiResponse> } = {};
const ruleResponseCache: { [product_id: string]: RuleApiResponse } = {};

/**
 * Rule Storage
 *
 * This class manages fetching rules from all sources.
 * Currently this class just uses the RuleApiClient to make a
 * request but in future it will manage local storage
 * caching or other caching approaches.
 */
class RuleStorage {
    static fetchRulesForShop(shop: Shop): Promise<RuleApiResponse>[] {
        shop = shop || container.get(Shop);
        const productIds = shop.getProducts().map((product) => product.getId());
        const cartItemIds = shop.getCart().getItems().map((cartItem) => cartItem.getProductId());
        return RuleStorage.fetchRulesForProductIds([ ...productIds, ...cartItemIds ]);
    }

    static getLoadedRulesForShop(shop: Shop): RuleApiResponse[] {
        shop = shop || container.get(Shop);
        const productIds = shop.getProducts().map((product) => product.getId());
        const cartItemIds = shop.getCart().getItems().map((cartItem) => cartItem.getProductId());
        return RuleStorage.getLoadedRulesForProductIds([ ...productIds, ...cartItemIds ]);
    }

    static fetchRulesForProducts(products: Product[]): Promise<RuleApiResponse>[] {
        const productIds = products.map((product) => product.getId());
        return RuleStorage.fetchRulesForProductIds(productIds);
    }

    static fetchRulesForCartItems(cartItems: CartItem[]): Promise<RuleApiResponse>[] {
        const productIds = cartItems.map((cartItem) => cartItem.getProductId());
        return RuleStorage.fetchRulesForProductIds(productIds);
    }

    static getLoadedRulesForProductIds(productIds: string[]): RuleApiResponse[] {
        productIds = dedupe(productIds.map(normalizeIdentifier).filter(Boolean));
        const responses: RuleApiResponse[] = [];
        for (let i = 0; i < productIds.length; i++) {
            const pid = productIds[i];
            if (ruleResponseCache[pid] === undefined) {
                console.warn(`No rules loaded for product ${pid}. Can't syncronously get rules for new ids. Skipping.`);
            } else {
                responses.push(ruleResponseCache[pid]);
            }
        }
        return responses;
    }

    static makeFiltersForRulesetRequest() {
        const shop = container.get(Shop);
        const tags = shop.getCustomer().getTags();
        let filters = tags.map((tag) => encodeURIComponent(`eq(CUSTOMER_GROUP:${tag})`));

        // if store has multi-currency turned on
        if (config(SETTINGS.multi_currency)) {
            const defaultSettingCurrency = config(SETTINGS.multi_currency_default_currency);
            const overriddenCurrency = CurrencyStorage.fetchCurrencyData(shop);
            const currency = overriddenCurrency !== null && overriddenCurrency.currencyName
                ? overriddenCurrency.currencyName
                : defaultSettingCurrency != null
                    ? defaultSettingCurrency
                    : shop.getCurrency();
            const currencyFilter = encodeURIComponent(`eq(CURRENCY:${currency})`);
            filters = filters.concat(currencyFilter);
        }

        if (shop.getCustomer() && shop.getCustomer().id) {
            const idFilter = encodeURIComponent(`like(CUSTOMER_ID:${shop.getCustomer().id})`);
            filters = filters.concat(idFilter);
        }
        return filters;
    }

    /**
     * Returns an array of promises that resolve to RuleApiResponse
     */
    static fetchRulesForProductIds(productIds: string[]): Promise<RuleApiResponse>[] {
        productIds = dedupe(productIds.map(normalizeIdentifier).filter(Boolean));

        const newProdIds = [];
        const existingRequests = [];

        for (let i = 0; i < productIds.length; i++) {
            const pid = productIds[i];
            if (ruleRequestCache[pid] === undefined) {
                newProdIds.push(pid);
            } else {
                existingRequests.push(ruleRequestCache[pid]);
                ruleRequestCache[pid].then((r) => r.usedFromCache());
            }
        }

        /* develblock:start */
        newProdIds.length > 0 && Log.info(`Fetching rulesets for products...`, newProdIds);
        existingRequests.length > 0 && Log.info(`Used cached rulesets for products...`, existingRequests.length);
        /* develblock:end */

        const filters = this.makeFiltersForRulesetRequest();
        const newRequests = RuleApiClient.getRulesets(newProdIds, filters).map(({
            ids,
            request,
        }) => {
            ids.forEach((pid) => {
                ruleRequestCache[pid] = request;
                request.then((response) => { ruleResponseCache[pid] = response; });
            });

            return request;
        });

        return [
            ...existingRequests,
            ...newRequests,
        ];
    }
}

export default RuleStorage;
