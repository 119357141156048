
import BaseAction, { BaseActionParams } from './BaseAction';
import CartItem from '../platform/CartItem';
import { roundDecimalNumber } from '../../helpers/MathHelper';
import { getLimitItem, scopeValues } from '../../helpers/ActionHelper';
import PriceBreakdown from '../money/PriceBreakdown';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import Shop from '../platform/Shop';
import { ENV, SETTINGS, config } from '../../config';

interface Input {
    limit: number;
    limit_scope?: scopeValues;
}

class PriceAdjustAbsoluteWithLimitAction extends BaseAction {
    value: number;
    limit: number;
    limitScope: scopeValues;

    constructor({ limit, type, value, limit_scope = 'variant' }: BaseActionParams & Input) {
        super({ type });

        this.limit = limit;
        this.limitScope = limit_scope;
        this.value = value;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = price.amount();
        let value = this.value;

        if (variant.parent instanceof CartItem) {
            const cartItem = variant.parent;
            const state = shop.cart.getRuleState(PriceAdjustAbsoluteWithLimitAction);
            const limitItem = getLimitItem(state, cartItem, this.limit, this.limitScope);

            const available = limitItem.available;

            this.setBreakdown(variant, cartItem.original_price.amount(), this.value, available, cartItem.quantity);

            // If quantity is greater than the available quantity, calculate the average price per product
            if (cartItem.quantity > available) {
                // Calculate the total with discount, add it to the total price without discount and divide the result by the total quantity.
                const modifiedValueTotal = this.value * available;
                const regularValueTotal = cartItem.original_price.amount() * (cartItem.quantity - available);

                if (config(SETTINGS.disable_price_rounding)) {
                    value = (modifiedValueTotal + regularValueTotal) / cartItem.quantity;
                } else {
                    value = roundDecimalNumber((modifiedValueTotal + regularValueTotal) / cartItem.quantity, 0);
                }
            }

            limitItem.subtract(cartItem.quantity);
        }

        price.setAmount(value);

        variant.log(this.type, { price_adj: `${oldPrice} --> ${price.amount()}` });
    }

    setBreakdown(variant: Variant, original_price: number, adjusted_price: number, available: number, quantity: number) {
        const adjustedPriceQty = Math.min(available, quantity);
        const originalPriceQty = quantity - adjustedPriceQty;
        variant.addPriceBreakdown([
            new PriceBreakdown({ qty: adjustedPriceQty, price: adjusted_price }),
            new PriceBreakdown({ qty: originalPriceQty, price: original_price }),
        ]);
    }

    toJSON() {
        return {
            type: this.type,
            value: this.value,
            limit: this.limit,
            limit_scope: this.limitScope,
        };
    }
}

export default PriceAdjustAbsoluteWithLimitAction;
