import { getFormField, queryHTMLElements } from './dom';
import { normalizeIdentifier } from './identifier';
import { getAddToCartForm } from './shopify';

/**
 * Get the selected variant for the first
 * add to cart form on the page.
 */
export function getSelectedVariantId(): string|null {
    const addToCartForm = getAddToCartForm();
    if (addToCartForm instanceof HTMLFormElement) {
        const idEl = getFormField(addToCartForm, 'id');
        if (idEl) {
            return normalizeIdentifier(idEl.value);
        }
    }
    return null;
}

/**
 * Get the index of the currently selected variant
 * based on the single-option-selector.
 *
 * NOTE: This element only exists in some themes.
 */
export function getSingleOptionSelectorIndex(): number|null {
    let index = null;
    const results = queryHTMLElements(document, 'select.single-option-selector');
    const sos = results.length > 0 ? results[0] : null;
    if (sos instanceof HTMLSelectElement) {
        const options = queryHTMLElements(sos, 'option') as HTMLOptionElement[];
        options.forEach((option, i) => {
            if (option.value === sos.value) {
                index = i;
            }
        });
    }
    return index;
}
