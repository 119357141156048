
import { matches, hasClass, createElement, queryHTMLElements } from '../../../helpers/dom';
import { encodeGetParams } from '../../../helpers/browser';
import { GenericObject } from '../../../helpers/object';

export function submitFormWithData(form: HTMLFormElement, data: GenericObject, newAction: string|null = null) {
    if (newAction) {
        form.action = newAction;
    }

    // Append data as hidden inputs.
    Object.keys(data).forEach(k => {
        const v = typeof data[k] === 'string' ? data[k] : JSON.stringify(data[k]);
        form.appendChild(createElement('input', {
            type: 'hidden',
            name: k,
            value: v,
        }));
    });

    form.submit();
}

export function goToLinkWithData(link: HTMLElement, data: GenericObject, newHref: string|null = null) {
    const linkHref = link.getAttribute('href');
    const href = newHref || linkHref || '';
    const appendChar = href.indexOf('?') === -1 ? '?' : '&';
    const stringData = encodeGetParams(data);
    const fullHref = `${href}${appendChar}${stringData}`;
    link.setAttribute('href', fullHref);
    window.location.href = fullHref;
}

export function isCheckoutButton(target: HTMLElement): boolean {
    const buttonEle = target as HTMLInputElement|HTMLButtonElement;
    const action = buttonEle.form && buttonEle.form.getAttribute('action');
    const nameAttr = buttonEle.getAttribute('name');
    const hasVariantAttribute = buttonEle.getAttribute('data-variantid');
    if (action && !NON_CHECKOUT_ELEMENTS.find(class_name => hasClass(buttonEle, class_name)) && !hasVariantAttribute) {
        if (action.search(/^\/checkout\b/) !== -1) {
            return true;
        }

        if (nameAttr === 'checkout' && action.search(/\/cart\b/) !== -1) {
            return true;
        }

        const path = getPath(action);
        if (path.search(/^\/checkout\b/) !== -1) {
            return true;
        }

        if (nameAttr === 'checkout' && path.search(/\/cart\b/) !== -1) {
            return true;
        }
    }
    return false;
}

export function isCheckoutLink(ele: HTMLElement): boolean {
    return matches(ele, "[href*=checkout]:not([href*='/tools/checkout/front_end/login'])") &&
        !hasClass(ele, 'bold_clone');
}

export function isExpressCheckoutButton(ele: HTMLElement): boolean {
    return hasClass(ele, 'shopify-payment-button__button') && !hasClass(ele, 'bold_clone');
}

export function getExpressCheckoutButtons(): HTMLElement[] {
    return queryHTMLElements(document, '.shopify-payment-button__button');
}

export function unbind(ele: HTMLElement) {
    if (ele && ele.parentNode) {
        const copy = ele.cloneNode(true);
        ele.parentNode.replaceChild(copy, ele);
    }
}

export function addCSS(cssRule: string) {
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(cssRule));
    document.head.appendChild(styleElement);
}

/**
 * This approach to adding CSS should be used sparingly.
 * Most things that we would need to add CSS for make
 * sense to be in the theme as a part of bold-pr.liquid
 * so that they can be customized.
 */
export function addProgressBarCSS(color: string) {
    addCSS(`
        #nprogress { pointer-events: none; }
        #nprogress .bar {
            background: ${color};
            position: fixed; z-index:1031;
            left:0; top:0;
            width:100%; height:2px;
        }
        #nprogress .peg {
            display:block; opacity:1;
            position:absolute; right:0;
            width:100px; height:100%;
            box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
            transform: rotate(3deg) translate(0px, -4px);
        }
    `);
}

function getPath(url: string): string {
    const parser = document.createElement('a');
    parser.href = url;
    return parser.pathname;
}

const NON_CHECKOUT_ELEMENTS = [
    'tos_label',
    'tos_agree',
    'bold_clone',
    'ajax-cart__note',
];
