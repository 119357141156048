
import container from '../components/Container';
import { createPlatform } from '../components/PlatformFactory';
import { channel } from '../events';
import { PUBLIC_ERROR_MESSAGE } from '../constants';
import Log from '../helpers/Log';
import PlatformScanner from '../components/PlatformScanner';
import reducers from '../reducers/index';
import WindowApi from '../api/WindowApi';
import RuleProcessQueue from '../components/RuleProcessQueue';
import checkStorePreEnabled from '../components/shopPreEnabled';
import IPlatformFactory from '../models/platform/IPlatformFactory';
import IPlatformBinder from '../models/platform/IPlatformBinder';
import BOLD, { WBoldPRE, boldWindow } from '../helpers/windowBold';
import { GenericObject } from '../helpers/object';

/**
 * App
 * Main app class and entry point to the app.
 * Runs each main component
 * - PlatformFactory
 * - Binder
 * - RuleProcessor
 * - RuleStorage
 * - PlatformScanner
 */

/**
 * Boots and runs the hopper for the current page and provided
 * platform data.
 */
export async function run(platformBinder: IPlatformBinder, platformFactory: IPlatformFactory): Promise<void> {
    container.set('CONFIG', initialConfig(BOLD.pre!));

    /* develblock:start */
    Log.init();
    /* develblock:end */

    if (!setPlatformData()) {
        return;
    }

    if (!await shopEnabled()) {
        return;
    }

    try {
        runComponents(platformBinder, platformFactory);
    } catch (err: any) { // Errors that didn't get caught anywhere else
        if (process.env.NODE_ENV === 'development') {
            // throw the error to keep it visible for local dev.
            throw err;
        } else {
            console.warn(`${PUBLIC_ERROR_MESSAGE} ${err.message}`, ...(err.extra ? err.extra : []));
        }
    }
}

async function shopEnabled() {
    const pre = BOLD.pre as WBoldPRE;
    const platformData = container.getData('platform_data');
    const storePreEnabled = await checkStorePreEnabled(platformData.shop.permanent_domain);
    if (!storePreEnabled) {
        pre._api_rdy_prom_reject?.('Price Rules is not enabled for this store.'); // For api ready promise in `BOLD.pre.ready()`
        /* develblock:start */
        Log.error(new Error('Store not enabled, bailing.'));
        /* develblock:end */
        return false;
    }
    return true;
}

function setPlatformData() {
    const platformDataElement = document.getElementById('bold-platform-data');
    if (platformDataElement instanceof Element) {
        const platformData = JSON.parse(platformDataElement.innerHTML);
        container.set('platform_data', platformData);
        return true;
    }
    return false;
}

function runComponents(platformBinder: IPlatformBinder, platformFactory: IPlatformFactory) {
    const api = new WindowApi();
    container.set(WindowApi, api);

    // For api ready promise in `BOLD.pre.ready()`
    BOLD.pre?._api_rdy_prom_resolve?.(api);

    BOLD.pre = api;

    // bind reducers
    reducers.forEach(r => channel.addMiddleware(r));

    // Create the platform state.
    const platformData = container.getData('platform_data');
    const platform = createPlatform(platformBinder, platformFactory, platformData);
    const shop = platform.getShop();

    /* develblock:start */
    boldWindow.__PR_DEBUG = container.debug;
    boldWindow.__PR_DEBUG.shop = shop;
    boldWindow.__PR_DEBUG.customer = shop.customer;
    /* develblock:end */

    RuleProcessQueue.init();
    RuleProcessQueue.processShop();

    PlatformScanner.scan();
}

/**
 * Get initial config from window.
 */
function initialConfig(pre: WBoldPRE): GenericObject {
    const configObj = pre.config ?? {};
    const configArr = boldWindow.BOLD_pr_confarr?.filter((obj: any) => typeof obj === 'object') ?? [];
    const mergedConfigArr = Object.assign({}, ...configArr);

    return {
        ...configObj,
        ...mergedConfigArr,
    };
}
