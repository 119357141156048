
import BaseAction, { BaseActionParams } from './BaseAction';
import QuantityBreak from '../money/QuantityBreak';
import RuleStorage from '../../components/RuleStorage';
import RuleProcessor from '../../components/RuleProcessor';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import Shop from '../platform/Shop';

interface Input {
    qty: number;
    price?: number;
    percent?: number;
    saved?: number;
}

class DisplayQuantityBreakAction extends BaseAction {
    percent?: number;
    price?: number;
    qty: number;
    saved?: number;

    constructor({ type, qty, price, percent, saved }: BaseActionParams & Input) {
        super({ type });
        this.qty = qty;
        this.price = price;
        this.percent = percent;
        this.saved = saved;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = variant.price.original();
        const qtyActionPrice = this.price;
        const proposedPrice = this.resolveNewPrice(oldPrice, qtyActionPrice, this.percent, this.saved);
        const cartPrice = this.emulateCartPriceAtQuantity(variant.getId(), this.qty, shop);
        /* The quantity break price might not be the best option, so the lesser price is taken here for display at the grid. */
        const newPrice = (cartPrice != null && cartPrice < proposedPrice ? cartPrice : proposedPrice);

        variant.addQuantityBreak(new QuantityBreak({
            qty: this.qty,
            price: newPrice,
            percent: this.percent ? this.percent : this.getPercentFromPrices(oldPrice, newPrice),
            saved: this.saved ? this.saved : this.getSavedFromPrices(oldPrice, newPrice),
            proposed_price: proposedPrice,
        }));

        variant.log(this.type, { qty: this.qty, price: newPrice });
    }

    resolveNewPrice(oldPrice: number, qtyPrice: number|undefined, percent: number|undefined, saved: number|undefined) {
        if (qtyPrice) {
            return qtyPrice;
        }

        if (percent) {
            return this.getNewPriceFromPercent(oldPrice, percent);
        }

        if (saved) {
            return this.getNewPriceFromSaved(oldPrice, saved);
        }

        /* develblock:start */
        throw new Error('No price data available for quantity break.');
        /* develblock:end */
    }

    getNewPriceFromPercent(oldPrice: number, percent: number) {
        const newPrice = oldPrice - (oldPrice * (percent / 100));
        return Math.round(newPrice);
    }

    getNewPriceFromSaved(oldPrice: number, saved: number) {
        return oldPrice - saved;
    }

    getPercentFromPrices(oldPrice: number, newPrice: number) {
        const percent = (oldPrice - newPrice) / oldPrice * 100;
        return Math.round(percent * 100) / 100;
    }

    getSavedFromPrices(oldPrice: number, newPrice: number) {
        return oldPrice - newPrice;
    }

    emulateCartPriceAtQuantity(variantId: string, qty: number, shop: Shop): number | null {
        const product = shop.products.find((prod) => {
            return !!prod.variants.find((v) => v.id === variantId);
        });
        if (!product) {
            return null;
        }
        const variant = product.getVariantById(variantId) as Variant;
        const shopCopy = shop.makeCopyWithoutProducts();
        shopCopy.cart = shop.platform.Factory.createCartInstance({
            items: [
                {
                    quantity: qty,
                    variant_id: variantId,
                    price: variant.original_price.raw_amt,
                    product_id: product.id,
                },
            ],
        });
        const ruleApiResponses = RuleStorage.getLoadedRulesForShop(shopCopy);
        RuleProcessor.applyRulesSync(ruleApiResponses, shopCopy);
        if (shopCopy.cart.items.length > 0) {
            const { price } = shopCopy.cart.items[0];
            return price !== null ? price.amount() : null;
        }
        return null;
    }
}

export default DisplayQuantityBreakAction;
