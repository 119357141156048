
import { show as domShow } from '../../helpers/dom';
import { SETTINGS, config } from '../../config';
import MoneyTemplate from '../money/MoneyTemplate';
import PriceElementSet from './PriceElementSet';
import Money from '../money/Money';

/**
 * PriceElement
 */
class TemplateElement {
    domElement: HTMLElement;
    hasUpdated: boolean;
    lastMoneyDisplay: string | null;
    money: Money;
    observer: MutationObserver | null;
    priceElementSet: PriceElementSet;
    template: MoneyTemplate;
    constructor(domElement: HTMLElement, priceElementSet: PriceElementSet) {
        this.domElement = domElement;
        this.priceElementSet = priceElementSet;
        this.money = priceElementSet.money;
        this.hasUpdated = false;
        this.lastMoneyDisplay = null;

        const templateString = TemplateElement.prepareElementSourcedTemplate(domElement);

        this.template = new MoneyTemplate(templateString);

        this.observer = null;

        this.update();
    }

    /**
     * Process the template element's inner HTML.
     * - Remove {{money}}
     */
    static prepareElementSourcedTemplate(element: any): string {
        let templateContents;
        if (element.firstChild && element.firstChild.tagName === 'SCRIPT') {
            templateContents = element.firstChild.innerHTML;
        } else {
            templateContents = element.innerHTML;
        }

        return templateContents.trim().replace(/{{\s*money\s*}}/gi, '{{price}}');
    }

    shouldUpdate(): boolean {
        /** If we handled it once before we continue. */
        if (this.hasUpdated) {
            return true;
        }

        /** Handling all prices on? */
        if (config(SETTINGS.handle_all_prices)) {
            return true;
        }

        /** Only update the element if the price has changed. */
        return this.money.hasChanged();
    }

    /**
     * Update the current or provided dom element
     * with the latest price.
     */
    update() {
        if (this.money && this.shouldUpdate()) {
            if (!this.hasUpdated) {
                this.hasUpdated = true;
                /* develblock:start */
                this.domElement.style.background = 'rgba(50,205,184,.5)';
                /* develblock:end */
            }

            const display = this.template.render(this.money, this.priceElementSet.parent).before ?? '';

            this.domElement.innerHTML = display;
            this.lastMoneyDisplay = display;
        }
    }

    /**
     * Show the dom element if it's hidden with css.
     */
    show() {
        domShow(this.domElement);
    }

    purge() {
        // too lazy to make an abstract class or interface
    }
}

export default TemplateElement;
