
class LimitItem {
    _available: number;
    _id: string;
    _limit: number;
    /**
     * @param {string} id
     * @param {number} limit
     */
    constructor(id: string, limit: number) {
        this._id = id;
        this._limit = limit;
        this._available = limit;
    }

    get id() {
        return this._id;
    }

    get limit() {
        return this._limit;
    }

    get available() {
        return this._available;
    }

    add(quantity = 0) {
        this._available = this._available + quantity;
    }

    subtract(quantity = 1) {
        this._available = this._available - quantity > 0 ? this._available - quantity : 0;
    }

    reset() {
        this._available = this._limit;
    }
}

export default LimitItem;
