
import BaseConditionInput from './BaseCondition';
import CartItem from '../platform/CartItem';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';
import CartBaseCondition from './CartBaseCondition';

class QtyByMixAndMatchCondition extends CartBaseCondition {
    label: string;
    value: number;

    constructor({
        type,
        operator,
        value,
        label,
    }: BaseConditionInput & { label: string, value: number }) {
        super({ type, operator, requiresPrepare: true });

        this.value = value;
        this.label = label;
    }

    prepare(item: CartItem, shop: Shop) {
        const state = shop.cart.getRuleState(QtyByMixAndMatchCondition);
        const labels = this.getItemLabels(state, item);

        if (!this.hasConditionLabel(labels)) {
            labels.push(this.label);
        }

        state.set(item, labels);
    }

    continueEvaluating(variant: Variant, shop: Shop): boolean {
        if (!(variant.parent instanceof CartItem)) {
            return this.compare(1);
        }

        const state = shop.cart.getRuleState(QtyByMixAndMatchCondition);

        const totalQuantity = shop.cart.items.reduce((sum, item) => {
            const labels = this.getItemLabels(state, item);

            // If condition's label is equal to the current label
            if (this.hasConditionLabel(labels)) {
                sum += item.getQuantity();
            }

            return sum;
        }, 0);

        return this.compare(totalQuantity);
    }

    toJSON() {
        return {
            type: this.type,
            operator: this.operator,
            value: this.value,
            label: this.label,
        };
    }

    getItemLabels(state: Map<CartItem, string[]>, item: CartItem): string[] {
        return state.has(item) ? state.get(item)! : [];
    }

    hasConditionLabel(labels: string[]): boolean {
        return labels.includes(this.label);
    }
}

export default QtyByMixAndMatchCondition;
