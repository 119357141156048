
import BaseCartLevelDiscountAction from './BaseCartLevelDiscountAction';
import CartLevelDiscount, { RuleDiscountType } from '../money/CartLevelDiscount';
import Cart from '../platform/Cart';

class CartLevelDiscountRelativeAction extends BaseCartLevelDiscountAction {
    calculateCartDiscount(shopCart: Cart, discount: number): number {
        const cart_sub_total: number = shopCart.calculateSubTotalWithoutFees();
        const new_cart_sub_total: number = cart_sub_total - Math.abs(discount);

        // IF the applied discount is equal or lower than 0 it will not be applied
        if (new_cart_sub_total <= 0) {
            return cart_sub_total;
        }

        shopCart.addCartLevelDiscount(new CartLevelDiscount({
            discountValue: this.value,
            amount: this.value,
            name: this.name,
            ruleDiscountType: RuleDiscountType.MONEY,
        }), this.name);
        return new_cart_sub_total;
    }
}

export default CartLevelDiscountRelativeAction;
