
import Rule from '../Rule';

/**
 * This class attaches to a Variant and stores
 * data for whatever app has the currently applied
 * discount (not necesserily Product Discount)
 * for this variant.
 *
 * @class DiscountData
 */
class DiscountData {
    expiry?: string|null;
    layer_2_rule: Rule;
    message: string;
    source_app: string;

    constructor({
        message,
        source_app,
        expiry,
        layer_2_rule,
    }: { message: string, source_app: string, expiry?: string|null, layer_2_rule: Rule }) {
        this.message = message;
        this.source_app = source_app;
        this.expiry = expiry;
        this.layer_2_rule = layer_2_rule;
    }
}

export default DiscountData;
