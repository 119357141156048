
import SerialCartItem, { serializeCartItem } from './SerialCartItem';
import Cart from '../../models/platform/Cart';

export interface SerialCart {
    items: SerialCartItem[];
    sub_total: number;
}

export function serializeCart(cart: Cart): SerialCart {
    return {
        sub_total: cart.getSubTotal().amount(),
        items: cart.getItems().map(serializeCartItem),
    };
}
