
import { GenericObject } from './object';

export function getCookie(name: string): string|undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts[parts.length - 1].split(';').shift();
    }
    return undefined;
}

export function setCookie(name: string, val: string) {
    document.cookie = `${name}=${val}; path=/`;
}

export function removeCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function time(): number {
    return parseInt(`${Date.now() / 1000}`);
}

export function sleep(ms: number): Promise<void> {
    return new Promise(resolve => window.setTimeout(resolve, ms));
}

export function getPageOriginUrl(): string|string[]|null {
    if (window.location.origin === undefined) {
        // @TODO check this, it returns an array
        return window.location.href.match(/https?:\/\/[^/]+/gi);
    }
    return window.location.origin;
}

export function encodeGetParams(obj: GenericObject): string {
    return Object.keys(obj).map(k => {
        const v = typeof obj[k] === 'string' ? obj[k] : JSON.stringify(obj[k]);
        return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    }).join('&');
}

export function getParam(name: string) {
    const location = window.location.href;
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
