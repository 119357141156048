
import Log from '../helpers/Log';
import Variant from '../models/platform/Variant';
import { isPresent } from '../helpers/array';

interface VariantsByPriority {
    [priority: string]: Variant[];
}

interface VariantByPriority {
    [priority: string]: Variant;
}

interface VariantsByAppAndPriority {
    [app_slug: string]: VariantsByPriority;
}

interface VariantByAppAndPriority {
    [app_slug: string]: VariantByPriority;
}

interface VariantByApp {
    [app_slug: string]: Variant;
}

/**
 * BucketFilter
 */
class BucketFilter {
    static selectBucket(buckets: Variant[]) {
        const bucketsByAppAndPriority = BucketFilter.sortBucketsByAppAndPriority(buckets);

        /** Filter 1 : Lowest price per priority */
        const lowestPricePerPriority = Object.keys(bucketsByAppAndPriority).reduce((carry: VariantByAppAndPriority, app_slug: string): VariantByAppAndPriority => {
            carry[app_slug] = {};
            Object.keys(bucketsByAppAndPriority[app_slug]).forEach((priority: string) => {
                const bucketsForPriority = bucketsByAppAndPriority[app_slug][priority];
                const lowPriceBucket = BucketFilter.lowPrice(bucketsForPriority);
                if (lowPriceBucket) {
                    carry[app_slug][priority] = lowPriceBucket;
                }
            });

            return carry;
        }, {});

        /** Filter 2 : Highest priority */
        const highestPriorityPerApp = Object.keys(lowestPricePerPriority).reduce((carry: VariantByApp, app_slug): VariantByApp => {
            const bucketsForApp = Object.keys(lowestPricePerPriority[app_slug]).map(priority => {
                return lowestPricePerPriority[app_slug][priority];
            });

            const best = BucketFilter.highPriority(bucketsForApp.filter(isPresent));
            if (best) {
                carry[app_slug] = best;
            }

            return carry;
        }, {});

        /** Filter 3 : Lowest price per app */
        const flatAppBuckets = Object.keys(highestPriorityPerApp).map(app_slug => highestPriorityPerApp[app_slug]);

        return BucketFilter.lowPrice(flatAppBuckets);
    }

    static lowPrice(buckets: Variant[]): Variant | null {
        let chosenBucket = null;
        let lowestPrice = Number.MAX_SAFE_INTEGER;

        buckets.forEach((bucketVariant) => {
            try {
                bucketVariant.validate();
                const bucketVariantPrice = bucketVariant.getPrice().amount();
                if (bucketVariantPrice < lowestPrice) {
                    lowestPrice = bucketVariantPrice;
                    chosenBucket = bucketVariant;
                }
            } catch (e: any) {
                /* develblock:start */
                Log.warn(`Invalid bucket ignored: ${e.message}`, bucketVariant);
                /* develblock:end */
            }
        });

        return chosenBucket;
    }

    static highPriority(buckets: Variant[]): Variant | null {
        let chosenBucket = null;
        let highestPriority = Number.MAX_SAFE_INTEGER;
        buckets.forEach((bucketVariant: Variant) => {
            try {
                bucketVariant.validate();
                const bucketPriority = bucketVariant.ruleProcessorState.rule!.ruleset.priority;
                if (bucketPriority < highestPriority) {
                    highestPriority = bucketPriority;
                    chosenBucket = bucketVariant;
                }
            } catch (e: any) {
                /* develblock:start */
                Log.warn(`Invalid bucket ignored: ${e.message}`, bucketVariant);
                /* develblock:end */
            }
        });

        return chosenBucket;
    }

    static sortBucketsByAppAndPriority(buckets: Variant[]): VariantsByAppAndPriority {
        return buckets.reduce((carry: VariantsByAppAndPriority, bucket) => {
            const { priority, app_slug } = bucket.ruleProcessorState.rule!.ruleset;

            if (!carry[app_slug]) {
                carry[app_slug] = {};
            }
            if (!carry[app_slug][priority]) {
                carry[app_slug][priority] = [];
            }

            carry[app_slug][priority].push(bucket);

            return carry;
        }, {});
    }
}

export default BucketFilter;
