
import EventEmitter from 'eventemitter3';
import container from './Container';
import { GenericObject } from '../helpers/object';

const MIDDLEWARE_LIMIT = 15;

type Middleware = (eventName: string, data?: any) => void;

/**
 * EventChannel
 *
 * This class behaves like an eventemitter
 * with a couple of behaviour adjustments.
 *
 * 1. If an event is registered to *after* it is
 * emitted then the function will be called
 * immediately.
 *
 * 2. There is middleware!
 *
 * 3. emit() behaves just like EventEmitter.emit.
 *
 * 4. dispatch() is like emit but is asynchronous.
 */
class EventChannel {
    ee: EventEmitter;
    events: GenericObject;
    middlewares: Middleware[];
    constructor() {
        this.events = {};
        this.ee = new EventEmitter();
        this.middlewares = [];
    }

    emit(eventName: any, data: any = null) {
        this.events[eventName] = data;
        this.middlewares.forEach(fn => fn(eventName, data));
        /* develblock:start */
        container.debug.events.push({ e: eventName, ...data });
        /* develblock:end */
        this.ee.emit(eventName, data);
    }

    dispatch(eventName: string, data: any = null): Promise<null> {
        return new Promise(resolve => {
            window.setTimeout(() => {
                this.emit(eventName, data);
                resolve(null);
            }, 0);
        });
    }

    addMiddleware(middlewareFn: Middleware) {
        if (this.middlewares.length < MIDDLEWARE_LIMIT) {
            this.middlewares.push(middlewareFn);
        }
    }

    on(eventName: any, fn: any, context?: any) {
        if (this.events[eventName]) { // event already happened, just call it
            this.callFn(fn, this.events[eventName], context);
        }
        this.ee.on(eventName, fn, context);
    }

    once(eventName: any, fn: any, context: any) {
        if (this.events[eventName]) { // event already happened, just call it
            this.callFn(fn, this.events[eventName], context);
        } else {
            this.ee.once(eventName, fn, context);
        }
    }

    callFn(fn: any, data: any, context: any) {
        if (context) {
            fn.call(context, data);
        } else {
            fn(data);
        }
    }
}

export default EventChannel;
