
import BaseCustomElement from './BaseCustomElement';
import { getDataRaw, onDomReady } from '../../../helpers/dom';

class ProductQtyElement extends BaseCustomElement {
    productId: any;
    qty: any;
    qtyId: any;
    prep() {
        this.productId = getDataRaw(this.element, 'product-id');
        this.qtyId = getDataRaw(this.element, 'qty-id');
        this.qty = 1;
        this._bindQtyUpdates();
    }

    render() {
        const prod = this.api.getProductById(this.productId);
        if (!prod) {
            return null;
        }

        return this.api.formatAmount(prod.price * this.qty);
    }

    _bindQtyUpdates() {
        onDomReady().then(() => {
            const qtyEle = document.getElementById(this.qtyId);
            if (!qtyEle) {
                return;
            }
            qtyEle.addEventListener('change', (evt) => this._onQtyChange(evt.target));
            this._onQtyChange(qtyEle);
        });
    }

    _onQtyChange(input: any) {
        const qtyVal = parseInt(input.value);
        if (!isNaN(qtyVal)) {
            this.qty = qtyVal;
            this.update();
        }
    }
}

export default ProductQtyElement;
