import { SerialDiscount, serializeDiscount } from './SerialDiscount';
import { SerialFee, serializeFee } from './SerialFee';
import { serializeIdentifier } from '../../helpers/identifier';
import Variant from '../../models/platform/Variant';
import Product from '../../models/platform/Product';
import QuantityBreak from '../../models/money/QuantityBreak';
import PriceBreakdown from '../../models/money/PriceBreakdown';
import { nonEmptyArrayOrUndefined } from '../../helpers/array';

export interface SerialPriceBreakdown {
    price: number;
    qty: number;
}

export function serializePriceBreakdown(pb: PriceBreakdown): SerialPriceBreakdown {
    return {
        price: pb.price,
        qty: pb.qty,
    };
}

interface SerialQuantityBreak {
    percent: number;
    price: number;
    proposed_price: number;
    qty: number;
    saved: number;
}

export function serializeQuantityBreak(qb: QuantityBreak): SerialQuantityBreak {
    return {
        percent: qb.percent,
        price: qb.price,
        proposed_price: qb.proposed_price,
        qty: qb.qty,
        saved: qb.saved,
    };
}

interface SerialVariant {
    available: boolean;
    compare_at_price?: number;
    fees?: SerialFee[];
    grams?: number;
    id: number;
    image?: string | null;
    name?: string;
    original_price: number;
    price: number;
    price_breakdown?: SerialPriceBreakdown[];
    product_id: number;
    qty_breaks?: SerialQuantityBreak[];
    sku?: string;
    weight?: number;
    weight_unit?: string;
}

export interface SerialProduct {
    currentVariantId: number|null;
    discount: SerialDiscount|null;
    id: number|null;
    price: number;
    variants: SerialVariant[];
    message: string;
    expiry: string;
    handle: string;
    description?: string;
    vendor?: string;
    type?: string;
}

function serializeVariant(v: Variant): SerialVariant {
    const fees = nonEmptyArrayOrUndefined(v.fees, serializeFee);
    const price_breakdown = nonEmptyArrayOrUndefined(v.price_breakdown, serializePriceBreakdown);
    const qty_breaks = nonEmptyArrayOrUndefined(v.qty_breaks, serializeQuantityBreak);
    return {
        id: serializeIdentifier(v.id) as number,
        product_id: serializeIdentifier(v.product_id) as number,
        name: v.name,
        sku: v.sku,
        price: v.price.amount(),
        original_price: v.original_price.amount(),
        weight: v.weight,
        weight_unit: v.weight_unit,
        grams: v.grams,
        image: v.image,
        available: v.available,
        compare_at_price: v.compare_at_price,
        fees,
        price_breakdown,
        qty_breaks,
    };
}

export function serializeProduct(product: Product): SerialProduct {
    const variant = product.getVariant();
    const discount = serializeDiscount(variant);
    return {
        currentVariantId: serializeIdentifier(variant.id),
        discount,
        id: serializeIdentifier(product.id),
        price: variant.getPrice().amount(),
        variants: product.getVariants().map(serializeVariant),
        message: discount?.message ?? '',
        expiry: discount?.expiry ?? '',
        handle: product.getHandle(),
        description: product.getDescription(),
        vendor: product.getVendor(),
        type: product.getType(),
    };
}
