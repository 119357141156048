
import { channel } from '../events';
import EventEmitter from 'eventemitter3';

/**
 * EventBridge
 */
class EventBridge {
    publicist: EventEmitter;
    constructor(publicist: EventEmitter) {
        this.publicist = publicist;
    }

    /**
     * Publishes a local event to the public event emitter provided.
     */
    publish(localEvent: string, publicEvent: string) {
        channel.on(localEvent, (data: any) => this.publicist.emit(publicEvent, data), null);
    }

    /**
     * Allows the local system to listen for a public event ocurring on the event emitter provided.
     */
    listen(publicEvent: string, localEvent: string) {
        this.publicist.on(publicEvent, (data: any) => channel.dispatch(localEvent, data));
    }
}

export default EventBridge;
