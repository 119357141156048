
import BaseAction, { BaseActionParams } from './BaseAction';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import Shop from '../platform/Shop';
import CartItem from '../platform/CartItem';

interface Input {
    case_price: number;
    case_size: number;
}

class CasePricingAction extends BaseAction {
    casePrice: number;
    caseSize: number;

    constructor({ type, case_price, case_size }: BaseActionParams & Input) {
        super({ type });
        this.casePrice = case_price;
        this.caseSize = case_size;
    }

    toJSON() {
        return {
            type: this.type,
            case_price: this.casePrice,
            case_size: this.caseSize,
            value: null,
        };
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = price.amount();
        if ((variant.parent instanceof CartItem)) {
            const quantity = variant.parent.quantity;
            if (quantity >= this.caseSize) {
                const misfits = quantity % this.caseSize;
                const cases = (quantity - misfits) / this.caseSize;
                const lineTotal = (cases * this.casePrice) + (misfits * oldPrice);
                const newPrice = lineTotal / quantity;
                price.setAmount(Math.round(newPrice));
                variant.log(this.type, { price_adj: `${oldPrice} --> ${newPrice}` });
            }
        } else {
            return oldPrice;
        }
    }
}

export default CasePricingAction;
