import Client from './Client';
import OrderDataStorage from '../components/OrderDataStorage';
import { channel, EVENTS } from '../events';
import Shop from '../models/platform/Shop';
import { GenericObject } from '../helpers/object';

/**
 * Rule Storage
 *
 * This class fetches and sets OrderData using local storage
 */
class OrderConditionClient {
    static applyOrderConditions(shop: Shop, orderData: GenericObject) {
        const shopSchema = shop.toJSON();
        const payload = { ...shopSchema, ...orderData };
        Client.request('post', `storefront/v2/shops/${shop.getShopDomain()}/order_condition`,
            payload, 'application/json',
        ).then(response => {
            OrderDataStorage.storeOrderConditions(response.data.order_condition);
            shop.setOrderConditions();
            channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
        });
    }
}

export default OrderConditionClient;
