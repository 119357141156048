
import Money from '../money/Money';
import PriceElementSet from '../dom/PriceElementSet';
import { ENV, SETTINGS, config } from '../../config';
import { normalizeIdentifier } from '../../helpers/identifier';
import { randomString } from '../../helpers/string';
import { GenericObject } from '../../helpers/object';
import Variant from './Variant';
import Shop from './Shop';
import { Properties } from './ShopifyCart';

export interface FactoryCartItemInput {
    variant_id: number | string;
    variation_id?: number; // Woo
    product_id: number | string;
    title?: string;
    variant_title?: string;
    sku?: string;
    price: number;
    grams?: number;
    image?: string;
    key?: string;
    handle?: string;
    quantity: number;
    line_price?: number;
    properties?: GenericObject;
    product?: GenericObject;
    line_item_key?: string;
    subscriptionGroupId?: number;
    subscriptionIntervalId?: number;
}

export interface CartItemInput {
    variant: Variant;
    id?: string;
    index: number;
    handle?: string;
    variant_id: string|number;
    product_id: string|number;
    quantity: number;
    properties?: GenericObject;
    raw_line_price: number;
    subscriptionGroupId?: number;
    subscriptionIntervalId?: number;

}

class CartItem {
    handle?: string;
    id: string;
    index: number;
    linePriceElementSet: PriceElementSet;
    line_price: Money;
    original_line_price: Money;
    original_price: Money;
    price: Money;
    priceElementSet: PriceElementSet;
    product_id: string;
    properties?: Properties;
    quantity: number;
    variant: Variant;
    variant_id: string;
    subscriptionGroupId?: number;
    subscriptionIntervalId?: number;

    constructor({
        variant,
        id,
        index,
        handle,
        variant_id,
        product_id,
        quantity = 1,
        properties = {},
        raw_line_price,
        subscriptionGroupId,
        subscriptionIntervalId,
    }: CartItemInput) {
        this.variant = variant;
        this.variant_id = normalizeIdentifier(variant_id) as string;
        this.id = id || randomString();
        this.index = index;
        this.handle = handle;
        this.product_id = normalizeIdentifier(product_id) as string;
        this.quantity = quantity;
        this.properties = properties;

        /** Derived/other cart item data that shouldn't be passed in when constructing: */
        this.price = variant.getPrice();
        this.line_price = new Money(this.price.amount() * this.quantity, raw_line_price);
        this.original_price = new Money(this.price.amount(), null);
        this.original_line_price = new Money(this.price.amount() * this.quantity, raw_line_price);

        /** Element sets */
        this.priceElementSet = new PriceElementSet('cart_line_price', this, [], this.price, config(SETTINGS.template_cart_item));
        this.linePriceElementSet = new PriceElementSet('cart_line_total', this, [], this.line_price);
        this.subscriptionGroupId = subscriptionGroupId;
        this.subscriptionIntervalId = subscriptionIntervalId;

        this.variant.setParent(this);

        if (ENV.BROWSER) {
            this.bindPriceEvents();
        }
    }

    bindPriceEvents() {
        /** Update the line price when the price changes. */
        this.price.ee.on('change', () => this.line_price.setAmount(this.price.amount() * this.quantity));
    }

    reset() {
        this.variant.reset();
    }

    toJSON() {
        return {
            index: this.index,
            variant_id: this.variant_id,
            product_id: this.product_id,
            price: this.price.amount(),
            original_price: this.original_price.amount(),
            line_price: this.line_price.amount(),
            quantity: this.quantity,
            properties: this.properties,
            fees: this.variant.getFees(),
            meta: this.variant.getMeta(),
            logs: this.variant.getLogs(),
            subscriptionGroupId: this.subscriptionGroupId,
            subscriptionIntervalId: this.subscriptionIntervalId,
        };
    }

    getVariant() {
        return this.variant;
    }

    getProductId() {
        return this.product_id;
    }

    getId() {
        return this.id;
    }

    getVariantId() {
        return this.variant_id;
    }

    getPrice() {
        return this.price;
    }

    getLinePrice() {
        return this.line_price;
    }

    getOriginalLinePrice() {
        return this.original_line_price;
    }

    setQuantity(quantity: number) {
        this.quantity = quantity;
    }

    getQuantity() {
        return this.quantity;
    }

    getProperty(name: string) {
        return this.properties ? this.properties[name] : null;
    }

    /**
     * Adds a new price element for this cart item.
     * It should update any time the price changes.
     */
    addPriceElement(domElement: HTMLElement) {
        this.priceElementSet.push(domElement);
    }

    processingFinished(shop: Shop) {
        this.priceElementSet.showAll();
        this.linePriceElementSet.showAll();
        shop.cart.subTotalPriceElementSet.showAll();
    }

    /**
     * Adds a new line price element for this cart item.
     * It should update any time the cart item line price changes.
     */
    addLinePriceElement(domElement: HTMLElement) {
        this.linePriceElementSet.push(domElement);
    }

    purge() {
        this.linePriceElementSet.purge();
        this.priceElementSet.purge();
    }

    update() {
        this.linePriceElementSet.updateElements();
        this.priceElementSet.updateElements();
    }
}

export default CartItem;
