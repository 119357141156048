
import Client from '../api/Client';

const SESSION_STORAGE_KEY_ENABLED = 'BOLD_SHOP_PRE_ENABLED';
const SESSION_STORAGE_KEY_ENABLED_TIMESTAMP = 'BOLD_SHOP_PRE_ENABLED_TIMESTAMP';
const SESSION_STORAGE_FALSE_VALUE = 'false';
const cacheMaxAge = 60 * 60 * 1000; // 60 minutes

export default async function (shopDomain: string): Promise<boolean> {
    const cachedPreEnabled = sessionStorage.getItem(SESSION_STORAGE_KEY_ENABLED) !== SESSION_STORAGE_FALSE_VALUE;
    const cachedPreEnabledTimestamp = parseInt(sessionStorage.getItem(SESSION_STORAGE_KEY_ENABLED_TIMESTAMP) ?? '0');
    const now = (+new Date());
    const cacheFound = !isNaN(cachedPreEnabledTimestamp);
    const cacheValid = cacheFound && now - cachedPreEnabledTimestamp <= cacheMaxAge;

    if (cacheValid) {
        return cachedPreEnabled;
    }

    let livePreEnabled;
    try {
        livePreEnabled = await Client.request('GET', `${shopDomain}/enabled`);
    } catch {
        livePreEnabled = true; // assume true on error
    }

    sessionStorage.setItem(SESSION_STORAGE_KEY_ENABLED, livePreEnabled ? 'true' : SESSION_STORAGE_FALSE_VALUE);
    sessionStorage.setItem(SESSION_STORAGE_KEY_ENABLED_TIMESTAMP, `${now}`);

    return livePreEnabled;
}
