
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop, { SubscriptionParamsStorage } from '../platform/Shop';
import Product from '../platform/Product';
import { config } from '../../config';

class SubscribeGroupCondition extends BaseCondition {
    value: number;

    constructor({
        value,
        ...others
    }: BaseConditionInput & { value: number }) {
        super(others);
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        let result = false;
        if (this.hasStaplesSubsFeatureFlag()) {
            if (variant.parent instanceof Product && shop.isSubscriptionSelected()) {
                // eslint-disable-next-line eqeqeq
                result = (variant.parent.getSubscriptionGroupId() == this.value);
            } else if (variant.parent instanceof Product && shop.isSubscriptionSelected() === false) {
                return false;
            } else if (shop.subscriptions_params != null) {
                shop.getSubscriptionParams()?.forEach((item: SubscriptionParamsStorage) => {
                    if (item.product_id === variant.getProductId()) {
                        result = true;
                    }
                });
            }
        } else {
            let subsParams = null;
            if (shop.cart_params != null && shop.cart_params.get != null) {
                subsParams = shop.cart_params.get('subsData');
            }
            if (subsParams != null) {
                subsParams.forEach((param: any) => {
                    if (param.subscription_group_id && param.line_item_key) {
                        result = (param.subscription_group_id === this.value && param.line_item_key === variant?.parent?.id);
                    }
                });
            }
        }
        return result;
    }

    hasStaplesSubsFeatureFlag(): boolean {
        return config('staples_subs_ff');
    }
}

export default SubscribeGroupCondition;
