
import ElementCache from './ElementCache';
import container from './Container';
import { getDataRaw, getDataNumber } from '../helpers/dom';
import { EVENTS, channel } from '../events';
import Platform from '../models/platform/Platform';

const goodElementCache = new ElementCache();
const badElementCache = new ElementCache();

interface Input {
    element: HTMLElement;
    lineIndex: number;
    lineKey: string;
}

function getCart() {
    const platform = container.get(Platform);
    return platform.getShop().getCart();
}

function cachesContain(element: HTMLElement): boolean {
    return goodElementCache.contains(element) || badElementCache.contains(element);
}

class CartElements {
    static elementToLineIndex(element: HTMLElement): number | null {
        const cartLineIndex0 = getDataNumber(element, 'line-index');
        const cartLineIndex1 = getDataNumber(element, 'line-index1');
        if (cartLineIndex0 === null && cartLineIndex1 === null) {
            return null;
        }
        return cartLineIndex0 !== null ? cartLineIndex0 : (cartLineIndex1 as number) - 1;
    }

    static identify(element: HTMLElement): boolean {
        if (goodElementCache.contains(element)) {
            return true;
        }
        if (badElementCache.contains(element)) {
            return false;
        }
        const cartItemKey = element.getAttribute('data-item-key');
        const cartLineIndex = this.elementToLineIndex(element);
        const lineTotal = getDataRaw(element, 'line-total');
        const cartTotal = getDataRaw(element, 'cart-total');

        const isCartElement = cartItemKey !== null ||
            cartLineIndex !== null ||
            lineTotal !== null ||
            cartTotal !== null;

        if (!isCartElement) {
            badElementCache.save(element);
            return this.identify(element);
        }

        if (lineTotal !== null && (cartLineIndex !== null || cartItemKey !== null)) { // Line total
            channel.dispatch(EVENTS.NEW_ELEMENT_LINE_ITEM_TOTAL, { element, lineIndex: cartLineIndex, lineKey: cartItemKey });
        } else if (cartTotal !== null) { // Cart total
            channel.dispatch(EVENTS.NEW_ELEMENT_SUBTOTAL, { element });
        } else { // Line price
            channel.dispatch(EVENTS.NEW_ELEMENT_LINE_ITEM_PRICE, { element, lineIndex: cartLineIndex, lineKey: cartItemKey });
        }

        return true;
    }

    static addLineItemPriceElement({ element, lineIndex, lineKey }: Input) {
        if (cachesContain(element)) {
            return;
        }

        const cart = getCart();

        const foundByKey = cart.items.filter((item: any) => {
            return item.id === lineKey;
        });

        if (cart.items && cart.items[lineIndex] === undefined && foundByKey.length === 0) {
            console.warn(`Invalid line index or key on line price element.`, lineIndex, element);
            return;
        }
        if (foundByKey.length > 0) {
            foundByKey[0].addPriceElement(element);
        } else {
            cart.items[lineIndex].addPriceElement(element);
        }
        goodElementCache.save(element);
    }

    static addLineItemTotalPriceElement({ element, lineIndex, lineKey }: Input) {
        if (cachesContain(element)) {
            return;
        }

        const cart = getCart();
        const foundByKey = cart.items.filter((item: any) => {
            return item.id === lineKey;
        });

        if (cart.items && cart.items[lineIndex] === undefined && foundByKey.length === 0) {
            console.warn(`Invalid line index on line total element.`, lineIndex, element);
            return;
        }

        if (foundByKey.length > 0) {
            foundByKey[0].addLinePriceElement(element);
        } else {
            cart.items[lineIndex].addLinePriceElement(element);
        }

        goodElementCache.save(element);
    }

    static addSubTotalPriceElement({ element }: { element: HTMLElement }) {
        if (cachesContain(element)) {
            return;
        }

        const cart = getCart();

        cart.addSubtotalPriceElement(element);

        goodElementCache.save(element);
    }

    /**
     * This is called when currency changes
     */
    static updateElements() {
        getCart().updateElements();
    }

    static purge() {
        goodElementCache.clear();
        badElementCache.clear();
        getCart().purge();
    }
}

export default CartElements;
