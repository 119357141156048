
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class OrderConditionNameValuePairCondition extends BaseCondition {
    name: string;
    value: string;

    constructor({
        name,
        value,
        ...others
    }: BaseConditionInput & { name: string, value: string }) {
        super(others);
        this.name = name;
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        return shop.hasConditionName(this.name, this.value);
    }
}

export default OrderConditionNameValuePairCondition;
