
import { fallbacks, GenericObject } from '../../helpers/object';
import Shop from '../../models/platform/Shop';
import Customer from '../../models/platform/Customer';
import Product from '../../models/platform/Product';
import Variant from '../../models/platform/Variant';
import Cart, { CartInputRaw } from '../../models/platform/Cart';
import CartItem, { CartItemInput, FactoryCartItemInput } from '../../models/platform/CartItem';
import { isShopifyMCActive, reverseShopifyMC } from '../../helpers/shopify';
import { ENV, SETTINGS, config } from '../../config';
import { getCookie } from '../../helpers/browser';
import IPlatformFactory from '../../models/platform/IPlatformFactory';
import OrderDataStorage from '../OrderDataStorage';

const compareAtPriceTable: { [variant: string]: number } = {};

/**
 * ShopifyFactory
 * Module to convert shop specific data into the PRE standard discount subject format
 */
const ShopifyFactory: IPlatformFactory = {

    createShop(platformData: GenericObject): Shop {
        const customer = ShopifyFactory.createCustomerInstance(platformData.customer);
        const singleProduct = platformData.product ? ShopifyFactory.createProductInstance(platformData.product) : null;
        const collectionProducts = platformData.collection ? ShopifyFactory.createProductInstances(platformData.collection) : [];
        const lineItemProducts = platformData.line_item_products ? ShopifyFactory.createProductInstances(platformData.line_item_products) : [];
        const collectionLineItemProducts = ShopifyFactory.mergeProductArraysAndDedupe(collectionProducts, lineItemProducts);
        const products = singleProduct !== null ? ShopifyFactory.mergeProductArraysAndDedupe([singleProduct], collectionLineItemProducts) : collectionLineItemProducts;
        const cart = ShopifyFactory.createCartInstance(platformData.cart);

        if (platformData.shop === undefined) {
            platformData.shop = {};
        }

        return new Shop({
            shop_domain: fallbacks(
                platformData.shop.permanent_domain,
                platformData.shop_domain,
                platformData.shop.domain,
            ),
            custom_domain: fallbacks(
                platformData.shop_domain,
                platformData.shop.permanent_domain,
            ),
            customer,
            cart,
            products,
            page: platformData.template,
            currency: platformData.shop.currency,
            currency_symbol: platformData.shop.money_format ? platformData.shop.money_format.replace(/[a-z\s{}]*/gi, '') : null,
            money_format: platformData.shop.money_format,
            order_conditions: (platformData.order_condition != null) ? new Map(Object.entries(platformData.order_condition)) : null,
            cart_params: (platformData.cart_params != null) ? JSON.parse(platformData.cart_params, OrderDataStorage.cart_params_reviver) : null,
            source: (platformData.source != null) ? platformData.source : null,
            product_collections: platformData.product_collections || {},
            is_calling_for_cart: platformData.is_calling_for_cart === true,
            skip_clearing_subs_cache: false,
        });
    },

    createCustomerInstance(customer: GenericObject): Customer {
        if (!customer) {
            return new Customer({});
        }

        if (!customer.province && ENV.BROWSER) {
            customer.province = getCookie('customer_province');
        }

        return new Customer({
            id: customer.id,
            tags: customer.tags,
            province: customer.province,
            taxable: !customer.tax_exempt,
            bold_id: customer.bold_id,
        });
    },

    createProductInstances(products: GenericObject[]): Product[] {
        return products.map((product) => ShopifyFactory.createProductInstance(product));
    },

    createProductInstance(product: GenericObject): Product {
        return new Product({
            id: product.id,
            handle: product.handle,
            variants: product.variants?.map((variant: any) => ShopifyFactory.createVariantInstance(variant, product.id)),
        });
    },

    createVariantInstance(variant: GenericObject, parentProductId: string): Variant {
        compareAtPriceTable[variant.id] = variant.compare_at_price;
        const price = ShopifyFactory.preparePrice(variant, variant.id);
        return new Variant({
            id: variant.id,
            product_id: parentProductId,
            price,
            original_price: price,
            compare_at_price: variant.compare_at_price,
            raw_price: variant.price,
        });
    },

    createCartInstance({ token, items, note, attributes, total_price, total_discount, discount_code }: CartInputRaw): Cart {
        const cartItems = items.map((item, index) => {
            return ShopifyFactory.createCartItemInstance(item, index);
        });
        return new Cart({
            token,
            items: cartItems,
            note,
            attributes,
            total_price,
            total_discount,
            discount_code,
        });
    },

    createCartItemInstance(item: FactoryCartItemInput, index: number): CartItem {
        const price = ShopifyFactory.preparePrice(item, item.variant_id);
        const line_price = typeof item.line_price === 'number' ? item.line_price : item.price * item.quantity;
        const subscriptionIntervalId = config('staples_subs_ff')
            ? item.properties?._interval_id
            : undefined;
        return new CartItem({
            variant: new Variant({
                id: item.variant_id,
                product_id: item.product_id,
                name: `${item.title} ${item.variant_title}`,
                sku: item.sku,
                price,
                original_price: price,
                raw_price: item.price,
                grams: item.grams,
                image: item.image,
            }),
            id: item.key ? item.key : item.line_item_key,
            index,
            handle: item.handle,
            quantity: item.quantity,
            variant_id: item.variant_id,
            product_id: item.product_id,
            properties: item.properties,
            raw_line_price: line_price,
            subscriptionIntervalId,
        });
    },

    preparePrice(itemOrVariant: { price: number, compare_at_price: number }, variant_id: string|number): number {
        const startingPrice = ShopifyFactory.getStartingPrice(itemOrVariant, variant_id);
        return Math.round(startingPrice);
    },

    getStartingPrice({ price, compare_at_price }: { price: number, compare_at_price: number }, variant_id: string|number): number {
        if (config(SETTINGS.compare_at_price_as_original)) {
            if (ENV.NODE && compare_at_price > 0) {
                return compare_at_price;
            }

            if (compareAtPriceTable[variant_id]) {
                return compareAtPriceTable[variant_id];
            }
        }

        if (ENV.BROWSER && isShopifyMCActive()) {
            return reverseShopifyMC(price);
        }

        return price;
    },

    addRawProductToShop(rawProd: GenericObject, shop: Shop): Product {
        const existingProduct = shop.getProductById(rawProd.id);
        if (existingProduct) {
            console.warn(`Product already exists in shop. Using existing product.`);
            return existingProduct;
        }

        const prod = ShopifyFactory.createProductInstance(rawProd);
        shop.products.push(prod);

        return prod;
    },

    mergeProductArraysAndDedupe(productList1: Product[], productList2: Product[]): Product[] {
        const newList: Product[] = [];

        productList1.forEach((product1) => {
            newList.push(product1);
        });

        productList2.forEach((product2) => {
            const product = productList1.find((product1) => {
                return product2.id === product1.id;
            });

            if (!product) {
                newList.push(product2);
            }
        });

        return newList;
    },

    async refreshShopCart(shop: Shop) {
        const rawCart = await fetch('/cart.js', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json());

        ShopifyFactory.replaceShopCart(shop, rawCart);
    },

    replaceShopCart(shop: Shop, rawCart: CartInputRaw): void {
        shop.cart.destroy();
        shop.cart = ShopifyFactory.createCartInstance(rawCart);
    },
};

export default ShopifyFactory;
