
import { getCookie } from '../../../helpers/browser';
import Log from '../../../helpers/Log';
import { getAddToCartForm } from '../../../helpers/shopify';
import { createElement, insertAfter, getFormField } from '../../../helpers/dom';
import { SETTINGS, config } from '../../../config';
import { BASE_URLS } from '../../../constants';
import container from '../../../components/Container';
import { hasRefUrlCookie, REF_URL_COOKIE_KEY, REF_URL_COOKIE_VAL } from '../../../models/conditions/RefUrlCondition';
import nprogress from 'nprogress';
import unbindExpressCheckouts from './ExpressCheckoutUnbind';
import * as helpers from './helpers';
import formSerialize from 'form-serialize';
import { normalizeIdentifier } from '../../../helpers/identifier';
import { GenericObject } from '../../../helpers/object';
import Customer from '../../../models/platform/Customer';
import { CartItemInput } from '../../../models/platform/CartItem';
import { CartInputRaw } from '../../../models/platform/Cart';
import { boldWindow } from '../../../helpers/windowBold';
import ShopifyDiscountCodeStorage from '../../../components/ShopifyDiscountCodeStorage';

const PROGRESS_BAR_TIMEOUT = 1500;

const TYPE_CHECKOUT_BUTTON = 'btn';
const TYPE_CHECKOUT_LINK = 'link';
const TYPE_EXPRESS_BUTTON = 'exprsbtn';
const TYPE_SPONTANEOUS = 'sp';

class DraftOrderCheckoutLoader {
    checkoutInProgress: boolean;
    customer: Customer;
    domain: string;
    injectedProgressBarCss: boolean;
    isEnabledFn: () => boolean;
    orderData: GenericObject|null;
    progressBarTimeout!: number;
    source: string|null;

    constructor(domain: string, customer: Customer, isEnabledFn: () => boolean, orderData: GenericObject|null, source: string|null) {
        this.domain = domain;
        this.customer = customer;
        this.isEnabledFn = isEnabledFn;
        this.checkoutInProgress = false;
        this.injectedProgressBarCss = false;
        this.orderData = orderData;
        this.source = source;
        window.addEventListener('pageshow', (event) => {
            this.checkoutInProgress = false;
        });
    }

    /**
     * Bind event listener to all click event in the page.
     * Also overrides initial event listeners.
     */
    init() {
        document.addEventListener('click', (event) => this.handleCheckoutClick(event), false);
        unbindExpressCheckouts();
        nprogress.configure({ showSpinner: false });
        /* develblock:start */
        Log.debug('Initialized checkout loader / login handler.');
        /* develblock:end */
    }

    isEnabled() {
        return this.isEnabledFn();
    }

    /**
     * Redirect to checkout page only if it's on cart page
     * Buy it now button won't work if it gets hooked up to this
     */
    static exitToStandardCheckout() {
        /* develblock:start */
        Log.debug('Backing out to standard checkout');
        /* develblock:end */
        if (boldWindow.Shopify && boldWindow.Shopify.locale) {
            window.location.href = `/checkout?locale=${boldWindow.Shopify.locale}`;
        } else {
            window.location.href = '/checkout';
        }
    }

    static getTargetType(target: HTMLElement): 'btn'|'link'|'exprsbtn'|null {
        if (helpers.isCheckoutButton(target)) {
            return TYPE_CHECKOUT_BUTTON;
        }
        if (helpers.isCheckoutLink(target)) {
            return TYPE_CHECKOUT_LINK;
        }
        if (helpers.isExpressCheckoutButton(target)) {
            return TYPE_EXPRESS_BUTTON;
        }
        return null;
    }

    /**
     * Handle a click that could be on anything.
     */
    handleCheckoutClick(event: Event) {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        const targetType = DraftOrderCheckoutLoader.getTargetType(event.target);

        if (!targetType) {
            // This is just any random click on the page.
            return;
        }

        if (!this.isEnabled()) {
            // PRE checkout loader is not enabled.
            return;
        }

        /* develblock:start */
        Log.debug('Detected checkout click.', { targetType });
        /* develblock:end */

        // Stop regular action.
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        // Do our checkout instead.
        this.checkout(event.target, targetType);
    }

    checkout(target: HTMLElement, targetType: 'btn'|'link'|'exprsbtn') {
        if (!this.checkoutInProgress) {
            this.checkoutInProgress = true;
            this.reportLoading(target, targetType);

            this.getCheckoutData(target).then((checkoutData) => {
                if (checkoutData === null) {
                    // We should only get here when targetType is TYPE_EXPRESS_BUTTON and
                    // no add to cart form was found.
                    if (target instanceof HTMLAnchorElement) {
                        window.location.href = target.href;
                    }
                    this.checkoutInProgress = false;
                    return;
                }

                const { url, data } = checkoutData;

                if (config(SETTINGS.checkout_submit_style) === 'async') {
                    return this.asyncCheckout({ url, data }, targetType);
                }

                // Standard form/link hijack checkout.
                switch (targetType) {
                    case TYPE_CHECKOUT_BUTTON:
                        const typedTarget = target as HTMLInputElement|HTMLButtonElement;
                        typedTarget.form && helpers.submitFormWithData(typedTarget.form, data, url);
                        break;
                    case TYPE_CHECKOUT_LINK:
                        helpers.goToLinkWithData(target, data, url);
                        break;
                    case TYPE_EXPRESS_BUTTON:
                        return this.asyncCheckout({ url, data }, targetType);
                }
            }).catch(err => {
                /* develblock:start */
                throw err; // Don't hide the error in dev mode.
                /* develblock:end */
                // eslint-disable-next-line no-unreachable
                if (process.env.NODE_ENV !== 'development') { // NOSONAR
                    console.error(err);
                    DraftOrderCheckoutLoader.exitToStandardCheckout();
                }
            }).finally(() => {
                this.reportDoneLoading(target, targetType);
            });
        } else {
            /* develblock:start */
            Log.debug(`Still busy checking out...`);
            /* develblock:end */
        }
    }

    reportLoading(target: HTMLElement, targetType: string) {
        if (targetType === TYPE_CHECKOUT_BUTTON || targetType === TYPE_EXPRESS_BUTTON) {
            const typedTarget = target as HTMLButtonElement;
            typedTarget.disabled = true;
        }
        if (config(SETTINGS.async_checkout_bar_enabled)) {
            this.progressBarTimeout = window.setTimeout(() => {
                if (!this.injectedProgressBarCss) {
                    this.injectedProgressBarCss = true;
                    helpers.addProgressBarCSS(config(SETTINGS.async_checkout_bar_color));
                }
                nprogress.start();
            }, PROGRESS_BAR_TIMEOUT);
        }
    }

    reportDoneLoading(target: HTMLElement, targetType: string) {
        if (targetType === TYPE_CHECKOUT_BUTTON || targetType === TYPE_EXPRESS_BUTTON) {
            const typedTarget = target as HTMLButtonElement;
            typedTarget.disabled = false;
        }
        if (config(SETTINGS.async_checkout_bar_enabled)) {
            window.clearTimeout(this.progressBarTimeout);
            nprogress.done();
        }
    }

    /**
     * Fetch the checkout and then redirect.
     * This handles "buy it now" button and "checkout out" button
     * on cart page.
     */
    async asyncCheckout({ url, data }: { url: string, data: GenericObject }, targetType: string) {
        data.json = ''; // Ask for a json response.

        /* develblock:start */
        Log.startTimer('asc');
        /* develblock:end */

        // If it is buy it now button, we allow no price adjustment when hitting checkout endpoint.
        // That means buy it now button always go to draft order.
        if (targetType === TYPE_EXPRESS_BUTTON) {
            data.allow_no_adjust = 1;
        }

        const res = await fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(data),
        });
        const checkout = await res.json();

        /* develblock:start */
        const time = Log.stopTimer('asc');
        Log.debug(`Checkout response in ${time}ms`, checkout);
        /* develblock:end */

        // if backend returns valid json response, redirect to the draft order url
        // if backend returns error json respones with status code 218. Go to standard checkout
        if (res.status >= 200 && res.status < 300 && !checkout.error) {
            /* develblock:start */
            Log.debug(`Async checkout success -- redirecting to ${checkout.url}`);
            /* develblock:end */

            if (config(SETTINGS.async_checkout_test_mode)) {
                /* develblock:start */
                const checkoutButton = document.getElementsByName('checkout')[0] as HTMLButtonElement;
                checkoutButton.value = `${time}ms`;
                insertAfter(createElement('a', { href: checkout.url, text: checkout.url }), checkoutButton);
                /* develblock:end */
            } else {
                window.location = checkout.url;
            }
        } else if (checkout.error === 'NO_PRICE_ADJUSTMENT_REQUIRED') {
            /* develblock:start */
            Log.debug(`Async checkout success however no price adjustment required -- backing out.`);
            /* develblock:end */
            DraftOrderCheckoutLoader.exitToStandardCheckout();
        } else {
            /* develblock:start */
            Log.debug(`Error while creating checkout: ${checkout.message}`);
            /* develblock:end */
            DraftOrderCheckoutLoader.exitToStandardCheckout();
        }
    }

    /**
     * Gather data needed to initiate a checkout.
     */
    async getCheckoutData(target: HTMLElement|null = null): Promise<{url: string, data: {domain: string}}|null> {
        const cartPayload = await this.getCartPayload(target);

        if (cartPayload === null) {
            /* develblock:start */
            Log.debug('Generated empty payload, aborting checkout');
            container.debug.last_checkout = null;
            /* develblock:end */
            return null;
        }

        type CheckoutData = {
            domain: string,
            [REF_URL_COOKIE_KEY]?: string,
            customer_id?: number,
            json?: any,
            order_data?: GenericObject,
            source?: string,
            shopify_discount_codes?: string,
        }

        const shopifyDiscountCodeRulesets = ShopifyDiscountCodeStorage.fetchShopifyDiscountCodeData(this.domain);
        const shopifyDiscountCodes = shopifyDiscountCodeRulesets.length > 0
            ? shopifyDiscountCodeRulesets.map((x: any) => x.title).join(',')
            : null;

        const checkout = {
            url: `${BASE_URLS.PHP_API}/${this.domain}/checkout`,
            data: {
                ...cartPayload,
                domain: this.domain,
                shopify_discount_codes: shopifyDiscountCodes,
            } as CheckoutData,
        };

        const customerId = this.customer.getId();
        if (customerId) {
            checkout.data.customer_id = customerId;
        }

        // Referrer url condition cookie.
        if (hasRefUrlCookie()) {
            checkout.data[REF_URL_COOKIE_KEY] = REF_URL_COOKIE_VAL;
        }

        // Redirect to json page output for this checkout.
        if (config(SETTINGS.checkout_json)) {
            checkout.data.json = '';
        }

        /* develblock:start */
        Log.debug('Prepared checkout data', checkout);
        container.debug.last_checkout = checkout;
        /* develblock:end */

        if (this.orderData != null) {
            checkout.data.order_data = this.orderData;
        }

        if (this.source != null) {
            checkout.data.source = this.source;
        }

        return checkout;
    }

    /**
     * Create the payload required to generate a checkout.
     */
    async getCartPayload(target: HTMLElement|null = null): Promise<{variant_id: string, quantity: number}|{items: CartItemInput, currency: string, note: string, attributes: GenericObject, token: string}|{token: string|undefined}|null> {
        // Express checkout
        if (target !== null && helpers.isExpressCheckoutButton(target)) {
            return this.getExpressCheckoutPayload();
        }

        // Cart
        switch (config(SETTINGS.checkout_data_style)) {
            case 'cartjs':
                return this.getCartJsModeData(target);
            case 'token':
            default:
                /** Send just the cart token for this mode. */
                return {
                    token: getCookie('cart'),
                };
        }
    }

    /**
     * Express checkout needs the variant_id / quantity from the add to cart form.
     */
    getExpressCheckoutPayload(): {variant_id: string, quantity: number}|null {
        let idEl;
        let qtyEl;
        let variantId;
        let qtyValue = 1;
        let parsedQty;
        const addToCartForm = getAddToCartForm();

        if (addToCartForm instanceof HTMLFormElement) {
            idEl = getFormField(addToCartForm, 'id');
            qtyEl = getFormField(addToCartForm, 'quantity');
            if (idEl instanceof HTMLElement) {
                variantId = normalizeIdentifier(idEl.value);
            }
            if (qtyEl instanceof HTMLElement) {
                parsedQty = parseInt(qtyEl.value);
                if (!isNaN(parsedQty)) {
                    qtyValue = parsedQty;
                }
            }
        }

        if (typeof variantId !== 'string') {
            return null;
        }

        return {
            variant_id: variantId,
            quantity: qtyValue,
        };
    }

    async saveCartMeta(meta: GenericObject) {
        await fetch('/cart.js', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(meta),
            credentials: 'include',
        });
    }

    /**
     * Request the latest cart data from Shopify.
     * Pare it down to only what we need to send.
     */
    async getCartJsModeData(target: HTMLElement|null): Promise<{items: CartInputRaw, currency: string, note: string, attributes: GenericObject, token: string}> {
        const res = await fetch(`/cart.js?t=${Date.now()}`, { credentials: 'include' });
        const cart = await res.json();
        let formNote = '';
        let formAttributes = null;
        if (target instanceof HTMLElement) {
            const cartForm = target.closest('form');
            const formData = formSerialize(cartForm, { hash: true });
            formNote = typeof formData.note === 'string' ? (formData.note).trim() : '';
            formAttributes = formData.attributes && Object.keys(formData.attributes).length ? formData.attributes : null;
        }

        if (formNote.length || formAttributes) {
            await this.saveCartMeta({ note: formNote, attributes: formAttributes });
        }

        const payloadCart = {
            items: cart.items.map((ci: any) => ({
                variant_id: ci.variant_id,
                product_id: ci.product_id,
                quantity: ci.quantity,
                properties: ci.properties,
            })),
            currency: cart.currency,
            note: formNote || cart.note,
            attributes: formAttributes || cart.attributes,
            token: cart.token,
        };

        // Remove some empty keys
        DraftOrderCheckoutLoader.removeEmptyOrExtraCartData(payloadCart);

        return payloadCart;
    }

    /**
     * Remove empty or extra data from the
     * cart payload that doesn't need to be
     * transmitted for the checkout.
     */
    static removeEmptyOrExtraCartData(cart: GenericObject) {
        if (!cart.attributes || Object.keys(cart.attributes).length === 0) {
            delete cart.attributes;
        }

        if (!cart.note) {
            delete cart.note;
        }

        cart.items.forEach((ci: any) => {
            if (ci.quantity === 1) {
                delete ci.quantity;
            }

            if (!ci.properties || Object.keys(ci.properties).length === 0) {
                delete ci.properties;
            }
        });
    }

    /**
     * Checkout that can run from any page without a cart form.
     */
    async spontaneousCheckout(): Promise<void> {
        const checkout = await this.getCheckoutData();
        if (checkout !== null) {
            return this.asyncCheckout(checkout, TYPE_SPONTANEOUS);
        } else {
            return DraftOrderCheckoutLoader.exitToStandardCheckout();
        }
    }

    /**
     * Wait checkout button available for click and click it
     */
    waitCheckoutAvailableForClick() {
        const targetNode = document.getElementsByName('checkout')[0];
        if (!targetNode) {
            // The node we need does not exist oryet.
            // Wait 200ms and try again
            window.setTimeout(this.waitCheckoutAvailableForClick, 200);
            return;
        }

        targetNode.click();
    }
}

export default DraftOrderCheckoutLoader;
