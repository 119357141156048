
import LimitItem from '../models/limit/LimitItem';
import CartItem from '../models/platform/CartItem';

export type scopeValues = 'variant'|'lineitem'|'product';

export function getLimitItem(state: Map<any, any>, cartItem: CartItem, limit: number, limitScope: scopeValues) {
    const key = getWithLimitActionKey(limitScope, cartItem);

    if (state.has(key)) {
        return state.get(key);
    }

    const limitItem = new LimitItem(key, limit);
    state.set(key, limitItem);
    return limitItem;
}

function getWithLimitActionKey(limitScope: scopeValues, cartItem: CartItem) {
    let id;

    switch (limitScope) {
        case 'product':
            id = `product:${cartItem.product_id}`;
            break;
        case 'lineitem':
            id = `lineitem:${cartItem.id}`;
            break;
        case 'variant':
        default:
            id = `variant:${cartItem.variant_id}`;
    }

    return id;
}
