
import { SerialDiscount, serializeDiscount } from './SerialDiscount';
import { serializeIdentifier } from '../../helpers/identifier';
import CartItem from '../../models/platform/CartItem';
import { Properties } from '../../models/platform/ShopifyCart';
import { SerialFee, serializeFee } from './SerialFee';
import { serializePriceBreakdown, SerialPriceBreakdown } from './SerialProduct';
import { nonEmptyStringOrUndefined } from '../../helpers/string';

interface SerialCartItem {
    discount: SerialDiscount|null;
    message?: string;
    expiry?: string|null;
    id: number|string;
    variant_id: number;
    product_id: number;
    price: number;
    original_price: number;
    line_price: number;
    quantity: number;
    properties: Properties;
    fees?: SerialFee[];
    price_breakdown: SerialPriceBreakdown[];
}

export function serializeCartItem(cartItem: CartItem): SerialCartItem {
    const discount = serializeDiscount(cartItem.getVariant());
    return {
        discount,
        fees: cartItem.getVariant().getFees().map(serializeFee),
        id: cartItem.id,
        message: nonEmptyStringOrUndefined(discount?.message),
        expiry: nonEmptyStringOrUndefined(discount?.expiry),
        product_id: serializeIdentifier(cartItem.product_id) as number,
        variant_id: serializeIdentifier(cartItem.variant_id) as number,
        price_breakdown: cartItem.getVariant().getPriceBreakdown().map(serializePriceBreakdown),
        quantity: cartItem.getQuantity(),
        price: cartItem.price.amount(),
        original_price: cartItem.original_price.amount(),
        line_price: cartItem.line_price.amount(),
        properties: cartItem.properties ?? {},
    };
}

export default SerialCartItem;
