import Ruleset, {
    ProductSelection,
    ProductSelectionLineItemId,
    ProductSelectionProduct,
    ProductSelectionVariant,
} from '../../models/Ruleset';
import BaseAction from '../../models/actions/BaseAction';
import { RuleInput } from '../../models/Rule';
import BaseCondition from '../../models/conditions/BaseCondition';
import { CONDITION_OPERATORS } from '../../constants';
import BaseCartLevelDiscountAction from '../../models/actions/BaseCartLevelDiscountAction';

export interface PrerequisiteToEntitlementQuantityRatio {
    prerequisite_quantity: any;
    entitled_quantity: any;
}

export interface PrerequisiteToEntitlementPurchase {
    prerequisite_amount: any;
}

export interface PrerequisiteShippingPriceRange {
    less_than_or_equal_to: string;
}

export interface PrerequisiteSubtotalRange {
    greater_than_or_equal_to: string;
}

export interface ShopifyDiscountCode {
    id: number;
    title: string;
    targetType: string;
    targetSelection: string;
    allocationMethod: string;
    allocationLimit: any;
    valueType: string;
    value: string;
    oncePerCustomer: boolean;
    usageLimit: any;
    customerSelection: string;
    prerequisiteSavedSearchIds: any;
    customerSegmentPrerequisiteIds: any[];
    prerequisiteCustomerIds: any[];
    prerequisiteSubtotalRange: PrerequisiteSubtotalRange;
    prerequisiteShippingPriceRange: PrerequisiteShippingPriceRange;
    prerequisiteQuantityRange: any;
    prerequisiteToEntitlementQuantityRatio: PrerequisiteToEntitlementQuantityRatio;
    prerequisiteToEntitlementPurchase: PrerequisiteToEntitlementPurchase;
    prerequisiteVariantIds: any[];
    prerequisiteProductIds: any[];
    prerequisiteCollectionIds: any[];
    entitledProductIds: any[];
    entitledVariantIds: any[];
    entitledCollectionIds: any[];
    entitledCountryIds: any[];
    startsAt: string;
    endsAt: any;
    createdAt: string;
    updatedAt: string;
}

export function serializeShopifyDiscountCode(codes: ShopifyDiscountCode[], discountCodeSetting: number, shop_domain: string): Ruleset[] {
    if (!codes || codes.length === 0) {
        return [];
    }

    const newRulesets: Ruleset[] = [];

    codes.filter((code) => isShopifyDiscountCodeSupported(code)).map((code) => {
        // Create a new ruleset for each customer
        if (code.prerequisiteCustomerIds && code.prerequisiteCustomerIds.length > 0) {
            code.prerequisiteCustomerIds.map(customer_id => {
                const conditions = mapConditions(code);
                conditions.push({
                    type: 'CUSTOMER_ID_SHOPIFY_DISCOUNT_CODE',
                    value: customer_id,
                } as BaseCondition);
                newRulesets.push(mapToRuleset(code, shop_domain, conditions, discountCodeSetting));
            });
        } else {
            const conditions = mapConditions(code);
            newRulesets.push(mapToRuleset(code, shop_domain, conditions, discountCodeSetting));
        }
    });

    return newRulesets;
}

export function isShopifyDiscountCodeSupported(code: ShopifyDiscountCode) {
    return !(
        code.customerSegmentPrerequisiteIds.length > 0 ||
        code.oncePerCustomer ||
        code.usageLimit ||
        code.entitledCollectionIds.length > 0 ||
        code.targetType === 'shipping_line' ||
        code.prerequisiteToEntitlementQuantityRatio.prerequisite_quantity > 0
    );
}

function mapConditions(code: ShopifyDiscountCode): any {
    const result: any[] = [];
    if (code.prerequisiteSubtotalRange) {
        result.push({
            type: 'CART_LEVEL_DISCOUNT_CONDITION',
            value: Number(code.prerequisiteSubtotalRange.greater_than_or_equal_to) * 100,
            operator: CONDITION_OPERATORS.GTE,
        });
    }
    if (code.prerequisiteQuantityRange) {
        result.push({
            type: 'QTY_ON_CART_SHOPIFY_DISCOUNT_CODE',
            value: code.prerequisiteQuantityRange.greater_than_or_equal_to,
            operator: CONDITION_OPERATORS.GTE,
        });
    }
    return result;
}

function mapToRuleset(code: ShopifyDiscountCode, shop_domain: string, conditions: BaseCondition[], discountCodeSetting: number) {
    return new Ruleset({
        id: code.id,
        app_slug: 'shopify_discount_code',
        public_name: '',
        sync_percent: 100,
        expiry_date: code.endsAt,
        priority: 1,
        product_selection: serializeShopifyDiscountCodeProductSelection(code),
        rules: [
            {
                id: code.id,
                external_id: `shopify_discount_code_${code.title}`,
                type: getRuleType(code, discountCodeSetting),
                actions: serializeShopifyDiscountCodeActions(code),
                conditions: conditions,
                priority: 1,
            } as RuleInput,
        ],
        external_id: `shopify_discount_code_${code.title}`,
    });
}

function getRuleType(code: ShopifyDiscountCode, discountCodeSetting: number) {
    switch (code.targetSelection) {
        case 'all':
            return 'CART_LEVEL_DISCOUNT';
        default:
            switch (discountCodeSetting) {
                case 1:
                    return 'SHOPIFY_DISCOUNT_CODE_STACKABLE';
                default:
                    return 'SHOPIFY_DISCOUNT_CODE_NOT_STACKABLE';
            }
    }
}

function serializeShopifyDiscountCodeProductSelection(code: ShopifyDiscountCode) {
    const applyToAllProducts = code.targetSelection === 'all';
    return {
        type: !applyToAllProducts
            ? 'SHOPIFY_PRODUCT_SEARCH'
            : 'SHOPIFY_PRODUCTS_ALL',
        products: !applyToAllProducts
            ? getProductsForProductSelection(code)
            : null,
    } as ProductSelection;
}

function getProductsForProductSelection(code: ShopifyDiscountCode): Array<ProductSelectionProduct|ProductSelectionVariant|ProductSelectionLineItemId> {
    const ids: Array<ProductSelectionProduct|ProductSelectionVariant|ProductSelectionLineItemId> = [];
    if (code.entitledProductIds) {
        code.entitledProductIds.forEach(id => {
            ids.push({
                product_id: id,
            } as ProductSelectionProduct);
        });
    }
    if (code.entitledVariantIds) {
        code.entitledVariantIds.forEach(id => {
            ids.push({
                variant_id: id,
            } as ProductSelectionVariant);
        });
    }
    return ids;
}

function serializeShopifyDiscountCodeActions(code: ShopifyDiscountCode) {
    const cartLevelDiscount = code.targetSelection === 'all';
    switch (code.valueType) {
        case 'percentage':
            return [{
                type: cartLevelDiscount
                    ? 'CART_LEVEL_DISCOUNT_PERCENT'
                    : 'PRICE_ADJUST_PERCENT',
                value: Math.trunc(Number(code.value)),
                name: cartLevelDiscount
                    ? code.title
                    : null,
            } as BaseAction | BaseCartLevelDiscountAction];
        default:
            return [{
                type: cartLevelDiscount
                    ? 'CART_LEVEL_DISCOUNT_RELATIVE'
                    : 'PRICE_ADJUST_RELATIVE',
                value: Math.trunc(Number(code.value)) * 100,
                name: cartLevelDiscount
                    ? code.title
                    : null,
            } as BaseAction | BaseCartLevelDiscountAction];
    }
}
