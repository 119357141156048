
import Ruleset, {
    ProductSelection, ProductSelectionLineItemId,
} from '../../Ruleset';
import { BuyXGetYCartItemInput, BuyXGetYCartItemResult, getCartResult as solver, Offer, OfferActions } from './Solver';
import BuyXGetYCondition, { BXGYProductSelection } from '../BuyXGetYCondition';
import { normalizeIdentifier, equalIdentifiers, makeVariantId } from '../../../helpers/identifier';
import Shop from '../../platform/Shop';
import container from '../../../components/Container';
import Cart from '../../platform/Cart';
import BaseCondition from '../BaseCondition';
import BaseAction from '../../actions/BaseAction';
import PriceAdjustRelativeAction from '../../actions/PriceAdjustRelativeAction';
import PriceAdjustPercentAction from '../../actions/PriceAdjustPercentAction';
import PriceAdjustAbsoluteAction from '../../actions/PriceAdjustAbsoluteAction';
import Rule from '../../Rule';
import Variant from '../../platform/Variant';
import CartItem from '../../platform/CartItem';

const ruleAppSlug = 'dynamic-buyXgetY';

const actionMap = {
    PRICE_ADJUST_PERCENT: 'PRICE_ADJUST_PERCENT_WITH_LIMIT',
    PRICE_ADJUST_ABSOLUTE: 'PRICE_ADJUST_ABSOLUTE_WITH_LIMIT',
    PRICE_ADJUST_RELATIVE: 'PRICE_ADJUST_RELATIVE_WITH_LIMIT',
};

let offerCount: number;
let generatedRulesetCount: number;

export function execute(cart: Cart, rulesets: Ruleset[]): Ruleset[] {
    resetRulesets(rulesets);
    offerCount = 0;
    generatedRulesetCount = 0;
    const cartBundlesResult = getBuyXGetYCartResult(cart, rulesets);
    return dynamicallyCreateRulesets(cart, cartBundlesResult, rulesets);
}

function getVariantsFromProductIds(productIds: string[]): string[] {
    const shop = container.get(Shop);
    return shop.getVariantsByProductIds(productIds).map((v) => v.id);
}

function getVariantIdFromSku(productId: string, sku: string): string|undefined {
    const shop = container.get(Shop);
    const variant = shop.getVariantsByProductIds([productId]).find(v => v.id);
    const variantId = variant?.getId();
    return variantId;
}

function parseGetVariants(product_selection: ProductSelection): string[] {
    return product_selection.products.flatMap((p) => {
        let variantId = normalizeIdentifier(p.variant_id);
        const productId = normalizeIdentifier(p.product_id) as string;
        const sku = normalizeIdentifier(p.variant_sku);
        if (sku) {
            variantId = variantId || (getVariantIdFromSku(productId, sku) ?? null);
            return variantId ? [makeVariantId(productId, variantId, sku)] : [];
        } else if (typeof variantId === 'string') {
            return variantId;
        } else {
            return getVariantsFromProductIds([productId]);
        }
    });
}

function parseBuyVariants(selection: BXGYProductSelection): string[] {
    if (typeof selection.product_ids === 'object') {
        if (selection.product_ids.length) {
            return getVariantsFromProductIds(selection.product_ids);
        } else {
            return [];
        }
    } else if (typeof selection.collection_ids === 'object') {
        const shop = container.get(Shop);
        return getVariantsFromProductIds(shop.productsInCollections(selection.collection_ids));
    } else if (typeof selection.sku_ids === 'object') {
        const skus = selection.sku_ids as string[];
        const shop = container.get(Shop);
        return shop.getAllVariants().filter(v => {
            const variantSku = normalizeIdentifier(v.getSku());
            return variantSku ? skus.includes(variantSku) : false;
        }).map(v => v.getId());
    } else {
        return selection.variant_ids as string[];
    }
}

function comparePrice(variant1: Variant, variant2: Variant): number {
    return variant1.getPrice().amt - variant2.getPrice().amt > 0 ? 1 : -1;
}

function parseRuleset(ruleset: Ruleset): Offer[] {
    let getVariantIds = [] as string[];
    const shop = container.get(Shop);
    if (['SHOPIFY_PRODUCTS_ALL', 'PRODUCTS_ALL'].includes(ruleset.product_selection.type)) {
        /**
         * All products are fair game, so the order in which we pass these along to generate rules matters.
         * To get the best price, we need to sort from highest to lowest price here
         */
        const variants = shop.getAllVariants();
        getVariantIds = variants.sort(comparePrice).map(variant => variant.id);
    } else {
        getVariantIds = parseGetVariants(ruleset.product_selection);
    }

    return ruleset.rules.filter(ruleIsBuyXGetY).map((rule) => ruleToOffer(rule, getVariantIds));
}

interface SplitCondition {
    bxgy: BuyXGetYCondition;
    others: BaseCondition[];
}

function splitConditions(conditions: BaseCondition[]): SplitCondition {
    const others: BaseCondition[] = [];
    let bxgy;

    for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i];
        if (condition instanceof BuyXGetYCondition) {
            bxgy = condition;
        } else {
            others.push(condition);
        }
    }
    return {
        bxgy: bxgy as BuyXGetYCondition,
        others,
    };
}

interface SplitAction {
    simple: PriceAdjustRelativeAction|PriceAdjustPercentAction|PriceAdjustAbsoluteAction|null;
    others: Array<BaseAction|null>
}

function splitActions(actions: BaseAction[]): SplitAction {
    const result: SplitAction = {
        simple: null,
        others: [],
    };
    const simpleActionTypes = Object.keys(actionMap);
    for (let i = 0; i < actions.length; i++) {
        const action = actions[i];
        const simple = simpleActionTypes.find(type => type === action.type);
        if (simple) {
            result.simple = action;
            result.others.push(null);
        } else {
            result.others.push(action);
        }
    }
    return result;
}

function ruleToOffer(rule: Rule, getVariantIds: string[]): Offer {
    const conditions = splitConditions(rule.conditions);
    const buyVariantIds = parseBuyVariants(conditions.bxgy.buy_product_selection);

    const actions = splitActions(rule.actions);
    const shop = container.get(Shop);

    const offerActions: OfferActions = {};
    const variants = shop.getAllVariants();

    getVariantIds.forEach((vid) => {
        const originalVariant = variants.find((v) => equalIdentifiers(v.id, vid) || equalIdentifiers(v.sku, vid)) as Variant;
        if (originalVariant) {
            const variantCopy = originalVariant.copy();
            if (actions.simple) {
                actions.simple.act(variantCopy.getPrice(), variantCopy, shop);
            }
            if (variantCopy.getPrice().amount() >= 0) {
                offerActions[vid] = variantCopy.getPrice().amount() - originalVariant.getPrice().amount();
            }
        }
    });

    return {
        id: offerCount++,
        rule,
        buy: {
            quantity: conditions.bxgy.buy_quantity,
            items: buyVariantIds.map(normalizeIdentifier) as string[],
        },
        get: {
            quantity: conditions.bxgy.get_quantity,
            items: getVariantIds.map(normalizeIdentifier) as string[],
        },
        getTimesLimit: conditions.bxgy.uses_per_order_limit,
        actions: offerActions,
        priority: rule.priority,
    };
}

function rulesetIsBuyXGetY(ruleset: Ruleset): boolean {
    return ruleset.app_slug !== ruleAppSlug &&
        ruleset.rules.length > 0 &&
        ruleset.rules.some(ruleIsBuyXGetY);
}

function ruleIsBuyXGetY(rule: Rule): boolean {
    return rule.actions.length > 0 &&
        rule.conditions.length > 0 &&
        rule.conditions.some((condition) => condition instanceof BuyXGetYCondition);
}

function getBuyXGetYCartResult(cart: Cart, rulesets: Ruleset[]): BuyXGetYCartItemResult[] {
    const cartItems = cart.items.map((cartItem) => ({
        lineItemId: cartItem.getId(),
        variantId: normalizeIdentifier(cartItem.variant_id) as string,
        quantity: cartItem.quantity,
        originalPrice: cartItem.price.orig_amt,
        productId: normalizeIdentifier(cartItem.product_id) as string,
        sku: normalizeIdentifier(cartItem.variant ? cartItem.variant.sku : '') as string,
    })) as BuyXGetYCartItemInput[];

    const offers = rulesets
        .filter(rulesetIsBuyXGetY)
        .flatMap(parseRuleset);

    return solver(cartItems, offers);
}

function dynamicallyCreateRulesets(cart: Cart, bxgyCartItems: BuyXGetYCartItemResult[], rulesets: Ruleset[]): Ruleset[] {
    bxgyCartItems = bxgyCartItems
        .filter((cartItem) => cartItem.computedPrice < cartItem.originalPrice && cartItem.offers!.length > 0);
    return bxgyCartItems.map((bxgyCartItem) => {
        const rule = bxgyCartItem.offers[0].rule as Rule;
        const originalCartItem = cart.items.find((ci) => ci.getId() === bxgyCartItem.lineItemId) as CartItem;
        const ruleset = rulesets.find((ruleset) => ruleset.id === rule.ruleset.id) as Ruleset;
        const conditions = splitConditions(rule.conditions);
        const actions = splitActions(rule.actions);
        const newAction = {
            type: 'PRICE_ADJUST_ABSOLUTE',
            value: bxgyCartItem.computedPrice,
        };
        const rawActions = actions.others.map(action => action === null ? newAction : action.toJSON());
        const productMatch: ProductSelectionLineItemId = {
            line_item_id: originalCartItem.getId(),
            product_id: bxgyCartItem.productId,
            variant_id: undefined,
            variant_sku: undefined,
        };

        return new Ruleset({
            id: generatedRulesetCount++,
            priority: ruleset.priority,
            app_slug: ruleAppSlug,
            expiry_date: ruleset.expiry_date,
            sync_percent: ruleset.sync_percent,
            public_name: ruleset.public_name,
            external_id: ruleset.external_id,
            product_selection: {
                type: 'LINE_ITEM_ID',
                products: [productMatch],
            },
            rules: [{
                type: rule.type,
                conditions: conditions.others.map(c => c.toJSON()),
                actions: rawActions,
            }],
        });
    });
}

function resetRulesets(rulesets: Ruleset[]) {
    rulesets.forEach((ruleset) => {
        if (ruleset.app_slug === ruleAppSlug) {
            ruleset.rules = [];
        }
    });
}
