import { shouldPolyfill as importGCL } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as importNF } from '@formatjs/intl-numberformat/should-polyfill';
import { checkBrowserRequiresPolyfill } from './checkBrowserRequiresPolyfill';

const lang = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
const isFr = Boolean(lang.match(/FR/));
const browserNeedsPolyfill = checkBrowserRequiresPolyfill();

export async function getIntlPolyfills() {
    await polyfillGCL();
    await polyfillPRAndNF();
}

async function polyfillGCL() {
    if (browserNeedsPolyfill && importGCL()) {
        await import(/* webpackChunkName: "intl-gcl-polyfill" */ '@formatjs/intl-getcanonicallocales/polyfill');
    }
}

// Polyfills pluralrules and numberformat
async function polyfillPRAndNF() {
    if (browserNeedsPolyfill && importNF()) {
        await import(/* webpackChunkName: "intl-pr-polyfill" */ '@formatjs/intl-pluralrules/polyfill');
        await import(/* webpackChunkName: "intl-nf-polyfill" */ '@formatjs/intl-numberformat/polyfill');
    }
    // @ts-ignore
    if (Intl.NumberFormat.polyfilled) {
        if (isFr) {
            await import(/* webpackChunkName: "intl-pr-fr-polyfill" */ '@formatjs/intl-pluralrules/locale-data/fr');
            await import(/* webpackChunkName: "intl-nf-fr-polyfill" */ '@formatjs/intl-numberformat/locale-data/fr');
        } else {
            await import(/* webpackChunkName: "intl-pr-en-polyfill" */ '@formatjs/intl-pluralrules/locale-data/en');
            await import(/* webpackChunkName: "intl-nf-en-polyfill" */ '@formatjs/intl-numberformat/locale-data/en');
        }
    }
}
