
export function isPresent<T>(t: T | undefined | null | void): t is T {
    return t !== undefined && t !== null;
}

export async function asyncForEach(arr: any[], fn: (x: any, i: number) => Promise<any>) {
    for (let i = 0; i < arr.length; i++) {
        await fn(arr[i], i);
    }
}

export function chunk(arr: string[], chunkSize = 2): string[][] {
    return arr.reduce((chunks: string[][], id: string) => {
        if (chunks[chunks.length - 1].length >= chunkSize) {
            chunks.push([]);
        }
        chunks[chunks.length - 1].push(id);
        return chunks;
    }, [[]]);
}

export function uniqueByProp(arr: any[], prop: string): any[] {
    const uniqueProps: any = [];
    return arr.filter((item: any) => {
        if (!uniqueProps.includes(item[prop])) {
            uniqueProps.push(item[prop]);
            return true;
        }
        return false;
    });
}

export function sortByProp(arr: any[], prop: string): any[] {
    return [...arr].sort((a, b) => {
        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }
        return 0;
    });
}

export function dedupe(arr: any[], key: string | null = null): any[] {
    const tbl: { [key: string]: number } = {};
    const newArr: any = [];
    arr.forEach((str: any) => {
        const dupeKey = key === null ? str : str[key];
        if (tbl[dupeKey] === undefined) {
            newArr.push(str);
            tbl[dupeKey] = 1;
        }
    });

    return newArr;
}

export function nonEmptyArrayOrUndefined<T, R>(arr: T[] | undefined, mapper: (raw: T) => R): R[] | undefined {
    if (arr && arr.length > 0) {
        return arr.map(mapper);
    }
    return undefined;
}

export function intersection(array1: any[], array2: any[]): any[] {
    return array1.filter(value => array2.includes(value));
}
