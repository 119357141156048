
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';

interface ProductSelectionVariant {
    variant_ids: string[];
    product_ids: undefined;
    collection_ids: undefined;
}

interface ProductSelectionProduct {
    variant_ids: undefined;
    product_ids: string[];
    collection_ids: undefined;
}

interface ProductSelectionCollection {
    variant_ids: undefined;
    product_ids: undefined;
    collection_ids: string[];
}

export type RequirementSelection = ProductSelectionProduct|ProductSelectionVariant|ProductSelectionCollection;

class BuyXGetYDiscountCodeCondition extends BaseCondition {
    requirement_selection: RequirementSelection;
    buy_quantity: number;
    get_quantity: number;
    uses_per_order?: number;

    constructor({
        type,
        requirement_selection,
        buy_quantity,
        get_quantity,
        uses_per_order,
    }: BaseConditionInput & {
        requirement_selection: RequirementSelection,
        buy_quantity: number,
        get_quantity: number,
        uses_per_order: number | null,
    }) {
        super({ type });
        this.requirement_selection = requirement_selection;
        this.buy_quantity = buy_quantity;
        this.get_quantity = get_quantity;
        this.uses_per_order = uses_per_order || 1;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        /*
         * This condition is not evaluated by rule processor, it is returned to PRE Discount Codes inside the
         * processed payload and send by our plugin to Cashier/Checkout so it can be evaluated in their side.
         */
        return true;
    }

    toJSON() {
        return {
            type: this.type,
            requirement_selection: this.requirement_selection,
            buy_quantity: this.buy_quantity,
            get_quantity: this.get_quantity,
            uses_per_order: this.uses_per_order,
        };
    }
}

export default BuyXGetYDiscountCodeCondition;
