import { ShopifyDiscountCode } from '../api/models/SerialShopifyDiscountCode';

/**
 * Shopify Discount Code Storage for draft orders CSPv3
 *
 * This class fetches and sets Shopify Discount Codes using session storage
 */

let memoryStorage: any = {};
class ShopifyDiscountCodeStorage {
    static storeData(shop_domain: string, rulesetData: ShopifyDiscountCode) {
        const data = sessionStorage.getItem(`PRE.shopifyDiscountCodes_${shop_domain}`) ?? '';
        const rulesets = data ? JSON.parse(data) : [];
        if (rulesets.filter((ruleset: any) => ruleset.id === rulesetData.id).length > 0) {
            return;
        }
        rulesets.push(rulesetData);
        memoryStorage.discountData =  JSON.stringify(rulesets);
        sessionStorage.setItem(`PRE.shopifyDiscountCodes_${shop_domain}`, JSON.stringify(rulesets));
    }

    static storeSettingsData(shop_domain: string, setting: string) {
        memoryStorage.discountSettings = setting;
        sessionStorage.setItem(`PRE.shopifyDiscountCodesSetting_${shop_domain}`, setting);
    }

    static fetchShopifyDiscountCodeData(shop_domain: string): [] {
        const data = sessionStorage?.getItem(`PRE.shopifyDiscountCodes_${shop_domain}`);
        if (!data) {
            if (memoryStorage?.discountData) {
                return JSON.parse(memoryStorage.discountData);
            }
            return [];
        }
        return JSON.parse(data);
    }

    static getShopifyDiscountCodeSettings(shop_domain: string): string | null {
        if (!sessionStorage && memoryStorage?.discountSettings) {
            return memoryStorage.discountSettings;
        }
        return sessionStorage.getItem(`PRE.shopifyDiscountCodesSetting_${shop_domain}`);
    }

    static clearAllShopifyDiscountCodeData(shop_domain: string) {
        memoryStorage.discountData = '';
        sessionStorage.removeItem(`PRE.shopifyDiscountCodes_${shop_domain}`);
    }

    static clearShopifyDiscountCodeData(shop_domain: string, codeToRemove: string) {
        const data = sessionStorage.getItem(`PRE.shopifyDiscountCodes_${shop_domain}`);
        if (data === null || data === '') {
            return null;
        }

        let rulesets = data ? JSON.parse(data) : [];
        rulesets = rulesets.filter((x: any) => x.title !== codeToRemove);

        this.clearMemoryDiscountData(codeToRemove);

        sessionStorage.setItem(`PRE.shopifyDiscountCodes_${shop_domain}`, JSON.stringify(rulesets));
    }

    static clearMemoryDiscountData(codeToRemove: string) {
        if (memoryStorage?.discountData) {
            let rulesets = memoryStorage.discountData ? JSON.parse(memoryStorage.discountData) : [];
            rulesets = rulesets.filter((x: any) => x.title !== codeToRemove);
    
            memoryStorage= { ...memoryStorage ,discountData: JSON.stringify(rulesets) };
        }
    }
}

export default ShopifyDiscountCodeStorage;
