import { ENV, config, SETTINGS } from '../config';
import { GenericObject } from '../helpers/object';
import Shop from '../models/platform/Shop';

/**
 * Rule Storage
 *
 * This class fetches and sets OrderData using local storage
 */
class CurrencyStorage {
    static isPreCurrencyEnabled() {
        return config(SETTINGS.multi_currency);
    }

    static storeData(currencyData: GenericObject) {
        window.localStorage.setItem(`PRE.currencyData_${currencyData.shop_domain}`, JSON.stringify(currencyData));
    }

    static fetchCurrencyData(shop: Shop): GenericObject|null {
        if (!this.isPreCurrencyEnabled() || ENV.NODE) {
            return null;
        }

        const data = window.localStorage.getItem(`PRE.currencyData_${shop.shop_domain}`);
        if (data === null) {
            return null;
        }

        return JSON.parse(data);
    }

    static getCurrencyName(shop: Shop) {
        if (!this.isPreCurrencyEnabled() || ENV.NODE) {
            return null;
        }

        const data = window.localStorage.getItem(`PRE.currencyData_${shop.shop_domain}`);
        if (data === null) {
            return null;
        }

        return JSON.parse(data).currencyName;
    }

    static setIsFetchingCurrency(shop: Shop) {
        const currencyData = this.fetchCurrencyData(shop) || {};
        if (Object.keys(currencyData).length === 0) {
            currencyData.shop_domain = shop.shop_domain;
        }
        currencyData.lastUpdated = Date.now();
        currencyData.isUpdating = true;
        this.storeData(currencyData);

        // force-set isUpdating to false after 3 seconds in case there is a backend 500 or an unresolved
        // promise preventing it from being reset
        setTimeout(() => this.unsetIsFetchingCurrency(shop), 3000);
    }

    static unsetIsFetchingCurrency(shop: Shop) {
        const currencyData = this.fetchCurrencyData(shop) || {};
        currencyData.isUpdating = false;
        this.storeData(currencyData);
    }

    static isFetchingCurrency(shop: Shop) {
        const currencyData = this.fetchCurrencyData(shop) || {};
        return currencyData.isUpdating && currencyData.lastUpdated < Date.now() + 3000;
    }

    static clearCurrencyData(shop: Shop) {
        window.localStorage.removeItem(`PRE.currencyData_${shop.shop_domain}`);
    }
}

export default CurrencyStorage;
