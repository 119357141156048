
import BaseCondition from './BaseCondition';
import Shop from '../platform/Shop';
import Variant from '../platform/Variant';
import { ENV } from '../../config';

abstract class CartBaseCondition extends BaseCondition {
    isCartConditionApplicable(shop: Shop): boolean {
        if (ENV.BROWSER) {
            return true;
        }
        if (shop === null || !shop.is_calling_for_cart) {
            return false;
        }
        return true;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        if (!this.isCartConditionApplicable(shop)) {
            return false;
        } else {
            return this.continueEvaluating(variant, shop);
        }
    }

    abstract continueEvaluating(variant: Variant, shop: Shop): boolean;
}

export default CartBaseCondition;
