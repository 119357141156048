
import { getParam, getCookie, setCookie, removeCookie } from '../helpers/browser';
import { BASE_URLS } from '../constants';
import BOLD, { boldWindow, WBoldPRE } from '../helpers/windowBold';
import WindowApi from '../api/WindowApi';
import { run } from './appRun';
import IPlatformFactory from '../models/platform/IPlatformFactory';
import IPlatformBinder from '../models/platform/IPlatformBinder';

const BI_COOKIE_NAME = 'PR_build_intercept';
const AUTOINJECT_ALLOWED_ROOTS = ['boldcommerce.com', 'boldapps.net', 'bold.ninja'];

declare global {
    let __webpack_public_path__: string;
}

export function setup() {
    // eslint-disable-next-line no-undef
    __webpack_public_path__ = `${BASE_URLS.CHUNK}/${__webpack_public_path__}`;

    if (BOLD.pre) {
        BOLD.pre.ready = getApiReadyPromise;
    } else {
        BOLD.pre = { ready: getApiReadyPromise };
    }

    makeReadyPromise(BOLD.pre);
}

export function start(platform: string, platformBinder: IPlatformBinder, platformFactory: IPlatformFactory) {
    try {
        if (interceptEnv(platform)) {
            return null;
        }
    } catch (err) {
        console.warn(err);
    }

    return run(platformBinder, platformFactory);
}

function makeReadyPromise(pre: WBoldPRE) {
    if (pre._api_rdy_prom !== undefined) {
        return;
    }
    // @ts-expect-error
    if (typeof boldWindow.Promise === 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        let callbackSuccess = (api: WindowApi) => {}; let callbackFailure = (reason: string|undefined) => {};
        pre._api_rdy_prom_resolve = (api) => { callbackSuccess(api); };
        pre._api_rdy_prom_reject = (reason) => { callbackFailure(reason); };
        // @ts-expect-error
        pre._api_rdy_prom = { then: (s, f) => { callbackSuccess = s; callbackFailure = f; } };
    } else {
        pre._api_rdy_prom = new Promise((resolve: (value: WindowApi) => void, reject) => {
            pre._api_rdy_prom_resolve = resolve;
            pre._api_rdy_prom_reject = reject;
        });
    }
}

function getApiReadyPromise(): Promise<WindowApi> {
    return BOLD.pre!._api_rdy_prom!;
}

/**
 * Intercept the env if _env is set
 * and valid. Return true if intercepting.
 */
function interceptEnv(platform: string): boolean {
    const envParam = getParam('_env');
    const envCookie = getCookie(BI_COOKIE_NAME);

    const _env = typeof envParam === 'string' ? envParam.trim() : envCookie;

    if (typeof _env !== 'string' || boldWindow.__PR_DEBUG_build_intercepted) { // !env or already intercepted
        return false; // not intercepting
    }

    const script = getScriptToInject(_env, platform);

    if (script) {
        console.warn(`PRE build interception -- ${_env} --> ${script}`);
        boldWindow.__PR_DEBUG_build_intercepted = true; // Prevent endless interception
        setCookie(BI_COOKIE_NAME, _env);
        loadScript(script);
        return true; // intercepting
    } else {
        if (_env === '') {
            console.warn(`PRE build interception reset`);
        } else {
            console.warn(`PRE build interception FAIL -- ${_env} --> ${script}`);
        }
        removeCookie(BI_COOKIE_NAME);

        return false; // not intercepting
    }
}

function loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.async = false;
    script.type = 'text/javascript';
    document.currentScript?.appendChild(script);
}

function getPlatformScriptName(platform: string): string {
    switch (platform) {
        case 'woocommerce':
            return 'pr-woocommerce';
        case 'bigcommerce':
            return 'pr-bigcommerce';
        case 'shopify':
        default:
            return 'pr';
    }
}

function getScriptToInject(_env: string, _platform: string): string|null {
    const scriptName = getPlatformScriptName(_platform);

    switch (_env) {
        case '':
        case null:
        case undefined:
            return null;
        case 'dev':
            return `${BASE_URLS.CHUNK}/${scriptName}.dev.js`;
        case 'staging':
            return `https://static.test.boldcommerce.com/bold-platform/sf/${scriptName}.js`;
        default:
            return isAutoInjectRootAllowed(_env)
                ? `https://${_env}`
                : null;
    }
}

function isAutoInjectRootAllowed(url: string): boolean {
    return !!AUTOINJECT_ALLOWED_ROOTS.find(ar => {
        return url.search(new RegExp(`^[^/]+.${ar}/`, 'i')) === 0;
    });
}
