
import BaseAction, { BaseActionParams } from './BaseAction';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import Shop from '../platform/Shop';

class CharmPriceAction extends BaseAction {
    value: number;

    constructor({ type, value }: BaseActionParams) {
        super({ type });
        this.value = value;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = price.amount();
        const oneDollar = 100;
        if (price.isLessThan(oneDollar) === false) {
            price.setCentsToZero();
            price.add(this.value);
        }

        variant.log(this.type, { price_adj: `${oldPrice} --> ${price.amount()}` });
    }
}

export default CharmPriceAction;
