
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class SourceCondition extends BaseCondition {
    name: string;
    value: string;

    constructor({ name, value, ...others }: BaseConditionInput & { name: string, value: string }) {
        super(others);
        this.name = name;
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        return shop.source === this.value;
    }
}

export default SourceCondition;
