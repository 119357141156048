
import Log from '../../helpers/Log';
import { isCashierActive } from '../../helpers/cashier';
import { isShopifyMCActive, getShopifyMCInfo } from '../../helpers/shopify';
import { getSelectedVariantId, getSingleOptionSelectorIndex } from '../../helpers/genericHelpers';
import container from '../Container';
import Shop from '../../models/platform/Shop';
import { EVENTS, channel } from '../../events';
import { SETTINGS, config } from '../../config';
import MoneyDisplay from '../../models/money/MoneyDisplay';
import { GenericObject } from '../../helpers/object';
import IPlatformBinder from '../../models/platform/IPlatformBinder';
import FrontendEvents, { onVariantChanged, onCartQuantityChanged, onAjaxCartLoaded, ee } from '@bold-commerce/frontend-events-shopify';
import BoldCheckoutLoader from '../../browser/modules/checkout/BoldCheckoutLoader';
import { onDomReady } from '../../helpers/dom';
import DraftOrderCheckoutLoader from '../../browser/modules/checkout_shopify/DraftOrderCheckoutLoader';
import LoginHandler from '../../browser/modules/checkout_shopify/LoginHandler';

/**
 * ShopifyBinder
 *
 * Binds platform specific events to events
 * that feed into the system.
 */
const ShopifyBinder: IPlatformBinder = {
    bind: () => {
        const platformData = container.getData('platform_data');

        initializeCheckoutLoader();

        FrontendEvents.init();
        onVariantChanged((data: any) => handleVariantChange(data));
        onCartQuantityChanged((data: any) => handleCartQuantityChange(data));
        onAjaxCartLoaded((cart: any) => channel.dispatch(EVENTS.CART_UPDATED, cart));
        ee.on('BOLD_CURRENCY_requires_augmentation', () => {
            MoneyDisplay.enableBoldMC();
            channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
        });

        window.addEventListener('load', onload.bind(null, platformData.template));
        // On Order Data change event goes here
        /* develblock:start */
        if (isShopifyMCActive()) {
            Log.warn('Detected shop has Shopify MultiCurrency active.');
            Log.debug(getShopifyMCInfo());
        }
        /* develblock:end */
    },
};

function handleVariantChange(data: GenericObject) {
    const variantId = data?.variant?.id;
    if (variantId) {
        channel.dispatch(EVENTS.VARIANT_CHANGED, data);
        channel.emit(EVENTS.MONEY_ELEMENT_RESTORED, variantId);
    } else {
        const variantId = getSelectedVariantId();
        if (variantId) {
            const shop = container.get(Shop);
            const variantData = shop.getVariantById(variantId);
            channel.dispatch(EVENTS.VARIANT_CHANGED, variantData);
            channel.emit(EVENTS.MONEY_ELEMENT_RESTORED, variantId);
        }
    }
}

function onload(page: string) {
    if (page === 'product') {
        setTimeout(
            productPageAutoVariantFallbacks,
            10,
        );
    }
}

/**
 * This handles cases on the product page in which we can
 * make assumptions about what variant was changed if we
 * can't tell explicitly by id.
 */
function productPageAutoVariantFallbacks() {
    const variantId = getSelectedVariantId();
    if (variantId) {
        /* develblock:start */
        Log.warn(`Backfilled variant changed with AddToCart form id.`, variantId);
        /* develblock:end */
        handleVariantChange({ variant: { id: variantId } });
        setTimeout(() => {
            ee.emit('BOLD_PRE_set_initial_variant');
        }, 1);
        return;
    }

    const variantIndex = getSingleOptionSelectorIndex();
    const pd = container.getData('platform_data');
    const variant = typeof variantIndex === 'number' && pd?.product?.variants?.[variantIndex];

    if (variant) {
        /* develblock:start */
        Log.warn(`Backfilled variant changed from single option selector index.`, variant);
        /* develblock:end */
        handleVariantChange({ variant });
    }
}

function handleCartQuantityChange(data: GenericObject) {
    const shop = container.get(Shop);
    if (data.quantities && data.quantities.length === shop.cart.items.length) {
        shop.cart.items.forEach((cartItem, i) => {
            const currentQty = parseInt(`${cartItem.getQuantity()}`);
            const newQty = parseInt(data.quantities[i]);
            if (currentQty !== newQty) {
                // Quantity changed
                /* develblock:start */
                Log.info(`Qty changed for line item #${i} ${cartItem.variant.displayName} : ${currentQty} => ${newQty}`);
                /* develblock:end */
                cartItem.setQuantity(newQty);
                channel.dispatch(EVENTS.CART_STATE_UPDATED);

                if (newQty > 0 && config(SETTINGS.shopify_auto_update_qty)) {
                    updateCartQuantityInShopify(cartItem.getId(), newQty);
                }
            }
        });
    }
}

async function updateCartQuantityInShopify(id: string, quantity: number) {
    try {
        fetch('/cart/change.js', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, quantity }),
        });
    } catch (error) {
        /* develblock:start */
        Log.warn('Error updating cart quantity in Shopify.', { error });
        /* develblock:end */
    }
}

function initializeCheckoutLoader() {
    const isCheckoutLoaderEnabled = () => {
        return config(SETTINGS.checkout_loader_enabled);
    };

    if (isCheckoutLoaderEnabled()) {
        const shop = container.get(Shop);
        const domain = shop.getShopDomain();
        const customer = shop.getCustomer();
        const page = shop.getPage();
        const orderData = shop.getOrderData();
        const source = shop.getSourceQuery();
        const cart_params = shop.getCartParams();
        if (isCashierActive()) {
            const cashierLoader = new BoldCheckoutLoader(domain, customer, orderData, source, cart_params, 'shopify', shop);
            onDomReady().then(() => {
                cashierLoader.init();
            });
        } else {
            const shopifyCheckoutLoader = new DraftOrderCheckoutLoader(domain, customer, isCheckoutLoaderEnabled, orderData, source);
            shopifyCheckoutLoader.init();
            onDomReady().then(() => {
                LoginHandler.run(page, shopifyCheckoutLoader);
            });
        }
    }
}

export default ShopifyBinder;
