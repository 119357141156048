
import Fee from '../../models/money/Fee';

export interface SerialFee {
    amount: number;
    is_cart_fee: boolean
    name?: string;
}

export function serializeFee(fee: Fee): SerialFee {
    return {
        amount: fee.amount,
        is_cart_fee: fee.is_cart_fee,
        name: fee.name,
    };
}
