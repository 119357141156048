
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';
import { config, SETTINGS, ENV } from '../../config';

class ApplicableCurrencyCondition extends BaseCondition {
    value: string | null;

    constructor({ type, value }: BaseConditionInput & { value: string | null }) {
        super({ type });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        /**
         * if this is from checkout, then it passes config check. The shop object from
         * Node doesn't have "currency".
         *
         * if this is from storefront, the ruleset has already passed filter in query which
         * means it can be directly applied.
         */

        return true;
    }
}

export default ApplicableCurrencyCondition;
