import { GenericObject } from '../../helpers/object';

interface Input {
    name?: string;
    is_cart_fee?: boolean;
    amount: number;
    meta?: GenericObject | null;
    line_id?: string;
}

/**
 * Fee
 */
class Fee {
    amount: number;
    is_cart_fee: boolean;
    line_id?: string;
    meta: GenericObject|null;
    name?: string;

    constructor({
        name,
        amount,
        meta = null,
        line_id,
        is_cart_fee = false,
    }: Input) {
        this.name = name;
        this.amount = amount;
        this.meta = meta;
        this.is_cart_fee = is_cart_fee;
        this.line_id = line_id;
    }
}

export default Fee;
