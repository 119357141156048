
import { EVENTS, channel } from '../events';
import Platform from '../models/platform/Platform';
import container from '../components/Container';
import { CartInputRaw } from '../models/platform/Cart';
import CartItem from '../models/platform/CartItem';
import { equalIdentifiers } from '../helpers/identifier';
import Shop from '../models/platform/Shop';

interface CartQuantityChangeData {
    index: number|null;
    id: number|undefined;
    qty?: number;
}

function cartReducer(event: string, data: CartInputRaw|CartQuantityChangeData) {
    switch (event) {
        case EVENTS.CART_UPDATED:
            updateCart(data as CartInputRaw);
            break;
        case EVENTS.CART_UPDATED_QTY:
            updateCartQty(data as CartQuantityChangeData);
            break;
    }
}

async function updateCart(rawCart: CartInputRaw) {
    const platform = container.get(Platform);
    const shop = platform.getShop();

    if (rawCart) {
        platform.Factory.replaceShopCart(shop, rawCart);
    } else {
        await platform.Factory.refreshShopCart(shop);
    }

    channel.dispatch(EVENTS.LOADED_CART);
}

function removeCartItem(index: number | null, id: any, shop: Shop) {
    const filterBy = id === undefined
        ? (_: CartItem, i: number) => i !== index // find by index
        : (item: CartItem) => !equalIdentifiers(item.getId(), id); // find by id

    shop.cart.items = shop.cart.items.filter(filterBy);
}

function updateCartQty({
    index = 0,
    id,
    qty = 1,
}: CartQuantityChangeData) {
    const platform = container.get(Platform);
    const shop = platform.getShop();

    const findBy = id === undefined
        ? (_: CartItem, i: number) => i === index // find by index
        : (item: CartItem) => equalIdentifiers(item.getId(), id); // find by id

    const cartItem = shop.cart.items.find(findBy);

    if (cartItem) {
        if (qty === 0) {
            removeCartItem(index, id, shop);
        } else {
            cartItem.setQuantity(qty);
        }
        channel.dispatch(EVENTS.CART_STATE_UPDATED);
    } else {
        console.warn('Cart item not found.');
    }
}

export default cartReducer;
