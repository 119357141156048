
import VariantElement from './VariantElement';
import ProductQtyElement from './ProductQtyElement';

export function make(type: string, element: HTMLElement) {
    switch (type) {
        case 'variant':
            return new VariantElement(element);
        case 'prodqty':
            return new ProductQtyElement(element);
    }

    console.warn(`Unrecognized custom element type: ${type}`);
}
