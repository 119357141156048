
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';
import CartItem from '../../platform/CartItem';
import { CONDITION_OPERATORS as OPS } from '../../../constants';

class QtyOnCartForShopifyDiscountCodeCondition extends BaseCondition {
    value: number;

    constructor({ type, value, operator = OPS.EQUAL }: BaseConditionInput & { value: number }) {
        super({ type, operator });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        if (!(variant.parent instanceof CartItem)) {
            return this.compare(1);
        }

        const totalQuantity = shop.cart.items.reduce((sum, item) => {
            sum += item.getQuantity();
            return sum;
        }, 0);

        return this.compare(totalQuantity);
    }
}

export default QtyOnCartForShopifyDiscountCodeCondition;
