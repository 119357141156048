
import type IPlatformFactory from './IPlatformFactory';
import Shop from './Shop';

class Platform {
    Factory: IPlatformFactory;
    shop: Shop;

    constructor({ shop, Factory }: { shop: Shop, Factory: IPlatformFactory }) {
        this.shop = shop;
        this.Factory = Factory;
    }

    /********************************
     * ⤓ Simple getters / toJSON ⤓ *
     ********************************/

    toJSON() {
        return {
            shop: this.shop,
        };
    }

    getShop() {
        return this.shop;
    }
}

export default Platform;
