// @ts-check
import { EVENTS, channel } from '../../../events';
import Customer from '../../../models/platform/Customer';
import { GenericObject } from '../../../helpers/object';
import bold, { WBoldPRE } from '../../../helpers/windowBold';
import OrderDataStorage from '../../../components/OrderDataStorage';
import Shop from '../../../models/platform/Shop';

class BoldCheckoutLoader {
    customer: Customer;
    domain: string;
    platform: string;
    orderData: GenericObject | null;
    source: string | null;
    config: GenericObject | undefined;
    cartParams: GenericObject | null;
    shop: Shop;

    constructor(domain: string, customer: Customer, orderData: GenericObject | null, source: string | null, cartParams: GenericObject | null, platform: string, shop: Shop) {
        this.domain = domain;
        this.customer = customer;
        this.orderData = orderData;
        this.source = source;
        this.cartParams = cartParams;
        this.platform = platform;
        this.shop = shop;

        if (typeof bold !== 'undefined') {
            const pre = bold.pre as WBoldPRE;
            this.config = pre.config;
        }
    }

    /**
     * Add order data to the cart form
     */
    init() {
        const checkoutForm = document.querySelector("form[action^='/cart']");
        if (this.platform === 'bigcommerce') {
            bold.checkout?.registerCheckoutCallback?.((form: HTMLFormElement, cart: any) => {
                this.appendPREDataToForm(form);
            });
        } else if (this.platform === 'woocommerce') {
            this.appendPREDataToCookie();
        } else if (checkoutForm instanceof HTMLFormElement) {
            this.appendPREDataToForm(checkoutForm);
            channel.on(EVENTS.ORDER_DATA_UPDATED, this.updateData);
        }
    }

    appendPREDataToCookie() {
        const cartParamsCookie = bold.checkout?.getCookie('cartParams');
        let cartParams = {};
        if (!cartParamsCookie) {
            cartParams = {};
        } else {
            cartParams = bold.checkout?.decodeCartParams(cartParamsCookie);
        }

        cartParams = {
            domain: this.domain,
            customer_data: JSON.stringify(this.customer),
            order_data: this.orderData,
            source: this.source,
            cart_params: JSON.stringify(this.cartParams, OrderDataStorage.cart_params_replacer),
            multi_currency_default_currency: this.config?.multi_currency ? this.config?.multi_currency_default_currency : null,
        };

        bold.checkout?.setCartParamsCookie('pre', cartParams);
    }

    appendPREDataToForm(checkoutForm: HTMLFormElement) {
        this.addData(checkoutForm, this.orderData);
        this.addSource(checkoutForm, this.source);
        this.addCustomer(checkoutForm);
        this.addCurrency(checkoutForm);
        this.cartParams = this.shop.getCartParams();
        if (this.cartParams != null) {
            this.addCartParams(checkoutForm, this.cartParams);
        }
    }

    addCurrency(target: HTMLFormElement) {
        if (typeof this.config !== 'undefined') {
            if (this.config.multi_currency) {
                this.addHidden(target, 'bold_cart_params[pre][multi_currency_default_currency]', this.config.multi_currency_default_currency);
            }
        }
    }

    addCustomer(target: HTMLFormElement) {
        if (this.customer != null) {
            this.addHidden(target, 'bold_cart_params[pre][customer_data]', JSON.stringify(this.customer));
        }
    }

    addHidden(form: HTMLFormElement, key: string, value: string|null) {
        // Create a hidden input element, and append it to the form:
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key; // 'the key/name of the attribute/field that is sent to the server
        input.value = value || '';
        form.appendChild(input);
    }

    /**
     * Gather data needed to initiate a checkout.
     */
    addData(target: HTMLFormElement, orderData: GenericObject|null) {
        if (this.orderData != null) {
            this.addHidden(target, 'bold_cart_params[pre][order_data]', JSON.stringify(orderData));
        }
    }

    addCartParams(target: HTMLFormElement, cartParams: GenericObject|null) {
        if (this.orderData != null) {
            this.addHidden(target, 'bold_cart_params[pre][cart_params]', JSON.stringify(cartParams, OrderDataStorage.cart_params_replacer));
        }
    }

    /**
     * Add source to hidden input
     */
    addSource(target: HTMLFormElement, source: string|null) {
        this.addHidden(target, 'bold_cart_params[pre][source]', source);
    }

    /**
     * Update or add the hidden data input.
     */
    updateData(orderData: GenericObject) {
        const checkoutForm = document.querySelector("form[action^='/cart']") as HTMLFormElement|null;
        if (checkoutForm instanceof HTMLFormElement) {
            const orderDataInputs = document.getElementsByName('bold_cart_params[pre][order_data]');
            if (orderDataInputs != null && orderDataInputs.length > 0) {
                const input = orderDataInputs[0] as HTMLInputElement;
                input.value = JSON.stringify(orderData);
            } else {
                this.addData(checkoutForm, orderData);
            }
        }
    }
}

export default BoldCheckoutLoader;
