
import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import CartItem from '../../platform/CartItem';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';

class DiscountCodeCondition extends BaseCondition {
    value: string;

    constructor({
        type,
        value,
    }: BaseConditionInput & { value: string }) {
        super({ type });
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        /* Discount codes don't apply outside of a cart. */
        if (!(variant.parent instanceof CartItem)) {
            return false;
        }

        const cart = shop.getCart();

        if (cart && cart.getDiscountCode()) {
            return this.value.toLowerCase() === cart.getDiscountCode()?.toLowerCase();
        }

        return false;
    }
}

export default DiscountCodeCondition;
