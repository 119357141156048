
import PriceElement from './PriceElement';
import TemplateElement from './TemplateElement';
import MoneyTemplate, { DEFAULT_TEMPLATE } from '../money/MoneyTemplate';
import { EVENTS, channel } from '../../events';
import { hasClass } from '../../helpers/dom';
import { GenericObject, sleep } from '../../helpers/object';
import Money from '../money/Money';

/**
 * PriceElementSet
 *
 * This class is the bridge between a Money element
 * belonging to a Variant and a set of elements that
 * ought to be populated with the price for that variant.
 *
 * Only Product, CartItem and Cart should have instances
 * of this class.
 */
class PriceElementSet {
    elements: Array<PriceElement | TemplateElement>;
    money: Money;
    name: string;
    parent: any;
    template: MoneyTemplate;
    templateString: string;
    visible: boolean;
    constructor(name: string, parent: any, elements = [], money: Money, templateString = DEFAULT_TEMPLATE) {
        this.name = name;
        this.parent = parent;
        this.elements = elements.map(ele => this.elementFactory(ele, null));
        this.money = money;
        this.templateString = templateString;
        this.visible = false;
        this.template = new MoneyTemplate(templateString);

        if (money) {
            this.addMoneyListener();
        }
    }

    addMoneyListener() {
        this.money.ee.on('change', this.updateElements, this);
    }

    removeMoneyListener() {
        this.money.ee.removeListener('change', this.updateElements, this);
    }

    /**
     * Add and process a new price dom element.
     */
    push(domElement: HTMLElement) {
        const data = this.renderTemplate();
        const newElement = this.elementFactory(domElement, data);
        this.elements.push(newElement);
        if (this.visible) {
            newElement.show();
        }
        channel.dispatch(EVENTS.MONEY_RENDERED, { element_type: this.name, elements: [domElement] });
    }

    elementFactory(domElement: HTMLElement, data: GenericObject | null = null) {
        if (hasClass(domElement, 'money-template')) {
            return new TemplateElement(domElement, this);
        }

        return new PriceElement(domElement, this, data);
    }

    /**
     * A new money element.
     *
     * Usually a new money element shows up
     * because the variant on a product page
     * was changed and we're getting the Money
     * for the newly selected variant.
     *
     * We need to switch out the listeners
     * and refill the elements with the new price.
     */
    setMoney(money: Money) {
        this.removeMoneyListener();
        this.money = money;
        this.addMoneyListener();

        this.updateElements();
    }

    updateElements() {
        const data = this.renderTemplate();
        this.elements.forEach((ele) => ele.update(data));
        channel.dispatch(EVENTS.MONEY_RENDERED, { element_type: this.name, elements: this.elements.map((e) => e.domElement) });
    }

    renderTemplate() {
        return this.template.render(this.money, this.parent);
    }

    showAll() {
        this.visible = true;
        this.elements.forEach((ele) => ele.show());
    }

    purge() {
        this.elements.forEach((ele) => ele.purge());
        this.elements = [];
    }

    isEmpty(): boolean {
        return this.elements.length === 0;
    }

    flash(): true {
        this.elements.forEach(async (ele) => {
            for (let i = 0; i < 3; i++) {
                ele.domElement.style.background = 'rgba(50,205,50,.5)';
                await sleep(200);
                ele.domElement.style.background = '';
                await sleep(200);
            }
        });
        return true;
    }
}

export default PriceElementSet;
