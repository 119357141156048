
class ElementCache {
    elements: HTMLElement[];
    constructor({ elements = [] } = {}) {
        this.elements = elements;
    }

    contains(element: HTMLElement) {
        return this.elements.includes(element);
    }

    clear() {
        this.elements = [];
    }

    save(elements: HTMLElement[]|HTMLElement) {
        if (Array.isArray(elements)) {
            this.elements = this.elements.concat(elements);
        } else {
            this.elements.push(elements);
        }
    }

    /**
     * Filters out any cached elements from
     * the provided set.
     */
    filterCachedFrom(elements: HTMLElement[]): HTMLElement[] {
        return Array.from(elements).filter(ele => !this.elements.includes(ele));
    }
}

export default ElementCache;
