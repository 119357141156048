
export function ucfirst(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function padZero(num: number, targetLength = 2): string {
    return num.toString().padStart(targetLength, '0');
}

export function randomString(): string {
    return randomShortString() + randomShortString();
}

function randomShortString(): string {
    return Math.random().toString(36).substr(2, 5);
}

export function nonEmptyStringOrUndefined(candidate: any): string|undefined {
    if (typeof candidate === 'string' && candidate.length > 0) {
        return candidate;
    }
    return undefined;
}
