
import BaseCondition, { BaseConditionInput } from './BaseCondition';
import CartItem from '../platform/CartItem';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';

class LineItemPropertyCondition extends BaseCondition {
    name: string;
    value: number;

    constructor({
        name,
        value,
        ...others
    }: BaseConditionInput & { name: string, value: number }) {
        super(others);
        this.name = name;
        this.value = value;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        if (!(variant.parent instanceof CartItem)) {
            return false;
        }

        return this.compare(variant.parent.getProperty(this.name));
    }
}

export default LineItemPropertyCondition;
