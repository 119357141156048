
import Log from '../helpers/Log';
import { EVENTS, channel } from '../events';
import { GenericObject } from '../helpers/object';
import Platform from '../models/platform/Platform';
import Shop from '../models/platform/Shop';
import container from '../components/Container';
import { normalizeIdentifier } from '../helpers/identifier';
import Product from '../models/platform/Product';

function productReducer(event: string, data: { products: Product[], variant: { id: string|number, index?: number, product_id?: number }|null } = { products: [], variant: null }) {
    switch (event) {
        case EVENTS.NEW_PRODUCTS_RAW:
            createNewProducts(data.products);
            break;
        case EVENTS.VARIANT_CHANGED:
            if (data?.variant?.id) {
                variantChanged(data.variant as { id: string|number });
            } else if (data?.variant?.index && data?.variant?.product_id) {
                variantChangedByIndex(data.variant as { index: number, product_id: number|string });
            }
            break;
    }
}

function createNewProducts(rawProducts: GenericObject[]) {
    const platform = container.get(Platform);
    const shop = container.get(Shop);
    const products = rawProducts.reduce((carry: Product[], rawProd) => {
        const existingProduct = shop.getProductById(rawProd.id);
        if (!existingProduct) {
            carry.push(platform.Factory.createProductInstance(rawProd));
        }
        return carry;
    }, []);

    shop.products = shop.products.concat(products);
    channel.dispatch(EVENTS.LOADED_PRODUCTS, { products });
}

function variantChanged({ id }: { id: string|number }) {
    id = normalizeIdentifier(id) as string;

    const shop = container.get(Shop);
    const product = shop.getProductByVariantId(id);

    if (product) {
        product.setSelectedVariantId(id);
    }
}

function variantChangedByIndex({ index, product_id }: { index: number, product_id: string|number }) {
    product_id = normalizeIdentifier(product_id) as string;

    const shop = container.get(Shop);
    const product = shop.getProductById(product_id);
    const variant = product && product.variants[index];

    if (variant && product) {
        product.setSelectedVariantId(variant.id);
    } else {
        /* develblock:start */
        Log.warn(`Variant index ${index} not found in prod ${product_id}.`);
        /* develblock:end */
    }
}

export default productReducer;
