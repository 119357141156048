
import PriceAdjustAbsoluteAction from './PriceAdjustAbsoluteAction';
import PriceAdjustRelativeAction from './PriceAdjustRelativeAction';
import PriceAdjustPercentAction from './PriceAdjustPercentAction';
import PriceAdjustAbsoluteWithLimitAction from './PriceAdjustAbsoluteWithLimitAction';
import PriceAdjustPercentWithLimitAction from './PriceAdjustPercentWithLimitAction';
import PriceAdjustRelativeWithLimitAction from './PriceAdjustRelativeWithLimitAction';
import CharmPriceAction from './CharmPriceAction';
import AddFeeAction from './AddFeeAction';
import AddCartFeeAction from './AddCartFeeAction';
import DisplayQuantityBreakAction from './DisplayQuantityBreakAction';
import CustomerDraftOrderTaxableAction from './CustomerDraftOrderTaxableAction';
import ShippingAdjustRelativeAction from './ShippingAdjustRelativeAction';
import ShippingAdjustPercentAction from './ShippingAdjustPercentAction';
import FreeShippingAction from './FreeShippingAction';
import CartAdjustRelativeAction from './CartAdjustRelativeAction';
import CartAdjustPercentAction from './CartAdjustPercentAction';
import PriceAdjustAbsoluteBasePriceAction from './PriceAdjustAbosluteBasePriceAction';
import CasePricingAction from './CasePricingAction';
import PriceAdjustRateAction from './PriceAdjustRateAction';
import CartLevelDiscountRelativeAction from './CartLevelDiscountRelativeAction';
import CartLevelDiscountPercentAction from './CartLevelDiscountPercentAction';

export function make(action: { type: string } & any) {
    switch (action.type) {
        case 'PRICE_ADJUST_ABSOLUTE_BASE_PRICE':
            return new PriceAdjustAbsoluteBasePriceAction(action);
        case 'PRICE_ADJUST_ABSOLUTE':
            return new PriceAdjustAbsoluteAction(action);
        case 'PRICE_ADJUST_RELATIVE':
            return new PriceAdjustRelativeAction(action);
        case 'PRICE_ADJUST_PERCENT':
            return new PriceAdjustPercentAction(action);
        case 'PRICE_ADJUST_ABSOLUTE_WITH_LIMIT':
            return new PriceAdjustAbsoluteWithLimitAction(action);
        case 'PRICE_ADJUST_RELATIVE_WITH_LIMIT':
            return new PriceAdjustRelativeWithLimitAction(action);
        case 'PRICE_ADJUST_PERCENT_WITH_LIMIT':
            return new PriceAdjustPercentWithLimitAction(action);
        case 'CHARM_PRICE':
            return new CharmPriceAction(action);
        case 'ADD_FEE':
            return new AddFeeAction(action);
        case 'ADD_CART_FEE':
            return new AddCartFeeAction(action);
        case 'DISPLAY_QTY_BREAK':
            return new DisplayQuantityBreakAction(action);
        case 'CUSTOMER_DRAFT_ORDER_TAXABLE':
            return new CustomerDraftOrderTaxableAction(action);
        case 'SHIPPING_ADJUST_RELATIVE':
            return new ShippingAdjustRelativeAction(action);
        case 'SHIPPING_ADJUST_PERCENT':
            return new ShippingAdjustPercentAction(action);
        case 'FREE_SHIPPING':
            return new FreeShippingAction(action);
        case 'CART_ADJUST_RELATIVE':
            return new CartAdjustRelativeAction(action);
        case 'CART_ADJUST_PERCENT':
            return new CartAdjustPercentAction(action);
        case 'CASE_PRICING':
            return new CasePricingAction(action);
        case 'PRICE_ADJUST_RATE':
            return new PriceAdjustRateAction(action);
        case 'CART_LEVEL_DISCOUNT_RELATIVE':
            return new CartLevelDiscountRelativeAction(action);
        case 'CART_LEVEL_DISCOUNT_PERCENT':
            return new CartLevelDiscountPercentAction(action);
    }

    /* develblock:start */
    throw new Error(`Unrecognized action type: ${action.type}`);
    /* develblock:end */
}
