import BaseCondition, { BaseConditionInput } from '../BaseCondition';
import Variant from '../../platform/Variant';
import Shop from '../../platform/Shop';

class CustomerBoldIdCondition extends BaseCondition {
    allowed_ids: number[];

    constructor({ type, allowed_ids }: BaseConditionInput & { allowed_ids: number[] }) {
        super({ type });
        this.allowed_ids = allowed_ids;
    }

    evaluate(variant: Variant, shop: Shop): boolean {
        const customer = shop.getCustomer();

        if (!customer.getBoldId()) {
            return false;
        }

        return this.allowed_ids.some(allowedBoldId => allowedBoldId === customer.getBoldId());
    }
}

export default CustomerBoldIdCondition;
