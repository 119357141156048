
import CartItem from '../platform/CartItem';
import { CONDITION_OPERATORS as OPS } from '../../constants';
import Variant from '../platform/Variant';
import Shop from '../platform/Shop';
import CartBaseCondition from './CartBaseCondition';
import { BaseConditionInput } from './BaseCondition';

class QtyByProductCondition extends CartBaseCondition {
    value: number;

    constructor({
        type,
        operator = OPS.EQUAL,
        value,
        requiresPrepare = false,
    }: BaseConditionInput & { value: number }) {
        super({ type, operator, requiresPrepare });
        this.value = value;
    }

    continueEvaluating(variant: Variant, shop: Shop): boolean {
        if (!(variant.parent instanceof CartItem)) {
            return this.compare(1);
        }

        const totalProductQuantity = shop.cart.items.reduce((sum, item) => {
            // eslint-disable-next-line eqeqeq
            if (item.product_id == variant.product_id) {
                sum += item.getQuantity();
            }

            return sum;
        }, 0);

        return this.compare(totalProductQuantity);
    }
}

export default QtyByProductCondition;
