
import { sleep } from '../helpers/browser';
import Log from '../helpers/Log';
import LegacyCartHooks from './shopify/LegacyCartHooks';
import { DOM_POLLING_INTERVAL } from '../constants';
import { EVENTS, channel } from '../events';
import ElementCache from './ElementCache';
import { boldWindow } from '../helpers/windowBold';
import WindowApi from '../api/WindowApi';
import Container from './Container';
import Shop from '../models/platform/Shop';
import { config } from '../config';

const foundElementCache = new ElementCache();

/**
 * PlatformScanner
 */
class PlatformScanner {
    static async scan() {
        do {
            await sleep(DOM_POLLING_INTERVAL);
            PlatformScanner.poll();
        } while (document.readyState !== 'complete');

        /* develblock:start */
        Log.info(`Dom loaded. Finished polling.`);
        /* develblock:end */

        PlatformScanner.bindRePollEvents();
        PlatformScanner.bindDomObserver();
    }

    static poll() {
        LegacyCartHooks.scanForLegacyCartHookElements();
        PlatformScanner.scanForProductData();
        PlatformScanner.scanForMoneyElements();
        PlatformScanner.scanForMoneyTemplateElements();
    }

    static bindRePollEvents() {
        const repoll = () => PlatformScanner.poll();
        document.addEventListener('DOMContentLoaded', repoll, false);
        window.addEventListener('load', repoll, false);
        channel.on(EVENTS.VARIANT_CHANGED, repoll, this);
        channel.on(EVENTS.LOADED_PRODUCTS, repoll, this);
        channel.on(EVENTS.LOADED_CART, repoll, this);
        /* develblock:start */
        boldWindow.__PR_DEBUG.repoll = repoll;
        /* develblock:end */
    }

    static bindDomObserver() {
        const targetNode = document.body;
        const config = { attributes: true, childList: true, subtree: true };
        const observerCallback = (mutations: any, observer: any) => {
            const shouldRepoll = mutations.some((mutation: any) => {
                return mutation.addedNodes.length > 0 || ['data-product-id', 'data-variant-id'].indexOf(mutation.attributeName) > -1;
            });

            if (shouldRepoll) {
                PlatformScanner.poll();
            }
        };

        this.addCustomSubscriptionObservers();
        const observer = new MutationObserver(observerCallback);
        observer.observe(targetNode, config);
    }

    static addCustomSubscriptionObservers() {
        const shop = Container.get(Shop);
        const subscriptionTabsElement = PlatformScanner.getCustomSubscriptionTabsElement();

        // Add an observer on the Custom Subs Widget tab
        if (subscriptionTabsElement) {
            shop.setSubscriptionSelect(subscriptionTabsElement.getAttribute('data-subscription-selected'));
            const observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === 'attributes') {
                        const oldSubscriptionTabSelectedValue = shop.subscriptionTabSelected;
                        const newSubscriptionTabSelectedValue = subscriptionTabsElement.getAttribute('data-subscription-selected') === 'true';

                        if (oldSubscriptionTabSelectedValue !== newSubscriptionTabSelectedValue) {
                            shop.setSubscriptionSelect(subscriptionTabsElement.getAttribute('data-subscription-selected'));
                            PlatformScanner.scan();
                            channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
                        }
                    }
                });
            });

            observer.observe(subscriptionTabsElement, {
                attributes: true,
            });
        }

        // If FF is set, add event listeners on the frequency dropdown
        if (config('staples_subs_ff')) {
            // Event listener on the subscription frequency Select
            const frequencyElements = document.getElementsByName('selling_plan');
            const frequencyEventListener = () => {
                frequencyElements[0].removeEventListener('change', frequencyEventListener);
                PlatformScanner.scan();
            };

            if (frequencyElements && frequencyElements.length > 0) {
                frequencyElements[0].addEventListener('change', frequencyEventListener);
            }
        }
        channel.dispatch(EVENTS.SHOP_STATE_UPDATED);
    }

    static getCustomSubscriptionTabsElement(attributeName = 'data-subscription-selected') {
        const elements = document.querySelectorAll(`[${attributeName}]`);
        if (elements && elements.length > 0) {
            return elements[0];
        }
    }

    /**
     * Scans for money elements and emits them.
     */
    static scanForMoneyElements() {
        const elements = document.getElementsByClassName('money');
        if (elements.length > 0) {
            channel.dispatch(EVENTS.NEW_PRICE_ELEMENTS, { elements: Array.from(elements) });
        }
    }

    /**
     * Scans for money template elements and emits them.
     */
    static scanForMoneyTemplateElements() {
        const templateElements = document.getElementsByClassName('money-template');
        if (templateElements.length > 0) {
            channel.dispatch(EVENTS.NEW_TEMPLATE_ELEMENTS, { elements: Array.from(templateElements) });
        }
    }

    /**
     * Scans for script tags containing product
     * json to be loaded in as a new product.
     */
    static scanForProductData() {
        const elements = Array.from(document.getElementsByClassName('bold-product-json')) as HTMLElement[];
        const newElements = foundElementCache.filterCachedFrom(elements);

        if (newElements.length > 0) {
            const products = newElements.map(element => {
                const product = JSON.parse(element.innerHTML);
                // removing the element from DOM to avoid CSS issues
                element.parentNode?.removeChild(element);

                return product;
            });

            channel.dispatch(EVENTS.NEW_PRODUCTS_RAW, { products });
            foundElementCache.save(newElements);
        }
    }
}

export default PlatformScanner;
