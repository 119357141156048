
/**
 * Shopify provides currency values as strings and integers.
 *
 * String values are assumed to be in the typical '12.45' format.
 *
 * Number values are assumed to be in the integer form 1245.
 */
export default function (numberValue: string|number|null, fractionDigits = 2, thousandsSeparator = ',', decimalPoint = '.') {
    const invalidInputReturn = '0';
    if (numberValue === null || isNaN(numberValue as number) || numberValue === '') {
        return invalidInputReturn;
    }
    if (typeof numberValue === 'string') {
        numberValue = numberValue.replace('.', '');
        numberValue = parseInt(numberValue);
    }

    numberValue = (numberValue / 100).toFixed(fractionDigits);
    // eslint-disable-next-line prefer-const
    let [dollars, cents] = numberValue.split('.');
    dollars = dollars.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousandsSeparator}`);
    const decimalPointAndCents = cents ? decimalPoint + cents : '';
    return dollars + decimalPointAndCents;
}
