
import { hasClass, createElement, addClass, show as domShow } from '../../helpers/dom';
import { GenericObject } from '../../helpers/object';

const moneyDetailsClass = 'money-details';
const debugBackgroundStyle = 'rgba(50, 205, 50, 0.5)';

class PriceElementManipulator {
    show(domElement: HTMLElement) {
        domShow(domElement);
    }

    update(data: GenericObject, domElement: HTMLElement) {
        let prevSib;
        let nextSib;

        /* develblock:start */
        domElement.style.background = debugBackgroundStyle;
        /* develblock:end */

        // This is added to signal to other apps not to manipulate this element.
        addClass(domElement, 'pre-money');

        domElement.innerHTML = data.display;

        if (domElement.parentNode) {
            prevSib = this.loadOrCreatePreviousSib(domElement);

            /* develblock:start */
            prevSib.style.background = 'rgba(120,50,205,.3)';
            /* develblock:end */
            if (prevSib) {
                prevSib.innerHTML = data.before ?? '';
            }
        }

        if (domElement.parentNode) {
            nextSib = this.loadOrCreateNextSib(domElement);

            /* develblock:start */
            nextSib.style.background = 'rgba(120,50,205,.3)';
            /* develblock:end */

            if (nextSib) {
                nextSib.innerHTML = data.after ?? '';
            }
        }
    }

    loadOrCreatePreviousSib(domElement: HTMLElement): HTMLElement {
        let prevElement = domElement.previousSibling;

        while (prevElement) {
            if (prevElement instanceof HTMLElement && this.isValidSibElement(prevElement)) {
                return prevElement;
            }
            prevElement = prevElement.previousSibling;
        }

        return createElement('div', {
            className: moneyDetailsClass,
            insertBefore: domElement,
        });
    }

    loadOrCreateNextSib(domElement: HTMLElement): HTMLElement {
        let nextElement = domElement.nextSibling;

        while (nextElement) {
            if (nextElement instanceof HTMLElement && this.isValidSibElement(nextElement)) {
                return nextElement;
            }
            nextElement = nextElement.nextSibling;
        }

        return createElement('div', {
            className: moneyDetailsClass,
            insertAfter: domElement,
        });
    }

    isValidSibElement(sib: HTMLElement): boolean {
        return hasClass(sib, moneyDetailsClass);
    }
}

export default PriceElementManipulator;
