
import { GenericObject } from '../helpers/object';
import Shop from '../models/platform/Shop';
import { RuleProcessQueue } from './RuleProcessQueue';

class Container {
    debug: {
        shop: Shop | null,
        events: any[],
        config: any,
        last_checkout: any,
        rp_queue?: RuleProcessQueue,
    };
    storage: GenericObject;
    constructor() {
        this.storage = {};
        this.debug = {
            shop: null,
            events: [],
            config: null,
            last_checkout: null,
        };
    }

    get<T>(classFn: new (args: any) => T): T {
        // @ts-expect-error
        return this.storage[classFn];
    }

    getData(name: string): GenericObject {
        return this.storage[name];
    }

    set(classFn: any, instance: any) {
        this.storage[classFn] = instance;
    }
}

export default new Container();
