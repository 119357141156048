
import PriceElementSet from '../dom/PriceElementSet';
import Log from '../../helpers/Log';
import { ENV, SETTINGS, config } from '../../config';
import { equalIdentifiers, normalizeIdentifier } from '../../helpers/identifier';
import Money from '../money/Money';
import Variant from './Variant';
import { GenericObject } from '../../helpers/object';

const onVariantChanged = ENV.BROWSER
    ? require('@bold-commerce/frontend-events-shopify').onVariantChanged
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    : () => {};

interface ProductInput {
    description?: string;
    handle: string;
    id: string|null;
    name?: string;
    type?: string;
    variants: Variant[];
    vendor?: string;
    subscriptionGroupId?: number;
    subscriptionIntervalId?: number;
}

class Product {
    description?: string;
    handle: string;
    id: string;
    name?: string;
    priceElementSet: PriceElementSet;
    selected_variant_id: string|null;
    type?: string;
    variants: Variant[];
    vendor?: string;
    subscriptionGroupId?: number;
    subscriptionIntervalId?: number;

    constructor({
        id,
        name,
        variants,
        handle,
        description,
        vendor,
        type,
        subscriptionGroupId,
        subscriptionIntervalId,
    }: ProductInput) {
        this.id = normalizeIdentifier(id) as string;
        this.name = name;
        this.variants = variants;
        this.handle = handle;
        this.description = description;
        this.vendor = vendor;
        this.type = type;
        this.selected_variant_id = null;
        this.subscriptionGroupId = subscriptionGroupId;
        this.subscriptionIntervalId = subscriptionIntervalId;

        this.priceElementSet = new PriceElementSet('product', this, [], this.getPrice(), config(SETTINGS.template_product));

        this.variants.forEach((variant) => variant.setParent(this));

        if (ENV.BROWSER) {
            this.bindPriceEvents();
        }
    }

    bindPriceEvents() {
        this.variants.forEach((variant) => {
            onVariantChanged((data: GenericObject) => {
                if (data && data.variant && equalIdentifiers(data.variant.id, variant.id)) {
                    /* develblock:start */
                    const name = this.name ? `${this.name} / ${this.id}` : this.id;
                    Log.info(`Variant changed for product ${name}`, data && data.variant ? data.variant.id : data);
                    /* develblock:end */

                    this.setSelectedVariantId(variant.id);
                }
            });
        });
    }

    toJSON() {
        return {
            id: this.id,
            name: this.name,
            variants: this.variants,
            handle: this.handle,
            description: this.description,
            vendor: this.vendor,
            type: this.type,
        };
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getVariants(): Variant[] {
        return this.variants;
    }

    getSubscriptionGroupId(): string | number | undefined | null {
        return this.subscriptionGroupId;
    }

    setSubscriptionGroupId(subscriptionId?: number | undefined) {
        this.subscriptionGroupId = subscriptionId;
    }

    setSubscriptionIntervalId(subscriptionIntervalId?: number | undefined) {
        this.subscriptionIntervalId = subscriptionIntervalId;
    }

    getSubscriptionIntervalId() {
        return this.subscriptionIntervalId;
    }

    getHandle() {
        return this.handle;
    }

    getDescription() {
        return this.description;
    }

    getVendor() {
        return this.vendor;
    }

    getType() {
        return this.type;
    }

    hasSelectedVariant() {
        return Boolean(this.selected_variant_id);
    }

    setSelectedVariantId(selected_variant_id: string|null) {
        selected_variant_id = normalizeIdentifier(selected_variant_id);
        if (selected_variant_id) {
            this.selected_variant_id = selected_variant_id;
            this.resetMoney();
        }
    }

    /*
     * Check if this Product contains a price DOM element
     */
    hasPriceElements() {
        return !this.priceElementSet.isEmpty();
    }

    /**
     * Adds a new price element for this product.
     * It should update any time either the price changes
     * or the selected variant changes.
     */
    addPriceElement(domElement: HTMLElement) {
        this.priceElementSet.push(domElement);
    }

    /*
     * Return selected variant id
     */
    getSelectedVariantId() {
        return this.selected_variant_id;
    }

    /*
     * Get variant by Id
     */
    getVariantById(variantId: string) {
        const variant = this.variants.find((variant) => variant.getId() === variantId);
        if (variant) {
            return variant;
        }
        return undefined;
    }

    /*
     * Get the selected or the first variant for this product
     */
    getVariant() {
        return this.getSelectedOrLowestPricedVariant();
    }

    /*
     * Get the selected or the first variant for this product
     */
    getSelectedOrFirstVariant() {
        const selectedVariant = this.getSelectedVariant();
        if (selectedVariant) {
            return selectedVariant;
        }

        // Just return the first variant
        return this.variants[0];
    }

    getSelectedOrLowestPricedVariant(): Variant {
        const selectedVariant = this.getSelectedVariant();
        if (selectedVariant) {
            return selectedVariant;
        }

        return this.variants.reduce((carry: Variant|null, v) => {
            if (!carry || v.price.amount() < carry.price.amount()) {
                carry = v;
            }
            return carry;
        }, null) as Variant;
    }

    getSelectedVariant() {
        if (this.hasSelectedVariant()) {
            const selectedVariantId = this.getSelectedVariantId();
            const selectedVariant = this.variants.find((variant) => variant.getId() === selectedVariantId);
            if (selectedVariant) {
                return selectedVariant;
            }
        }

        return null;
    }

    /**
     * Loads the price from the currently selected or first variant.
     */
    getPrice(): Money {
        const variant = this.getSelectedOrLowestPricedVariant();
        return variant.getPrice();
    }

    processingFinished() {
        this.priceElementSet.showAll();
    }

    reset() {
        this.variants.forEach((v) => v.reset());
    }

    /**
     * This replaces the Money of the element set with
     * the Money of the newly selected variant.
     *
     * PriceElementSet will respond to setMoney by updating all
     * its elements with the new price.
     */
    resetMoney() {
        this.priceElementSet.setMoney(this.getPrice());
    }
}

export default Product;
