
import { CONDITION_OPERATORS as OPS } from '../../constants';
import Ruleset from '../Ruleset';
import Shop from '../platform/Shop';
import CartItem from '../platform/CartItem';
import Variant from '../platform/Variant';
import { GenericObject } from '../../helpers/object';
import Rule from '../Rule';
import { ENV } from '../../config';
import Container from '../../components/Container';
import WindowApi from '../../api/WindowApi';

export interface BaseConditionInput {
    type: string;
    operator?: string;
    requiresPrepare?: boolean
}

abstract class BaseCondition {
    operator: string;
    requiresPrepare: boolean;
    type: string;
    value: any;

    constructor({
        type,
        operator = OPS.EQUAL,
        requiresPrepare = false,
    }: BaseConditionInput) {
        this.type = type;
        this.operator = operator;
        this.value = null;
        this.requiresPrepare = requiresPrepare;
    }

    /**
     * Use this to compare shop values to the condition value
     */
    compare(actualValue: any, conditionValue = this.value): boolean {
        switch (this.operator) {
            case OPS.NOTEQUAL:
                return actualValue != conditionValue; // eslint-disable-line eqeqeq
            case OPS.LT:
                return actualValue < conditionValue;
            case OPS.GT:
                return actualValue > conditionValue;
            case OPS.LTE:
                return actualValue <= conditionValue;
            case OPS.GTE:
                return actualValue >= conditionValue;
            case OPS.EQUAL:
            default:
                return actualValue == conditionValue; // eslint-disable-line eqeqeq
        }
    }

    /**
     * @override
     */
    toJSON(): GenericObject {
        return {
            type: this.type,
            operator: this.operator,
            value: this.value,
        };
    }

    /**
     * This is an overridable method that will prepare the condition prior to its evaluation
     */
    prepare(item: CartItem, shop: Shop, rulesets: Ruleset[], rule: Rule | null = null) {
        // Do nothing
    }

    abstract evaluate(variant: Variant, shop: Shop): boolean;
}

export default BaseCondition;
