
import EventEmitter from 'eventemitter3';
import MoneyDisplay from './MoneyDisplay';
import { ENV } from '../../config';

/**
 * Money
 */
class Money {
    amt: number;
    ee: EventEmitter;
    fee_total: number;
    orig_amt: number;
    raw_amt: number;

    constructor(amount: number, raw_amt: number|null|undefined) {
        this.ee = new EventEmitter();
        /**
         * For internal comparison, not for external use.
         */
        this.amt = amount;
        this.orig_amt = raw_amt || amount;
        this.raw_amt = raw_amt || amount;
        this.fee_total = 0;
    }

    /**
     * Get the price
     */
    amount() {
        return this.amt;
    }

    amountNoFees() {
        return this.amt - this.fee_total;
    }

    feeTotal() {
        return this.fee_total;
    }

    /*
     * Get the full display price
     */
    display() {
        return MoneyDisplay.display(this.amt);
    }

    /*
     * Add a $ amount to the price
     */
    add(amount: number) {
        this.amt += amount;
        this.emitChange();
    }

    /*
     * Add a $ amount for a fee to the price
     */
    addFee(amount: number) {
        this.amt += amount;
        this.fee_total += amount;
        this.emitChange();
    }

    /*
     * Multiply the price
     */
    multiply(multiplier: number) {
        this.amt *= multiplier;
        this.emitChange();
    }

    /*
     * Adjust an amount up or down by a %
     */
    percentAdjust(percent: number) {
        const amtAdjust = this.amt / 100 * percent;
        this.amt = this.amt + Math.round(amtAdjust);
        this.emitChange();
    }

    /*
     * Set a specific price
     */
    setAmount(amount: number) {
        this.amt = amount;
        this.emitChange();
    }

    /*
    * Set cents to 0
    */
    setCentsToZero() {
        const dollarydoos = this.amt.toString().slice(0, -2);
        this.amt = parseInt(`${dollarydoos}00`, 10);
        this.emitChange();
    }

    /*
     * Set a specific price
     */
    setAmountWithoutEvent(amount: number) {
        this.amt = amount;
    }

    isNegative() {
        return this.amt < 0;
    }

    isLessThan(compareValue: number) {
        return this.amt < compareValue;
    }

    emitChange() {
        if (ENV.BROWSER) {
            this.ee.emit('change');
        }
    }

    original() {
        return this.orig_amt;
    }

    raw() {
        return this.raw_amt;
    }

    setOriginal(original: number) {
        this.orig_amt = original;
        this.emitChange();
    }

    hasChanged() {
        return this.amt !== this.orig_amt;
    }

    /*
     * Get the full display price
     */
    toString() {
        return this.display();
    }

    toJSON() {
        return this.amt;
    }
}

export default Money;
