
import BaseCartLevelDiscountAction from './BaseCartLevelDiscountAction';
import Cart from '../platform/Cart';
import CartLevelDiscount, { RuleDiscountType } from '../money/CartLevelDiscount';

class CartLevelDiscountPercentAction extends BaseCartLevelDiscountAction {
    calculateCartDiscount(shopCart:Cart, discount: number): number {
        const shop_cart_sub_total = shopCart.calculateSubTotalWithoutFees();
        // it will not apply discount equal or great than 100%
        if (discount >= 100) {
            return shop_cart_sub_total;
        }
        // calculate the new value
        const amtAdjust = shop_cart_sub_total / 100 * Math.abs(discount);
        const newValue = shop_cart_sub_total - Math.round(amtAdjust);

        shopCart.addCartLevelDiscount(new CartLevelDiscount({
            amount: this.value,
            discountValue: amtAdjust,
            name: this.name,
            ruleDiscountType: RuleDiscountType.PERCENT,
        }), this.name);

        return newValue;
    }
}

export default CartLevelDiscountPercentAction;
