
import BaseAction, { BaseActionParams } from './BaseAction';
import Fee from '../money/Fee';
import Shop from '../platform/Shop';
import CartItem from '../platform/CartItem';
import Variant from '../platform/Variant';
import Money from '../money/Money';
import { ENV } from '../../config';
/**
 * This action used in both Rule "meta" and "actions". Huh.
 */
class AddFeeAction extends BaseAction {
    name?: string;
    value: number;
    includeAsLineItemTotal?: boolean;

    constructor({ type, name, value, includeAsLineItemTotal = true }: BaseActionParams & { name?: string, includeAsLineItemTotal?: boolean}) {
        super({ type });
        this.name = name;
        this.value = value;
        this.includeAsLineItemTotal = includeAsLineItemTotal;
    }

    act(price: Money, variant: Variant, shop: Shop) {
        const oldPrice = price.amount();

        if (this.includeAsLineItemTotal || ENV.NODE) {
            variant.addFee(new Fee({
                name: this.name,
                amount: this.value,
                meta: null,
            }));
        } else {
            variant.showFee(new Fee({
                name: this.name,
                amount: this.value,
                is_cart_fee: true,
            }));

            if (variant.parent instanceof CartItem) {
                const cartItem = variant.parent;
                shop.cart.addFee(`${cartItem.getId()}|${this.name}`, new Fee({
                    name: this.name,
                    amount: this.value * cartItem.getQuantity(),
                    line_id: cartItem.getId(),
                    is_cart_fee: true,
                }));
            }
        }

        variant.log(this.type, { price_adj: `${oldPrice} --> ${price.amount()}` });
    }
}

export default AddFeeAction;
