
import { time } from '../../helpers/browser';
import { SETTINGS, config } from '../../config';
import Log from '../../helpers/Log';
import PriceElementManipulator from './PriceElementManipulator';
import { GenericObject } from '../../helpers/object';
import PriceElementSet from './PriceElementSet';

let instanceCount = 0;

/**
 * PriceElement
 */
class PriceElement {
    domElement: HTMLElement;
    hasUpdated: boolean;
    instanceNumber: number;
    lastMoneyDisplay: string | null;
    manipulator: PriceElementManipulator;
    mutationBattleCount: number;
    mutationBattleSecond: number;
    observer: MutationObserver | null;
    priceElementSet: PriceElementSet;
    constructor(domElement: HTMLElement, priceElementSet: PriceElementSet, templateData: null | GenericObject = null) {
        this.domElement = domElement;
        this.priceElementSet = priceElementSet;
        this.hasUpdated = false;
        this.observer = null;
        this.manipulator = new PriceElementManipulator();
        this.instanceNumber = ++instanceCount;
        this.mutationBattleSecond = time();
        this.mutationBattleCount = 0;
        this.lastMoneyDisplay = null;

        this.update(templateData);
        this.observe();
    }

    shouldUpdate(force = false): boolean {
        if (!this.priceElementSet.money) {
            return false;
        }

        if (force) {
            return true;
        }

        /** If we handled it once before we continue. */
        if (this.hasUpdated) {
            return true;
        }

        /** Handling all prices on? */
        if (config(SETTINGS.handle_all_prices)) {
            return true;
        }

        /** Only update the element if the price has changed. */
        return this.priceElementSet.money.hasChanged();
    }

    /**
     * Update with the latest price.
     */
    update(data: null | GenericObject = null, force = false) {
        if (!this.shouldUpdate(force)) {
            return;
        }

        if (!data) {
            data = this.priceElementSet.renderTemplate();
        }

        this.hasUpdated = true;

        this.manipulator.update(data, this.domElement);
        this.lastMoneyDisplay = this.domElement.innerHTML;
    }

    purge() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    /**
     * Show the dom element if it's hidden with css.
     */
    show() {
        this.manipulator.show(this.domElement);
    }

    /**
     * This function is a throttle to prevent us from engaging
     * in mutation battles and freezing the page.
     */
    mutationBattleCheck(fn: () => void) {
        const currentSecond = time();

        if (currentSecond - this.mutationBattleSecond <= 1) {
            // Count mutations that happened within 1 second of the last one
            this.mutationBattleCount++;
        } else { // Reset
            this.mutationBattleCount = 0;
        }

        this.mutationBattleSecond = currentSecond;

        if (this.mutationBattleCount < 25) {
            // Only call our fn if we're below the threshold
            fn();
        } else {
            /* develblock:start */
            Log.warn(this.instanceNumber, 'Mutation battle detected -- backing off.', this.domElement.outerHTML);
            /* develblock:end */
        }
    }

    /**
     * Watches an money element for changes and:
     * - Restores the element if it gets deleted from the DOM.
     * - Restores its value if it changes.
     */
    observe() {
        const parent = this.domElement.parentElement;
        const grandParent = parent && parent.parentElement;

        if (!parent || !grandParent) {
            return;
        }

        this.observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                const mutationTarget = mutation.target;
                if (mutation.type === 'childList' && mutation.target === parent && mutationTarget instanceof HTMLElement) {
                    // @ts-expect-error
                    for (const removedNode of mutation.removedNodes) {
                        const ele = removedNode;
                        if (removedNode === this.domElement) {
                            // Money element got removed, restore it
                            this.mutationBattleCheck(this.restore.bind(this, mutationTarget, ele));
                        }
                    }
                } else if (this.domElement.innerHTML !== this.lastMoneyDisplay && this.hasUpdated) {
                    // Value got replaced, restore it
                    this.mutationBattleCheck(this.update.bind(this));
                }
            }
        });

        this.observer.observe(grandParent, { childList: true, subtree: true });
    }

    restore(mutationTarget: HTMLElement, moneyElement: HTMLElement) {
        mutationTarget.innerHTML = '';
        mutationTarget.appendChild(moneyElement);
        this.update(null, true);
        this.show();

        /* develblock:start */
        if (document.body.contains(moneyElement)) {
            Log.info(this.instanceNumber, 'Money element ejection detected -- Element restored');
        } else {
            Log.warn(this.instanceNumber, 'Money element ejection detected -- Did not appear to restore successfully');
        }
        /* develblock:end */
    }
}

export default PriceElement;
